<template>
  <div class="wrapper">
    <div>
      <img
        src="@/assets/images/banner/wedding-banner.jpg"
        alt="婚庆专区"
        class="banner"
      />
    </div>
    <div class="location">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item>首页</el-breadcrumb-item>
        <el-breadcrumb-item>婚庆专区</el-breadcrumb-item>
        <el-breadcrumb-item>商品列表</el-breadcrumb-item>
      </el-breadcrumb>
      <div>
        总共找到<span class="find-count">{{ totalCount }}</span
        >个{{ type }}{{ category }}商品
      </div>
    </div>

    <!--查询条件.开始-->
    <div class="goods-screen">
      <div class="list-box">
        <span class="title">排序</span>
        <div class="item-box">
          <span
            @click="handleFilterChange(0)"
            :class="{ active: filterIndex === 0 }"
            class="text"
            >默认</span
          >
          <span
            @click="handleFilterChange(1)"
            :class="{ active: filterIndex === 1 }"
            class="text"
            >销量</span
          >
          <span
            @click="handleFilterChange(2)"
            :class="{ active: filterIndex === 2 }"
            class="text"
            >人气</span
          >
          <div @click="handleFilterChange(3)" class="text">
            价格
            <span class="icon iconfont icon-sort-full"></span>
          </div>
          <div class="input">
            <el-input v-model="minPrice" size="mini" clearable>
              <template slot="prepend"> ￥ </template>
            </el-input>
          </div>
          <span class="line">-</span>
          <div class="input">
            <el-input v-model="maxPrice" size="mini" clearable>
              <template slot="prepend"> ￥ </template>
            </el-input>
          </div>
          <div class="btn">
            <el-button @click="handlePriceChange" size="mini">确认</el-button>
          </div>
        </div>
      </div>
    </div>
    <!--查询条件.结束-->

    <!--商品列表.开始-->
    <div class="goods-index">
      <div class="goods-list">
        <div v-if="listData.length === 0" class="nodata">
          暂无查询到相关商品
        </div>
        <router-link
          v-for="(item, index) in listData"
          :key="index"
          :to="'/shop/goods/show/' + item.id"
          class="list-box"
        >
          <Goods
            :imgUrl="item.imgUrl"
            :title="item.merchantTitle + ' ' + item.title"
            :goodsName="item.title"
            :sellPrice="item.sellPrice"
            :publicTime="item.addTime.split(' ')[0]"
            :icons="[]"
            :storeName="item.merchantTitle"
          />
        </router-link>
      </div>

      <div class="pager-box">
        <el-pagination
          background
          :hide-on-single-page="false"
          @current-change="handleCurrentChange"
          :page-size="pageSize"
          layout="prev, pager, next"
          :total="totalCount"
        >
        </el-pagination>
      </div>
    </div>

    <!--商品列表.结束-->
  </div>
</template>

<script>
import AxiosAjax from "@/utils/axios.config";
import Goods from "@/components/Goods.vue";
export default {
  name: "AATShopWedding",
  components: {
    Goods,
  },
  data() {
    return {
      categoryId: this.$route.params.categoryId || 0,
      brandId: this.$route.query.brandId || 0, //品牌(自主品牌)
      labelId: 10, //婚庆的id是10
      pageSize: 20,
      pageIndex: 1,
      listData: [], //数据列表
      totalCount: 0, //总数量
      categoryList: [],
      filterIndex: 0, //排序索引
      minPrice: "", //最低价格
      maxPrice: "", //最高价格
      priceOrder: 0, //1价格从低到高 2价格从高到低
    };
  },

  computed: {
    categorychildren() {
      return this.categoryList.reduce((accumulator, currentValue) => {
        if (currentValue.children && currentValue.children.length > 0) {
          accumulator.concat(currentValue.children);
        }
      }, []);
    },
    type() {
      if (this.categoryId == 0) return "";
      const curCategoryChild = this.categorychildren.find(
        (category) => category.id == this.categoryId
      );
      if (curCategoryChild) {
        const curCategory = this.categoryList.find(
          (category) => category.id == curCategoryChild.parentId
        );
        return curCategory ? curCategory.title : "";
      }
      return "";
    },
    category() {
      if (this.categoryId == 0) return "";
      const curCategory = this.categorychildren.find(
        (category) => category.id == this.categoryId
      );
      return curCategory ? curCategory.title : "";
    },
  },
  methods: {
    //初始化数据
    async initData() {
      let _this = this;
      //加载分类
      AxiosAjax({
        url: `/client/shop/category`,
        success: function (res) {
          _this.categoryList = res.data;
        },
      });
      //加载标签
      AxiosAjax({
        url: `/client/shop/label/view/0`,
        success: function (res) {
          _this.labelList = res.data;
        },
      });
      //加载商品列表
      _this.loadData();
    },
    //加载商品列表
    async loadData() {
      let _this = this;
      let sendUrl = `/client/shop/goods?categoryId=${_this.categoryId}`;
      sendUrl += "&brandId=" + _this.brandId;
      sendUrl += "&labelId=" + _this.labelId;
      sendUrl += "&pageSize=" + _this.pageSize;
      sendUrl += "&pageIndex=" + _this.pageIndex;
      //关健字
      if (_this.common.param.keyword) {
        sendUrl += "&keyword=" + encodeURI(_this.common.param.keyword);
      }
      //价格
      if (_this.minPrice > 0) {
        sendUrl += `&minPrice=${_this.minPrice}`;
      }
      if (_this.maxPrice > 0) {
        sendUrl += `&maxPrice=${_this.maxPrice}`;
      }
      //排序
      if (_this.filterIndex === 1) {
        sendUrl += `&orderBy=-SaleCount`;
      }
      if (_this.filterIndex === 2) {
        sendUrl += `&orderBy=-Click`;
      }
      if (_this.filterIndex === 3) {
        if (_this.priceOrder == 1) {
          sendUrl += `&orderBy=SellPrice`;
        } else {
          sendUrl += `&orderBy=-SellPrice`;
        }
      }
      AxiosAjax({
        url: sendUrl,
        loading: true,
        success: function (res) {
          _this.listData = res.data;
          let pageInfo = JSON.parse(res.headers["x-pagination"]);
          _this.totalCount = pageInfo.totalCount;
          _this.pageSize = pageInfo.pageSize;
          _this.pageIndex = pageInfo.pageIndex;
        },
        error: function () {
          _this.listData = [];
        },
      });
    },
    //跳转到第几页
    handleCurrentChange: function (val) {
      if (this.pageIndex != val) {
        this.pageIndex = val;
        this.loadData();
      }
    },
    //排序
    handleFilterChange(val) {
      this.filterIndex = val;
      //价格筛选
      if (val === 3) {
        this.priceOrder = this.priceOrder === 1 ? 2 : 1;
      } else {
        this.priceOrder = 0;
      }
      this.pageIndex = 1;
      this.totalCount = 0;
      this.loadData();
    },
    //确认
    handlePriceChange() {
      this.pageIndex = 1;
      this.totalCount = 0;
      this.loadData();
    },
  },
  created() {
    this.common.param.keyword = this.common.queryValue("keyword");
    this.initData();
  },
};
</script>

<style lang="scss" scoped>
.location {
  display: flex;
  align-items: center;
  justify-content: space-between;
  .find-count {
    color: #fb4d4f;
    font-weight: bold;
  }
}
.banner {
  width: 100%;
  aspect-ratio: 1920 / 330;
}
</style>
