<template>
  <div class="wrapper">
    <div class="account-box">
      <AccountNav :account="account" model="order" />

      <div class="right-box">
        <div class="tab-box">
          <div class="menu-box menu-box-header">
            <div class="item-box active">
              <span>我的订单</span>
            </div>
            <div class="menu-box-header-right">
              <a
                download
                filename="批量上传订单模板"
                :href="`${publicPath}excel/orderList.xls`"
                >下载模板</a
              >
              <el-upload>批量上传订单</el-upload>
            </div>
          </div>
        </div>
        <div class="content">
          <div v-if="listData.length === 0" class="nodata">
            <span class="icon iconfont icon-msg"></span>
            <span class="text">暂无记录</span>
          </div>
          <div class="order-list">
            <div
              v-for="(item, index) in listData"
              :key="index"
              class="list-box"
            >
              <div class="head-box">
                <span class="time iconfont icon-time">{{ item.addTime }}</span>
                <span class="state">{{ orderStateExp(item) }}</span>
              </div>
              <div
                v-for="(goods, gindex) in item.orderGoods"
                :key="gindex"
                class="item-box"
              >
                <div class="img-box">
                  <img :src="common.loadFile(goods.imgUrl)" />
                </div>
                <div class="txt-box">
                  <span class="text">{{ goods.goodsTitle }}</span>
                  <span class="info"
                    >{{ goods.specText }} x {{ goods.quantity }}</span
                  >
                  <span class="price">{{ goods.realPrice }}</span>
                </div>
                <div v-if="item.paymentStatus > 0" class="btn-box">
                  <el-tag v-if="goods.deliveryStatus === 2" type="info"
                    >已退货</el-tag
                  >
                  <el-button
                    v-if="goods.deliveryStatus === 1"
                    size="small"
                    type="warning"
                    @click="common.linkUrl('/account/refund/add/' + goods.id)"
                    >申请售后</el-button
                  >
                </div>
              </div>
              <div class="total-box">
                <div class="left">
                  <el-tag
                    v-if="item.orderType === 1"
                    type="warning"
                    effect="plain"
                    >限时抢购</el-tag
                  >
                  <el-tag
                    v-if="item.orderType === 2"
                    type="warning"
                    effect="plain"
                    >积分换购</el-tag
                  >
                </div>
                <div class="right">
                  共<span class="num">{{ item.orderGoods.length }}</span
                  >件商品，实付<span class="price"
                    >￥{{ item.orderAmount }}</span
                  >元
                </div>
              </div>
              <div class="btn-box">
                <el-button
                  type="primary"
                  @click="common.linkUrl('/account/order/show/' + item.id)"
                  >查看订单</el-button
                >
                <el-button
                  v-if="item.status < 3 && item.paymentStatus == 0"
                  @click="cancelOrder(item)"
                  type="warning"
                  >取消订单</el-button
                >
                <el-button
                  v-if="item.status === 1 && item.paymentStatus == 0"
                  @click="common.linkUrl('/payment/confirm?no=' + item.tradeNo)"
                  type="danger"
                  >去支付</el-button
                >
                <el-button
                  v-if="item.deliveryStatus > 0"
                  @click="
                    common.linkUrl('/account/order/delivery?no=' + item.orderNo)
                  "
                  type="danger"
                  >查看物流</el-button
                >

                <el-button v-if="item.status < 3" type="warning">拦截</el-button>

                <el-button v-if="item.status < 3" type="warning"
                  >改地址</el-button
                >

                <el-button v-if="item.status < 3" type="primary">催件</el-button>

                <el-button
                  v-if="item.status == 3 || item.status == 4"
                  @click="confirmOrder(item)"
                  type="danger"
                  >确认收货</el-button
                >
                <el-button
                  v-if="
                    item.status == 5 &&
                    item.orderGoods.find(
                      (val) =>
                        val.deliveryStatus === 1 && val.evaluateStatus === 0
                    )
                  "
                  type="success"
                  @click="common.linkUrl('/account/evaluate/edit/' + item.id)"
                  >立即评价</el-button
                >
                <el-button
                  v-if="item.status > 2"
                  @click.stop="
                    common.linkUrl('/account/refund/list?no=' + item.orderNo)
                  "
                  class="btn"
                  >售后详情</el-button
                >
              </div>
            </div>
          </div>

          <div class="pager-box">
            <el-pagination
              background
              :hide-on-single-page="true"
              @current-change="handleCurrentChange"
              :page-size="pageSize"
              layout="prev, pager, next"
              :total="totalCount"
            >
            </el-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AxiosAjax from "@/utils/axios.config";
import AccountNav from "@/components/AccountNav.vue";
export default {
  name: "AATShopOrderList",
  components: {
    AccountNav,
  },
  data() {
    return {
      status: 0, //订单类型
      totalCount: 0, //总数量
      pageSize: 10, //每页显示条数
      pageIndex: 1, //当前页码
      listData: [], //数据列表
      account: null, //会员信息,
      publicPath: process.env.BASE_URL,
    };
  },

  methods: {
    //加载列表
    async loadData() {
      let _this = this;
      AxiosAjax({
        url: `/account/order?pageSize=${_this.pageSize}&pageIndex=${_this.pageIndex}&status=${_this.status}`,
        loading: true,
        success(res) {
          _this.listData = res.data;
          let pageInfo = JSON.parse(res.headers["x-pagination"]);
          _this.totalCount = pageInfo.totalCount;
          _this.pageSize = pageInfo.pageSize;
          _this.pageIndex = pageInfo.pageIndex;
          //循环查找收款信息赋值
          _this.listData.forEach((item) => {
            //判断是否预售
            if (item.orderType === 2) {
              //检查预付款是否已付
              let collectionType = 2; //默认查找尾款
              if (item.paymentStatus === 0) {
                collectionType = 1;
              }
              let modelt = item.collectionRelations.find(
                (val) => val.collection.collectionType == collectionType
              );
              if (modelt) {
                let collection = modelt.collection;
                let tradeStatus = _this.checkDate(
                  collection.startTime,
                  collection.endTime
                );
                _this.$set(item, "tradeNo", collection.tradeNo);
                _this.$set(item, "tradeStatus", tradeStatus);
              }
            } else {
              let collection = item.collectionRelations[0].collection;
              let tradeStatus = _this.checkDate(
                collection.startTime,
                collection.endTime
              );
              _this.$set(item, "tradeNo", collection.tradeNo);
              _this.$set(item, "tradeStatus", tradeStatus);
            }
          });
        },
        error() {
          _this.listData = [];
        },
      });
    },
    //初始化数据
    async initData() {
      let _this = this;
      //加载列表
      await _this.loadData();
      //加载会员信息
      await AxiosAjax({
        url: `/account/member`,
        success(res) {
          _this.account = res.data;
        },
      });
    },
    //跳转到第几页
    handleCurrentChange: function (val) {
      if (this.pageIndex != val) {
        this.pageIndex = val;
        this.loadData();
      }
    },
    //订单状态
    orderStateExp(item) {
      let stateTip = "已下单";
      if (item.status === 1) {
        stateTip = "待付款";
        //如果是预售订单
        if (item.orderType == 2) {
          if (item.paymentStatus == 1) {
            stateTip = "待付尾款";
          }
          if (item.paymentStatus == 1 && item.tradeStatus == 2) {
            stateTip = "活动已失效";
          }
        }
      } else if (item.status === 2) {
        stateTip = "待发货";
      } else if (item.status === 3) {
        stateTip = "待收货";
      } else if (item.status === 4) {
        stateTip = "已签收";
      } else if (item.status === 5) {
        stateTip = "交易成功";
        if (item.orderGoods.find((val) => val.evaluateStatus === 0)) {
          stateTip = "待评价";
        }
      } else if (item.status === 6) {
        stateTip = "交易取消";
      } else if (item.status === 7) {
        stateTip = "交易作废";
      }
      return stateTip;
    },
    //取消订单
    cancelOrder(item) {
      let _this = this;
      _this
        .$confirm("确认要取消订单吗？", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
        .then(() => {
          AxiosAjax({
            method: "put",
            url: `/account/order/cancel/${item.id}`,
            loading: true,
            successMsg: "订单已取消",
            success: function () {
              item.status = 6; //更改订单状态
            },
          });
        })
        .catch(() => {});
    },
    //确认收货
    confirmOrder(item) {
      let _this = this;
      _this
        .$confirm("确认已收到货了吗？", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
        .then(() => {
          AxiosAjax({
            method: "put",
            url: `/account/order/confirm/${item.id}`,
            loading: true,
            successMsg: "交易已成功",
            success: function () {
              item.status = 5; //更改订单状态
            },
          });
        })
        .catch(() => {});
    },
    //返回时间对比状态
    checkDate(beginDateStr, endDateStr) {
      var curDate = new Date(),
        beginDate = new Date(beginDateStr),
        endDate = new Date(endDateStr);
      if (curDate < beginDate) {
        return 1;
      }
      if (curDate > endDate) {
        return 2;
      }
      return 0;
    },
  },
  created() {
    let status = parseInt(this.common.queryValue("status"));
    this.status = status ? status : 0;
    this.initData();
  },
  beforeRouteUpdate(to, _, next) {
    if (to.name == "OrderList") {
      let status = parseInt(this.common.queryValue("status"));
      this.status = status ? status : 0;
      this.initData();
    }
    next();
  },
};
</script>

<style lang="scss" scoped>
.menu-box-header {
  width: 100%;
  display: flex;
  justify-content: space-between !important;
  align-items: center;
}
.menu-box-header-right {
  display: flex;
  gap: 10px;
}
</style>
