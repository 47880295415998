<template>
  <div class="wrapper">
    <div class="account-box">
      <AccountNav :account="account" model="address" />
      <div class="right-box">
        <div class="tab-box">
          <div class="menu-box">
            <div class="item-box active">
              <span>收货地址</span>
            </div>
          </div>
        </div>
        <div class="content">
          <div>示例:爱小兔,136xxxxxxxx,广东省深圳市南山区xx路xx号</div>
          <el-form class="cbf">
            <el-form-item>
              <el-input v-model="recognitionAddr"></el-input>
            </el-form-item>
            <el-button class="fr" @click="recognition">识别</el-button>
          </el-form>
        </div>
        <div class="content">
          <el-form
            ref="editForm"
            :model="model"
            :rules="rules"
            label-position="right"
            label-width="120px"
          >
            <el-form-item prop="acceptName" label="收货人姓名">
              <el-input
                v-model="model.acceptName"
                placeholder="必填，收货人姓名"
              ></el-input>
            </el-form-item>
            <el-form-item prop="mobile" label="手机号码">
              <el-input
                v-model="model.mobile"
                placeholder="必填，收货人手机号码"
              ></el-input>
            </el-form-item>
            <el-form-item prop="areaValue" label="所属地区">
              <el-cascader
                v-model="model.areaValue"
                :options="areaOptions"
                :props="{ label: 'title', value: 'title' }"
                placeholder="请选择省市区"
                @change="handleAreaChange"
              ></el-cascader>
            </el-form-item>
            <el-form-item prop="address" label="详情地址">
              <el-input
                type="textarea"
                :rows="2"
                v-model="model.address"
                placeholder="除省市区外详细地址"
              ></el-input>
            </el-form-item>
            <el-form-item label="默认地址">
              <el-switch
                v-model="model.isDefault"
                :inactive-value="0"
                :active-value="1"
              ></el-switch>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="submitForm">确认提交</el-button>
              <el-button @click="common.back(-1)">取消</el-button>
            </el-form-item>
          </el-form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AxiosAjax from "@/utils/axios.config";
import AccountNav from "@/components/AccountNav.vue";
const han = "[\u4e00-\u9fa5]";
const reg = new RegExp(`(${han}+省)?(${han}+市)?(${han}+市)(${han}+区)`);

export default {
  name: "AATShopAddressEdit",
  components: {
    AccountNav,
  },
  data() {
    return {
      recognitionAddr: "爱小兔,136xxxxxxxx,广东省深圳市南山区xx路xx号",
      account: {}, //账户信息
      areaOptions: [], //省市区树
      model: {
        id: 0,
        userId: 0,
        userName: null,
        acceptName: null,
        province: null,
        city: null,
        area: null,
        address: null,
        mobile: null,
        zip: null,
        isDefault: 0,
        addTime: null,
        areaValue: null, //省市区值
      },
      rules: {
        acceptName: [
          { required: true, message: "请输入收货人姓名", trigger: "blur" },
        ],
        mobile: [
          { required: true, message: "请输入手机号码", trigger: "blur" },
          {
            pattern: /^1[0-9]{10}$/,
            message: "手机号码格式不正确",
            trigger: "blur",
          },
        ],
        areaValue: [
          { required: true, message: "请选择省市区", trigger: "blur" },
        ],
        address: [
          { required: true, message: "请输入详细的收货地址", trigger: "blur" },
        ],
      },
    };
  },
  methods: {
    //初始化数据
    async initData(id) {
      let _this = this;
      //获取会员信息
      await AxiosAjax({
        url: `/account/member`,
        success(res) {
          _this.account = res.data;
        },
      });
      //加载省市区
      await AxiosAjax({
        url: `/client/area`,
        success(res) {
          _this.areaOptions = _this.getTreeData(res.data);
        },
      });
      //获取地址信息
      if (id) {
        await AxiosAjax({
          url: `/account/member/address/${id}`,
          success: function (res) {
            _this.model = res.data;
            let addrArr = _this.model.address.split(","); //将地址拆分
            _this.model.address = addrArr[addrArr.length - 1];
            _this.model.areaValue = [
              _this.model.province,
              _this.model.city,
              _this.model.area,
            ];
          },
        });
      }
    },
    // 递归判断列表
    getTreeData(data, lay = 0) {
      lay++;
      for (var i = 0; i < data.length; i++) {
        if (lay >= 3) {
          data[i].children = undefined;
        } else {
          this.getTreeData(data[i].children, lay);
        }
      }
      return data;
    },
    //提交表单
    submitForm() {
      let _this = this; //当前页面
      //多个表单验证
      const f1 = new Promise((resolve) => {
        _this.$refs["editForm"].validate((valid) => {
          if (valid) resolve();
        });
      });
      Promise.all([f1]).then(() => {
        //提交表单,Id大于0则修改，否则添加
        if (_this.model.id > 0) {
          AxiosAjax({
            method: "put",
            url: `/account/member/address/${_this.model.id}`,
            data: _this.model,
            loading: true,
            successMsg: "编辑地址成功",
            success() {
              //跳转列表页
              _this.common.linkUrl("/account/address/list");
            },
          });
        } else {
          AxiosAjax({
            method: "post",
            url: `/account/member/address`,
            data: _this.model,
            loading: true,
            successMsg: "添加地址成功",
            success() {
              //重置表单
              _this.$refs["editForm"].resetFields();
            },
          });
        }
      });
    },
    //省市区事件
    handleAreaChange(value) {
      this.model.areaValue = value;
      this.model.province = this.model.areaValue[0];
      this.model.city = this.model.areaValue[1];
      this.model.area = this.model.areaValue[2];
    },
    recognition() {
      if (this.recognitionAddr) {
        const [name, mobile, addr] = this.recognitionAddr.split(",");
        if (name) {
          this.model.acceptName = name;
        }
        if (mobile) {
          this.model.mobile = mobile;
        }
        if (addr) {
          const subCity = "市辖区";
          this.model.address = addr;
          const result = addr.match(reg);
          const [, province, subProvince, city, area] = result;

          // 直辖市
          if (subProvince && !province) {
            this.model.province = subProvince;
            this.model.city = subCity;
            this.model.area = area;
            this.model.areaValue = [subProvince, subCity, area];
            return;
          }
          // 直辖市
          if (!(subProvince || province)) {
            this.model.province = city;
            this.model.city = subCity;
            this.model.area = area;
            this.model.areaValue = [city, subCity, area];
            return;
          }
          this.model.province = province;
          this.model.city = city;
          this.model.area = area;
          this.model.areaValue = [province, city, area];
        }
      }
    },
  },
  created() {
    const id = this.$route.params.id;
    this.initData(id);
  },
};
</script>

<style lang="scss" scoped>
.fr {
  float: right;
}
.cbf::after {
  clear: both;
  content: "";
  display: block;
}
</style>
