<template>
  <div class="pub-info">
    <div>
      <el-avatar size="large" :src="info.avatar"></el-avatar>
    </div>
    <div class="content">
      <div class="user-name">{{ info.editName }}</div>
      <div class="time">{{ info.addTime }}</div>
      <div class="title">{{ info.editTitle }}</div>
      <div :class="{ text: true, expanded: expand }">
        <div v-html="info.editContent"></div>
        <div v-if="show">
          <div class="mt1 align-center">
            <template v-for="(item, i) in info.editPhoto">
              <img
                v-if="showIndex == i"
                :key="i"
                class="big-pic"
                :src="baseApi + item"
                @click="show = false"
              />
            </template>
          </div>
          <div class="flex mt2 gap2">
            <div
              v-for="(item, i) in info.editPhotoSmall"
              :key="i"
              :class="{ 'small-pic': true, active: showIndex == i }"
              @click="showHandle(i)"
            >
              <img :src="baseApi + item" />
            </div>
          </div>
        </div>
        <div class="flex mt1 gap1" v-else>
          <img
            v-for="(item, i) in info.editPhotoSmall"
            :key="i"
            class="pic"
            :src="baseApi + item"
            @click="showHandle(i)"
          />
        </div>
      </div>
      <div class="expand" @click="expand = !expand">
        {{ expand ? "收起" : "展开" }}
      </div>
    </div>
    <div v-if="self" class="remove">
      <el-button
        @click="$emit('removeInfo')"
        type="danger"
        icon="el-icon-delete"
        circle
      ></el-button>
    </div>
    <div v-else class="top">置顶</div>
  </div>
</template>

<script>
import { baseApi } from "@/utils/axios.config";

export default {
  name: "AATShopPublicInfoItem",
  props: ["info", "self", "removeInfo"],
  data() {
    return {
      expand: false,
      show: false,
      showIndex: 0,
      baseApi,
    };
  },
  methods: {
    showHandle(i) {
      if (!this.expand) this.expand = true;
      this.show = true;
      this.showIndex = i;
    },
  },
};
</script>

<style lang="scss" scoped>
.pub-info {
  width: 100%;
  background-color: #fff;
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.11);
  position: relative;
  padding: 23px 81px 30px 24px;
  display: flex;
  .content {
    flex: 1;
    margin-left: 20px;
    .user-name {
      color: rgba(36, 36, 36, 1);
      font-size: 16px;
    }
    .time {
      color: rgba(106, 106, 106, 1);
      font-size: 12px;
      margin-top: 9px;
    }
    .title {
      color: rgba(36, 36, 36, 1);
      font-size: 14px;
      margin-top: 15px;
    }
    .text {
      color: rgba(106, 106, 106, 1);
      font-size: 14px;
      margin-top: 8px;
      height: 76px;
      overflow: hidden;
      text-overflow: ellipsis;
      -webkit-line-clamp: 4;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      &.expanded {
        height: auto;
        display: block;
      }

      .pic {
        width: 168px;
        height: 168px;
        border-radius: 10px;
        cursor: pointer;
        object-fit: cover;
      }
      .big-pic {
        width: 520px;
        height: 520px;
        object-fit: cover;
        border-radius: 10px;
      }
      .small-pic {
        width: 66px;
        height: 66px;
        position: relative;
        border-radius: 10px;
        overflow: hidden;
        cursor: pointer;
        &.active {
          border: 4px solid rgba(255, 121, 0, 1);
        }
        img {
          width: 66px;
          height: 66px;
          border-radius: 10px;
          object-fit: cover;
        }

        &:not(.active)::after {
          content: "";
          display: block;
          width: 66px;
          height: 66px;
          position: absolute;
          top: 0;
          left: 0;
          opacity: 0.5;
          background-color: #fff;
        }
      }
    }
    .expand {
      color: rgba(255, 121, 0, 1);
      font-size: 14px;
      margin-top: 8px;
      cursor: pointer;
    }
  }
  .top {
    position: absolute;
    width: 58px;
    height: 30px;
    line-height: 30px;
    border-radius: 50px 0px 0px 50px;
    background-color: rgba(255, 121, 0, 1);
    color: #fff;
    font-size: 12px;
    text-align: center;
    right: 0;
    cursor: pointer;
  }
  .remove {
    position: absolute;
    right: 10px;
  }
}

.mt1 {
  margin-top: 14px;
}
.mt2 {
  margin-top: 40px;
}
.gap1 {
  gap: 18px;
}
.gap2 {
  gap: 10px;
}
.align-center {
  text-align: center;
}
</style>
