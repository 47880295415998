<template>
  <div>
    <div class="wrapper">
      <div class="location">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item>首页</el-breadcrumb-item>
          <el-breadcrumb-item>购物车</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
    </div>
    <!--循环列表.开始-->
    <div class="wrapper">
      <div class="cart-list">
        <div class="head-box">
          <div class="row-box row-check row-center">选择</div>
          <div class="row-box row-info">商品信息</div>
          <div class="row-box row-num">数量</div>
          <div class="row-box row-amount row-center">金额</div>
          <div class="row-box row-center">操作</div>
        </div>
        <div v-if="cartList.length === 0" class="nodata">
          <span class="icon iconfont icon-cart"></span>
          <span>购物车空空如也</span>
        </div>

        <div v-for="(item, index) in cartList" :key="index" class="list-wrap">
          <div class="list-head">
            <el-checkbox
              v-model="item.checked"
              @change="check('group', index)"
              >{{ item.merchantTitle }}</el-checkbox
            >
            <span v-if="item.isSelf == 1" class="label">自营</span>
          </div>
          <div
            v-for="(gitem, gindex) in item.goods"
            :key="gindex"
            class="list-box"
          >
            <div class="row-box row-check row-center">
              <el-checkbox
                v-model="gitem.checked"
                @change="check('item', index, gindex)"
              ></el-checkbox>
            </div>
            <div class="row-box row-info">
              <div class="goods-box">
                <div class="img-box">
                  <img :src="common.loadFile(gitem.imgUrl)" />
                </div>
                <div class="txt-box">
                  <router-link
                    class="title"
                    :to="'/shop/goods/show/' + gitem.goodsId"
                    >{{ gitem.title }}</router-link
                  >
                  <span class="info">{{ gitem.skuText }}</span>
                  <span class="price">{{ gitem.sellPrice }}</span>
                </div>
              </div>
            </div>
            <div class="row-box row-num">
              <el-input-number
                v-model="gitem.quantity"
                size="small"
                :min="1"
                :max="gitem.stockQuantity"
                @change="numberChange(gitem)"
              ></el-input-number>
            </div>
            <div class="row-box row-amount row-center">
              ￥{{ gitem.groupPrice }}
            </div>
            <div class="row-box row-center">
              <span
                class="del iconfont icon-delete"
                @click="deleteCartItem(index, gindex)"
              ></span>
            </div>
          </div>
        </div>

        <div v-if="cartList.length > 0" class="foot-box">
          <div class="check-box">
            <el-checkbox v-model="allChecked" @change="check('all')"
              >全选</el-checkbox
            >
            <span class="text">已选中 {{ totalNumber }} 件商品</span>
          </div>
          <div class="total-box">
            <div class="txt-box">
              <span>合计</span>
              <span class="price">{{ totalPrice }}</span>
            </div>

            <div class="btn-box">
              <span class="btn" @click="confirmOrder">去结算</span>
              <span class="btn ml-5" @click="submitOrder()">自行拿货</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AxiosAjax from "@/utils/axios.config";
import { mapActions } from "vuex";
export default {
  name: "AATShopCart",

  data() {
    return {
      totalPrice: 0, //总金额
      discountPrice: 0, //优惠金额
      allChecked: false, //全选状态  true|false
      totalNumber: 0, //已选中商品数量
      cartList: [], //购物车列表
    };
  },

  methods: {
    ...mapActions({
      getCartCount: "getCartCount",
    }),
    //初始化数据
    async initData() {
      let _this = this;
      await AxiosAjax({
        url: `/account/shop/cart/list`,
        loading: true,
        success(res) {
          _this.totalPrice = 0;
          _this.discountPrice = 0;
          _this.allChecked = false;
          _this.cartList = res.data;
        },
      });
    },
    //删除
    async deleteCartItem(index, gindex) {
      let _this = this;
      let row = _this.cartList[index].goods[gindex];
      let id = row.id;
      _this
        .$confirm("确认要删除该吗？", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
        .then(() => {
          AxiosAjax({
            method: "delete",
            url: `/account/shop/cart/${id}`,
            loading: true,
            successMsg: "已删除成功",
            success() {
              _this.getCartCount();
              //删除所选的商品
              _this.cartList[index].goods.splice(gindex, 1);
              //如果商家下面没有商品则删除商家
              if (_this.cartList[index].goods.length == 0) {
                _this.cartList.splice(index, 1);
              }
              _this.calcTotal();
            },
          });
        })
        .catch(() => {});
    },
    //数量
    async numberChange(val) {
      let _this = this;
      if (!val.quantity) {
        return false;
      }
      await AxiosAjax({
        method: "put",
        url: `/account/shop/cart/${val.id}?quantity=${val.quantity}`,
        success() {
          //重新统计
          _this.calcTotal();
        },
      });
    },
    //单项选中
    check(type, index, gindex) {
      let _this = this;
      if (type === "item") {
        let row = _this.cartList[index];
        let item = row.goods[gindex];
        console.log(item);
        //只有这样视图才更新
        //_this.$set(item, 'checked', !item.checked);
        //检查是否全选组
        let group = row.goods.find((val) => !val.checked);
        _this.$set(row, "checked", !group);
        //检查是否全选
        let all = _this.cartList.find((val) => !val.checked);
        _this.allChecked = !all;
      } else if (type === "group") {
        //选中下面所有商品
        let row = _this.cartList[index];
        row.goods.forEach((item) => {
          _this.$set(item, "checked", row.checked);
        });
        //_this.$set(row, 'checked', !row.checked);
        //检查是否全选
        let all = _this.cartList.find((val) => !val.checked);
        _this.allChecked = !all;
      } else {
        _this.cartList.forEach((row) => {
          row.checked = _this.allChecked;
          row.goods.forEach((item) => {
            _this.$set(item, "checked", _this.allChecked);
          });
        });
      }
      //重新统计
      _this.calcTotal();
    },
    //计算总价
    calcTotal() {
      let list = this.cartList;
      if (list.length === 0) {
        this.totalPrice = 0;
        this.discountPrice = 0;
        this.totalNumber = 0;
        return;
      }
      let total = 0;
      let original = 0;
      let num = 0;
      list.forEach((row) => {
        row.goods.forEach((item) => {
          if (item.checked) {
            original += item.sellPrice * item.quantity;
            total += item.groupPrice * item.quantity;
            num++;
          }
        });
      });
      this.totalPrice = Number(total.toFixed(2));
      this.discountPrice = Number((original - total).toFixed(2));
      this.totalNumber = num;
    },
    //创建订单
    confirmOrder() {
      let goodsData = [];
      this.cartList.forEach((item) => {
        item.goods.forEach((gitem) => {
          if (gitem.checked) {
            goodsData.push({
              productId: gitem.productId,
              quantity: gitem.quantity,
            });
          }
        });
      });
      if (goodsData.length === 0) {
        this.$message({
          message: "请选择购物车商品",
          type: "warning",
        });
        return;
      }
      //跳转链接
      this.$router.push(
        `/shop/order/confirm?type=0&data=${JSON.stringify(goodsData)}`
      );
    },
    // 自行拿货
    async submitOrder() {
      const _this = this;
      let goodsData = [];
      this.cartList.forEach((item) => {
        item.goods.forEach((gitem) => {
          if (gitem.checked) {
            goodsData.push({
              productId: gitem.productId,
              quantity: gitem.quantity,
            });
          }
        });
      });
      if (goodsData.length === 0) {
        this.$message({
          message: "请选择购物车商品",
          type: "warning",
        });
        return;
      }

      const loading = this.$loading({
        lock: true,
        text: "生成清单中。。。",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });

      let cartTotal;
      //加载商品列表
      await AxiosAjax({
        url: `/account/shop/cart/buy?jsonData=${JSON.stringify(goodsData)}`,
        success(res) {
          cartTotal = res.data;
        },
      });
      //开始保存订单
      let model = {};
      model.orderType = 4; //0普通订单1抢购订单2换购订单
      model.activeId = 0; //抢购ID或换购ID,当订单类型为1时抢购ID，为2时换购ID
      model.paymentId = 2;
      model.acceptName = "";
      model.mobile = "";
      model.province = "";
      model.city = "";
      model.area = "";
      model.address = "";
      model.orderList = [];
      //循环列表
      cartTotal.list.forEach((item) => {
        let modelt = {};
        let orderGoods = [];
        modelt.merchantId = item.merchantId;
        modelt.activeId = 0;
        modelt.deliveryId = 8;
        modelt.useCouponId = item.couponId;
        modelt.isInsure = 0;
        modelt.insurePrice = 0;
        modelt.postscript = item.postscript;
        //modelt.acceptTime = item.acceptTime;
        item.goods.forEach((gitem) => {
          orderGoods.push({
            productId: gitem.productId,
            quantity: gitem.quantity,
          });
        });
        modelt.orderGoods = orderGoods;
        //添加进订单列表
        model.orderList.push(modelt);
      });
      loading.close();
      this.$message({
        message: "清单提交中。。。",
        type: "warning",
      });
      //提交订单
      AxiosAjax({
        url: `/account/order`,
        method: "post",
        data: model,
        loading: true,
        success() {
          _this
            .$confirm("清单提交成功，是否查看清单详情？", "提示", {
              confirmButtonText: "确定",
              cancelButtonText: "取消",
              type: "warning",
            })
            .then(() => {
              // window.location.href = `/shop/order/show/${res.data.id}`;
              window.location.href = `/account/cargo/list`;
            })
            .catch(() => {
              window.location.href = `/home`;
            });
        },
      });
    },
  },
  created() {
    this.initData();
  },
};
</script>

<style lang="scss" scoped>
.ml-5 {
  margin-left: 5px;
}
</style>
