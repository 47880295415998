<template>
  <div id="app">
    <AppHeader v-if="!$route.meta.hideHeader"></AppHeader>
    <router-view name="shop" />
    <router-view name="auth" />
    <AppFooter v-if="!$route.meta.hideFooter"></AppFooter>
  </div>
</template>

<script>
import AppHeader from './components/AppHeader.vue';
import AppFooter from './components/AppFooter.vue';

export default {
  name: 'App',
  components: {
    AppHeader,
    AppFooter,
  },
};
</script>
