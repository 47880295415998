<template>
    <div>
        <!--导航菜单.开始-->
        <div class="wrapper">
            <div class="location">
                <el-breadcrumb separator-class="el-icon-arrow-right">
                    <el-breadcrumb-item>首页</el-breadcrumb-item>
                    <el-breadcrumb-item>在线支付</el-breadcrumb-item>
                    <el-breadcrumb-item>支付结果</el-breadcrumb-item>
                </el-breadcrumb>
            </div>
        </div>
        <!--导航菜单.结束-->

        <div class="wrapper">
            <div class="payment-box">
                <div class="head-box">
                    <div class="title" v-if="model.status == 1">
                        <span class="icon iconfont icon-time"></span>
                        <span>支付未完成</span>
                    </div>
                    <div class="title" v-else-if="model.status == 2">
                        <span class="icon iconfont icon-check"></span>
                        <span>订单支付成功</span>
                    </div>
                    <div class="title" v-else>
                        <span class="icon iconfont icon-close"></span>
                        <span>交易已取消</span>
                    </div>
                    <div class="item-box">
                        <div class="item">
                            <div class="left">订单编号</div>
                            <div class="right">{{ model.tradeNo }}</div>
                        </div>
                        <div class="item">
                            <div class="left">订单金额</div>
                            <div class="right">
                                <span class="price">{{ model.paymentAmount }}</span>元
                            </div>
                        </div>
                    </div>
                    <div class="btn-box">
                        <el-button type="warning" class="btn" @click="common.linkUrl('/account/recharge/list')"
                            v-if="model.tradeType == 1">查看订单</el-button>
                        <el-button type="warning" class="btn" @click="common.linkUrl('/account/order/list')"
                            v-else>查看订单</el-button>
                        <el-button type="danger" class="btn" @click="common.linkUrl('/account')">会员中心</el-button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import AxiosAjax from "@/utils/axios.config";

export default {
    name: 'AATShopResult',

    data() {
        return {
            model: {}
        };
    },
    methods: {
        //加载订单信息
        async initData(no) {
            let _this = this;
            if (!no) {
                this.$alert('订单参数有误，请检查重试', '错误提示', {
                    confirmButtonText: '确定',
                    callback: () => {
                        _this.common.back(-1);
                    }
                });
                return;
            }
            await AxiosAjax({
                url: `/account/order/collection/${no}`,
                success(res) {
                    _this.model = res.data;
                }
            });
        },
    },
    created() {
        let tradeNo = this.common.queryValue("no");
        this.initData(tradeNo);
    }
};
</script>

<style lang="scss" scoped></style>