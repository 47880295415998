<template>
  <div class="wrapper">
    <div class="account-box">
      <AccountNav :account="account" model="message" />
      <div class="right-box">
        <div class="tab-box">
          <div class="menu-box">
            <div class="item-box active">
              <span>站内消息</span>
            </div>
          </div>
          <div class="btn-box">
            <!-- <el-link
              type="danger"
              :underline="false"
              icon="el-icon-plus"
              @click="deleteCheckAll"
              >添加</el-link
            > -->
            <el-link
              type="danger"
              :underline="false"
              icon="el-icon-delete"
              @click="deleteCheckAll"
              >删除</el-link
            >
          </div>
        </div>
        <div class="content">
          <el-table
            ref="multipleTable"
            :data="listData"
            stripe
            :show-header="true"
            class="table-list"
            @selection-change="handleSelectionChange"
            @expand-change="handleExpandChange"
          >
            <el-table-column type="selection" width="45"></el-table-column>
            <el-table-column type="expand">
              <template v-slot="props">
                <p class="expand-content" v-html="props.row.content"></p>
              </template>
            </el-table-column>
            <el-table-column label="时间" prop="addTime"> </el-table-column>
            <el-table-column label="标题" prop="title"> </el-table-column>
            <el-table-column label="是否已读" prop="isRead">
              <template v-slot="props">{{
                props.row.userId === 0
                  ? "/"
                  : props.row.isRead
                  ? "已读"
                  : "未读"
              }}</template>
            </el-table-column>
            <el-table-column label="操作" align="center" width="110">
              <template slot-scope="scope">
                <el-button
                  size="mini"
                  type="danger"
                  icon="el-icon-delete"
                  @click="deleteItem(scope.row.id)"
                ></el-button>
              </template>
            </el-table-column>
          </el-table>

          <div class="pager-box">
            <el-pagination
              background
              :hide-on-single-page="true"
              @current-change="handleCurrentChange"
              :page-size="pageSize"
              layout="prev, pager, next"
              :total="totalCount"
            >
            </el-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AxiosAjax from "@/utils/axios.config";
import AccountNav from "@/components/AccountNav.vue";
export default {
  name: "AATShopMessageList",
  components: {
    AccountNav,
  },
  data() {
    return {
      account: null, //会员信息
      totalCount: 0, //总数量
      pageSize: 10, //每页显示条数
      pageIndex: 1, //当前页码
      listData: [],
    };
  },
  methods: {
    //加载列表

    async loadData() {
      let _this = this;
      const url = `/client/member/message?merchantId=${_this.account.userId}&pageSize=${_this.pageSize}&pageIndex=${_this.pageIndex}&orderBy=AddTime`;
      AxiosAjax({
        url,
        loading: true,
        success: function (res) {
          _this.listData = res.data;
          let pageInfo = JSON.parse(res.headers["x-pagination"]);
          _this.totalCount = pageInfo.totalCount;
          _this.pageSize = pageInfo.pageSize;
          _this.pageIndex = pageInfo.pageIndex;
        },
        error: function () {
          _this.listData = [];
        },
      });
    },
    //初始化数据
    async initData() {
      let _this = this;
      //加载会员信息
      await AxiosAjax({
        url: `/account/member`,
        success: function (res) {
          _this.account = res.data;
        },
      });
      //加载列表
      await _this.loadData();
    },
    //跳转到第几页
    handleCurrentChange(val) {
      if (this.pageIndex != val) {
        this.pageIndex = val;
        this.loadData();
      }
    },
    //多选删除
    deleteCheckAll() {
      let _this = this; //当前页面
      //拿到选中的数据
      let list = _this.multipleSelection;
      //检查是否有选中
      if (!list) {
        _this.$message({ type: "warning", message: "请选择要删除的记录！" });
        return false;
      }
      //执行删除操作
      _this
        .$confirm("确认要删除该记录吗？", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
        .then(function () {
          let delIds = []; //创建一个数组
          list.forEach((item) => {
            //遍历源数据
            _this.listData.forEach((v) => {
              if (item.id == v.id) {
                delIds.push(item.id);
              }
            });
          });
          AxiosAjax({
            method: "delete",
            url: "/client/member/message?ids=" + delIds.join(","),
            loading: true,
            successMsg: "已删除成功",
            success: function () {
              _this.pageIndex = 1;
              _this.totalCount = 0;
              _this.loadData(); //重新加载列表
            },
          });
        })
        .catch(function () {});
    },
    //单项删除
    deleteItem(id) {
      let _this = this;
      _this
        .$confirm("确认要删除该记录吗？", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
        .then(() => {
          AxiosAjax({
            method: "delete",
            url: `/client/member/message/${id}`,
            loading: true,
            successMsg: "已删除成功",
            success: function () {
              _this.pageIndex = 1;
              _this.totalCount = 0;
              _this.loadData();
            },
          });
        })
        .catch(() => {});
    },
    //选中第几行
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    handleExpandChange(row) {
      if (row.isRead == 1) return;
      const _this = this;
      AxiosAjax({
        url: `/client/member/message/${row.id}`,
        method: "put",
        data: {
          ...row,
          isRead: 1,
        },
        success: function () {
          _this.$set(row, "isRead", 1);
        },
      });
    },
  },
  created() {
    this.initData();
  },
};
</script>

<style lang="scss" scoped>
.expand-content {
  padding: 20px;
}
</style>
