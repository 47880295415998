<template>
  <div class="login-box">
    <div class="wrap-box">
      <div class="left-box">
        <div class="tab-box">
          <span
            @click="toggleMethod(0, 'userForm')"
            class="text"
            :class="{ active: regMethod === 0 }"
            >用户名注册</span
          >
          <span
            @click="toggleMethod(1, 'phoneForm')"
            class="text"
            :class="{ active: regMethod === 1 }"
            >手机注册</span
          >
          <!-- <span @click="toggleMethod(2, 'emailForm')" class="text"
                        :class="{ active: regMethod === 2 }">邮箱注册</span> -->
        </div>
        <!--用户名注册.开始-->
        <el-form
          v-if="regMethod === 0"
          :model="userModel"
          :rules="rules"
          ref="userForm"
          @submit.native.prevent
        >
          <el-form-item prop="userName">
            <el-input
              type="text"
              v-model="userModel.userName"
              placeholder="请输入账户名"
              prefix-icon="el-icon-user"
            ></el-input>
          </el-form-item>
          <el-form-item prop="password">
            <el-input
              type="password"
              v-model="userModel.password"
              placeholder="请输入密码"
              prefix-icon="el-icon-lock"
            ></el-input>
          </el-form-item>
          <el-form-item prop="codeValue">
            <el-input
              type="text"
              v-model="userModel.codeValue"
              placeholder="请输入验证码"
              prefix-icon="el-icon-key"
            >
              <template slot="append">
                <img
                  @click="initCode"
                  class="code"
                  :src="srcData"
                  alt="点击切换验证码"
                />
              </template>
            </el-input>
          </el-form-item>
          <el-form-item>
            <el-button
              type="primary"
              class="btn"
              native-type="submit"
              :loading="btnLoading"
              :disabled="btnLoading"
              @click="submitForm('userForm')"
              >确认注册</el-button
            >
          </el-form-item>
        </el-form>
        <!--用户名注册.结束-->
        <!--手机注册.开始-->
        <el-form
          v-if="regMethod === 1"
          :model="phoneModel"
          :rules="rules"
          ref="phoneForm"
          @submit.native.prevent
        >
          <el-form-item prop="phone">
            <el-input
              type="text"
              v-model="phoneModel.phone"
              placeholder="请输入手机号码"
              prefix-icon="el-icon-user"
            ></el-input>
          </el-form-item>
          <el-form-item prop="codeValue">
            <el-input
              type="text"
              v-model="phoneModel.codeValue"
              placeholder="请输入手机验证码"
              prefix-icon="el-icon-key"
            >
              <template slot="append">
                <el-button v-if="timer > 0">重新获取({{ timer }}s)</el-button>
                <el-button v-else @click="sendPhoneCode">获取验证码</el-button>
              </template>
            </el-input>
          </el-form-item>
          <el-form-item prop="password">
            <el-input
              type="password"
              v-model="phoneModel.password"
              placeholder="请输入密码"
              prefix-icon="el-icon-lock"
            ></el-input>
          </el-form-item>
          <el-form-item>
            <el-button
              type="primary"
              class="btn"
              native-type="submit"
              :loading="btnLoading"
              :disabled="btnLoading"
              @click="submitForm('phoneForm')"
              >确认注册</el-button
            >
          </el-form-item>
        </el-form>
        <!--手机注册.结束-->
        <!--邮箱注册.开始-->
        <!-- <el-form v-if="regMethod === 2" :model="emailModel" :rules="rules" ref="emailForm" @submit.native.prevent>
                    <el-form-item prop="email">
                        <el-input type="text" v-model="emailModel.email" placeholder="请输入邮箱账户"
                            prefix-icon="el-icon-user"></el-input>
                    </el-form-item>
                    <el-form-item prop="codeValue">
                        <el-input type="text" v-model="emailModel.codeValue" placeholder="请输入邮箱验证码"
                            prefix-icon="el-icon-key">
                            <template slot="append">
                                <el-button v-if="timer > 0">重新获取({{ timer }}s)</el-button>
                                <el-button v-else @click="sendEmailCode">获取验证码</el-button>
                            </template>
                        </el-input>
                    </el-form-item>
                    <el-form-item prop="password">
                        <el-input type="password" v-model="emailModel.password" placeholder="请输入密码"
                            prefix-icon="el-icon-lock"></el-input>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" class="btn" native-type="submit" :loading="btnLoading"
                            :disabled="btnLoading" @click="submitForm('emailForm')">确认注册</el-button>
                    </el-form-item>
                </el-form> -->
        <!--邮箱注册.结束-->
        <div class="foot-box">
          <div class="text">
            <el-checkbox v-model="agree">已阅读并同意</el-checkbox>
            <RouterLink to="/account/article?article=3">《隐私策略》</RouterLink>
          </div>
          <span class="text"
            >已有账号？<router-link to="/account/login"
              >马上登录</router-link
            ></span
          >
        </div>
      </div>

      <div class="right-box">
        <div class="head-box">
          <span class="title">会员注册</span>
          <span class="text">登录后即可享受更多的会员权益</span>
        </div>
        <div class="img-box">
          <img src="@/assets/images/img-1.png" />
        </div>
        <div class="foot-box">
          <router-link to="/home" class="icon iconfont icon-home"
            >返回首页</router-link
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import AxiosAjax from "@/utils/axios.config";
import AxiosAjax, { saveToken } from "@/utils/axios.config";
import { RouterLink } from "vue-router";

export default {
  name: "AATShopRegister",
  components: { RouterLink },
  data() {
    return {
      btnLoading: false,
      timer: 0, //计时器
      agree: false, //同意协议
      regMethod: 0, //注册方式0用户名密码1手机2邮箱
      srcData: null, //验证码路径
      //用户名注册
      userModel: {
        method: 0,
        userName: null,
        password: null,
        codeKey: null,
        codeValue: null,
      },
      //手机注册
      phoneModel: {
        method: 1,
        phone: null,
        password: null,
        codeKey: null,
        codeValue: null,
      },
      //邮箱注册
      emailModel: {
        method: 2,
        email: null,
        password: null,
        codeKey: null,
        codeValue: null,
      },
      rules: {
        userName: [
          { required: true, message: "请输入登录用户名", trigger: "blur" },
          {
            min: 3,
            max: 128,
            message: "长度在 3 到 128 个字符",
            trigger: "blur",
          },
          {
            pattern: /^[a-zA-Z0-9_]*$/,
            message: "只能是字母数字下划线",
            trigger: "blur",
          },
        ],
        email: [
          { required: true, message: "请输入邮箱账户", trigger: "blur" },
          {
            pattern: /^(\w)+(\.\w+)*@@(\w)+((\.\w+)+)$/,
            message: "邮箱格式有误",
            trigger: "blur",
          },
        ],
        phone: [
          { required: true, message: "请输入手机号码", trigger: "blur" },
          {
            pattern: /^1[0-9]{10}$/,
            message: "手机号码格式不正确",
            trigger: "blur",
          },
        ],
        password: [
          { required: true, message: "请输入登录密码", trigger: "blur" },
          { min: 6, max: 128, message: "密码长度在至少6位", trigger: "blur" },
          {
            pattern: /^[a-zA-Z][a-zA-Z0-9_]*$/,
            message: "以字母开头至少包含数字",
            trigger: "blur",
          },
        ],
        codeValue: [
          { required: true, message: "请输入验证码", trigger: "blur" },
          {
            lmin: 4,
            max: 8,
            message: "请输入 4 到 8 位的验证码",
            trigger: "blur",
          },
        ],
      },
    };
  },
  methods: {
    //切换注册方式
    toggleMethod(type, formName) {
      this.regMethod = type;
      this.$nextTick(() => {
        this.$refs[formName].clearValidate();
      });
    },
    //获取图形验证码
    initCode() {
      let _this = this;
      AxiosAjax({
        url: "/verifycode",
        success: function (res) {
          _this.userModel.codeKey = res.data.key;
          _this.srcData = res.data.data;
        },
      });
    },
    //发送手机验证码
    sendPhoneCode() {
      let _this = this;
      if (!/(^1[3|4|5|7|8][0-9]{9}$)/.test(_this.phoneModel.phone)) {
        _this.$message({
          message: "提示，请输入正确的手机号码",
          type: "warning",
        });
        return;
      }
      //发送短信
      AxiosAjax({
        url: `/verifycode/mobile/${_this.phoneModel.phone}?check=0`,
        success: function (res) {
          _this.timer = 120; //设置120秒
          _this.countDown(); //开始倒计时
          _this.phoneModel.codeKey = res.data.codeKey;
        },
      });
    },
    //发送邮件验证码
    sendEmailCode() {
      let _this = this;
      if (
        !/(^[_a-z0-9-]+(\.[_a-z0-9-]+)*@@[a-z0-9-]+(\.[a-z0-9-]+)*(\.[a-z]{2,})$)/.test(
          _this.emailModel.email
        )
      ) {
        _this.$message({
          message: "提示，请输入正确的邮箱账户",
          type: "warning",
        });
        return;
      }
      //发送邮件
      AxiosAjax({
        url: `/verifycode/email/${_this.emailModel.email}?check=0`,
        success: function (res) {
          _this.timer = 120; //设置120秒
          _this.countDown(); //开始倒计时
          _this.emailModel.codeKey = res.data.codeKey;
        },
      });
    },
    //计时器
    countDown() {
      let _this = this;
      if (_this.timer > 0) {
        _this.timer--;
        setTimeout(function () {
          _this.countDown();
        }, 1000);
      }
    },
    //提交表单
    submitForm(formName) {
      let _this = this;
      if (!_this.agree) {
        _this.$message({
          message: "提示，请先阅读并同意协议",
          type: "warning",
        });
        return;
      }
      //表单验证
      _this.$refs[formName].validate((valid) => {
        if (valid) {
          if (_this.regMethod === 1) {
            _this.submitData(_this.phoneModel);
          } else if (_this.regMethod === 2) {
            _this.submitData(_this.emailModel);
          } else {
            _this.submitData(_this.userModel);
          }
        } else {
          return false;
        }
      });
    },
    //请求API接口
    submitData(model) {
      let _this = this;
      AxiosAjax({
        url: `/account/member/register`,
        method: "post",
        data: model,
        beforeSend() {
          _this.btnLoading = true;
        },
        complete() {
          _this.btnLoading = false;
        },
        success: function (res) {
          _this
            .$confirm("注册成功，是否登录会员账户？", "提示", {
              confirmButtonText: "确定",
              cancelButtonText: "取消",
              type: "success",
            })
            .then(() => {
              //  _this.common.linkUrl('/account/login');

              //存储ACCESSTOKEN
              saveToken(res.data.accessToken, res.data.refreshToken);
              //跳转到管理页
              _this.$router.push("/account");
            })
            .catch(() => {
              _this.common.linkUrl("/home");
            });
        },
      });
    },
  },
  created() {
    this.initCode();
  },
};
</script>

<style lang="scss" scoped></style>
