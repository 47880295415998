<template>
  <span>{{ d }} 天 {{ h }} : {{ i }} : {{ s }}</span>
</template>

<script>
export default {
  props: {
    end: {
      type: String,
      default: null,
    },
  },
  data: function () {
    return {
      timer: null,
      syncFlag: false,
      d: '00',
      h: '00',
      i: '00',
      s: '00',
      leftTime: 0,
      seconds: 0,
    };
  },
  methods: {
    toSeconds(endTime) {
      let endDate = new Date(endTime.replace(/-/g, '/'));
      let timestamp = parseInt(endDate.getTime() / 1000, 10);
      return timestamp - parseInt(new Date().getTime() / 1000, 10);
    },
    timeUp() {
      clearInterval(this.timer);
      this.$emit('timeup');
    },
    countDown() {
      let seconds = this.seconds;
      let [day, hour, minute, second] = [0, 0, 0, 0];
      if (seconds > 0) {
        day = Math.floor(seconds / (60 * 60 * 24));
        hour = Math.floor(seconds / (60 * 60)) - day * 24;
        minute = Math.floor(seconds / 60) - day * 24 * 60 - hour * 60;
        second =
          Math.floor(seconds) -
          day * 24 * 60 * 60 -
          hour * 60 * 60 -
          minute * 60;
      } else {
        this.timeUp();
      }
      if (day < 10) {
        day = '0' + day;
      }
      if (hour < 10) {
        hour = '0' + hour;
      }
      if (minute < 10) {
        minute = '0' + minute;
      }
      if (second < 10) {
        second = '0' + second;
      }
      this.d = day;
      this.h = hour;
      this.i = minute;
      this.s = second;
    },
    startData() {
      this.seconds = this.toSeconds(this.end);
      if (this.seconds <= 0) {
        return;
      }
      this.countDown();
      this.timer = setInterval(() => {
        this.seconds--;
        if (this.seconds < 0) {
          this.timeUp();
          return;
        }
        this.countDown();
      }, 1000);
    },
    changeFlag() {
      if (!this.syncFlag) {
        this.seconds = this.toSeconds(this.end);
        this.startData();
        this.syncFlag = true;
      }
    },
  },
  created: function () {
    this.startData();
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
  watch: {
    end() {
      this.changeFlag();
    },
  },
};
</script>
