<template>
  <!--页面底部.开始-->
  <div class="footer">
    <div class="wrapper">
      <ul class="delivery-box">
        <li class="list-box">
          <span class="icon iconfont icon-shopping"></span>
          <span class="text">品种齐全，购物轻松</span>
        </li>
        <li class="list-box">
          <span class="icon iconfont icon-deliver"></span>
          <span class="text">多仓直发，极速配送</span>
        </li>
        <li class="list-box">
          <span class="icon iconfont icon-service"></span>
          <span class="text">正品行货，精致服务</span>
        </li>
        <li class="list-box">
          <span class="icon iconfont icon-ticket"></span>
          <span class="text">天天低价，畅选无忧</span>
        </li>
      </ul>
      <div class="copyright">
        <p>
          <span
            >版权所有 @ 2009-2023 {{ webCompany }} AII Rights Reserved.</span
          >
        </p>
        <a href="https://beian.miit.gov.cn/#/Integrated/index" target="_blank" class="filings"
          >苏ICP备2023047104号-1</a
        >
      </div>
    </div>
  </div>
  <!--页面底部.结束-->
</template>

<script>
import AxiosAjax from "@/utils/axios.config";
export default {
  data() {
    return {
      webCompany: "",
    };
  },
  methods: {
    initData() {
      const _this = this;
      AxiosAjax({
        url: `/client/setting/0`,
        success(res) {
          _this.webCompany = res.data.webCompany;
          document.title = res.data.webName;
        },
      });
    },
  },
  created() {
    this.initData();
  },
};
</script>

<style lang="scss" scoped>
.filings {
  color: #4ea1db;
  &:hover,&:focus{
    color: #ca0c16;
  }
}
</style>
