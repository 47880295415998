<template>
  <div>
    <div class="pub-btn" @click="show = true">
      <i class="el-icon-edit pub-icon"></i>
    </div>
    <el-dialog
      destroy-on-close
      title="发布信息"
      center
      :visible.sync="show"
      class="dialog"
    >
      <div class="dialog-content">
        <el-form ref="form" :model="form" label-width="80px">
          <el-form-item label="标题">
            <el-input
              v-model="form.title"
              class="title"
              placeholder="请输入标题"
            ></el-input>
          </el-form-item>
          <el-form-item label="内容">
            <el-input
              type="textarea"
              v-model="form.content"
              class="content"
              placeholder="请输入内容"
              :autosize="{ minRows: 12 }"
            ></el-input>
          </el-form-item>
          <el-form-item label="图片">
            <div class="flex gap1">
              <el-upload
                :action="uploadPath"
                list-type="picture-card"
                class="upload"
                :on-success="uploadHandle"
                :on-remove="removeHandle"
                :limit="4"
              >
                <i class="el-icon-plus"></i>
              </el-upload>
            </div>
          </el-form-item>
        </el-form>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button class="public-btn" @click="publicInfo">发布</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { baseApi } from "@/utils/axios.config";
import AxiosAjax from "@/utils/axios.config";

export default {
  name: "AATShopPublicInfoBtn",
  data() {
    return {
      show: false,
      uploadPath: baseApi + "/upload?water=1&thumb=1",
      form: {
        title: "",
        content: "",
        filePath: [],
        thumbPath: [],
      },
    };
  },
  methods: {
    publicInfo() {
      const _this = this;
      AxiosAjax({
        url: `/client/message`,
        method: "post",
        data: {
          editTitle: _this.form.title,
          editContent: _this.form.content,
          editPhoto: _this.form.filePath.join(","),
          editPhotoSmall: _this.form.thumbPath.join(","),
        },
        loading: true,
        success() {
          _this.show = false;
        },
        error(err) {
          _this.show = false;
          _this.$message.error(err.message);
        },
      });
    },
    updatePath(path) {
      this.form.filePath = path.map((item) => item.filePath);
      this.form.thumbPath = path.map((item) => item.thumbPath[0]);
    },
    uploadHandle(res, file, fileList) {
      this.updatePath(fileList.map((item) => item.response[0]));
    },
    removeHandle(file, fileList) {
      this.updatePath(fileList.map((item) => item.response[0]));
    },
  },
};
</script>

<style lang="scss" scoped>
.pub-btn {
  width: 50px;
  height: 50px;
  background-color: #ff7900;
  border-radius: 50%;
  text-align: center;
  cursor: pointer;
  box-shadow: 0px 0px 6px 0px rgba(191, 91, 0, 0.67);
  .pub-icon {
    line-height: 50px;
    font-size: 30px;
    color: #fff;
  }
}
.dialog-content {
  display: flex;
  justify-content: center;
  min-width: 758px;
}

.public-btn {
  width: 380px;
  height: 40px;
  line-height: 20px;
  border-radius: 5px;
  background-color: rgba(255, 121, 0, 1);
  color: #fff;
  font-size: 14px;
  text-align: center;
}

.title {
  width: 469px;
  height: 40px;
}
.content {
  width: 469px;
  height: 250px;
}
.flex {
  display: flex;
}
.gap1 {
  gap: 22px;
}
.upload {
  ::v-deep.el-upload--picture-card {
    width: 100px;
    height: 100px;
    line-height: 100px;
  }
}
.dialog::v-deep {
  .el-dialog {
    border-radius: 14px;
    min-width: 785px;
  }
}
</style>
