export function ReplaceImagesPath(content) {
    if (!content) return;
    var baseApi = process.env.VUE_APP_BaseUrl; //获取API地址
    const reg = new RegExp("IMG[^>]*?src\\s*=\\s*(?:\"(?<a>[^\"]*)\"|'(?<b>[^']*)')", 'i');
    const found = content.match(reg)
    const imgUri = found && found[1] ?  found[1] : '';
    if (imgUri && (!(imgUri.startsWith("http://") || imgUri.startsWith("https://")))) {
        const newImgPath = baseApi + imgUri;
        content = content.replace(imgUri, newImgPath);
    }
    // const m = reg.Matches(content);
    // for (let math in m) {
    //     const imgUri = math.Groups['a'].Value;
    //     if (imgUri.StartsWith("http://") || imgUri.StartsWith("https://")) {
    //         continue;
    //     }
    //     const newImgPath = baseApi + imgUri;
    //     content = content.Replace(imgUri, newImgPath);
    // }
    return content;
}