<!-- eslint-disable vue/require-v-for-key -->
<template>
  <div class="container">
    <div class="wrapper">
      <div class="slider-banner">
        <div class="goods-index-container swiper-container">
          <div class="swiper-wrapper">
            <div
              v-for="(item, index) in advertList.banner"
              :key="index"
              class="swiper-slide"
            >
              <a :title="item.title" :href="item.linkUrl">
                <img :src="common.loadFile(item.filePath)" />
              </a>
            </div>
          </div>
          <div class="swiper-pagination"></div>
          <div class="swiper-button-prev swiper-button-white"></div>
          <div class="swiper-button-next swiper-button-white"></div>
        </div>
      </div>
      <div class="advlist-store">
        <div data-v-c4ce1d54="" class="title">
          <h2 data-v-c4ce1d54="">店铺推荐</h2>
          <span data-v-c4ce1d54="">爱爱兔优秀店铺，万千商品放心选购</span
          ><a data-v-c4ce1d54="" href="/shop/store/list" class="">更多</a>
        </div>
        <ul>
          <li v-for="(store, i) in advertStoreList.banner" :key="i">
            <a :href="store.linkUrl" target="_blank">
              <img :src="common.loadFile(store.filePath)" :alt="store.title" />
            </a>
          </li>
        </ul>
      </div>
      <div class="new-goods">
        <div class="title">
          <h2>新品推荐</h2>
          <span>爱爱兔个性推荐，毛绒玩具任你选择</span>
          <router-link to="/shop/selected/list">更多</router-link>
        </div>
        <div class="content">
          <ul class="store-list">
            <li
              :class="{ 'store-item': true, active: merchantIndex == i }"
              v-for="(item, i) in merchantList"
              :key="i"
              @click="clickStoreHandle(i)"
            >
              {{ item.title }}
            </li>
          </ul>
          <div class="merchant" v-if="merchant">
            <router-link :to="`/shop/store/index/${merchant.merchantId}`">
              <img
                :src="common.loadFile(merchant.imgUrl)"
                class="merchant-img"
              />
            </router-link>
            <div class="swiper-container newgoods-recommend">
              <div class="swiper-wrapper">
                <ul
                  class="merchant-goods-list swiper-slide"
                  v-for="(group, index) in merchant.group"
                  :key="index"
                >
                  <li v-for="(goods, i) in group" :key="i">
                    <router-link :to="`/shop/goods/show/${goods.id}`">
                      <div class="goods">
                        <div class="goods-content">
                          <img
                            :src="common.loadFile(goods.imgUrl)"
                            :alt="goods.title"
                          />
                        </div>
                        <div class="sell-price">
                          ￥ <span class="num">{{ goods.sellPrice }}</span>
                        </div>
                      </div>
                    </router-link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="recommend">
        <div class="title">
          <h2>猜你喜欢</h2>
          <span>爱兔最新商品，毛绒玩具批发火爆热销中</span>
          <router-link to="/shop/goods/list">更多</router-link>
        </div>
        <div class="goods-list">
          <router-link
            class="list-box"
            :to="`/shop/goods/show/${item.id}`"
            v-for="(item, i) in recommendList"
            :key="i"
          >
            <Goods
              :imgUrl="item.imgUrl"
              :title="item.title"
              :sellPrice="item.sellPrice"
            />
          </router-link>
        </div>
        <div class="mt-10">
          <router-link to="/shop/goods/list" class="color-primary">
            更多商品
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AxiosAjax from "@/utils/axios.config";
import Swiper from "swiper/swiper-bundle.min.js";
import Goods from "@/components/RecommendGoods.vue";
export default {
  components: {
    Goods,
  },
  data: function () {
    return {
      recommendList: [],
      advertList: {
        banner: [],
      },
      advertStoreList: { banner: [] },
      merchantList: [],
      merchantIndex: 0,
      swiper: null,
    };
  },
  computed: {
    merchant() {
      const merchant = this.merchantList[this.merchantIndex];
      if (!merchant) return;
      const arr = [];
      for (let i = 0; i < merchant.shopGoods.length; i += 4) {
        const group = [
          merchant.shopGoods[i],
          merchant.shopGoods[i + 1],
          merchant.shopGoods[i + 2],
          merchant.shopGoods[i + 3],
        ];
        arr.push(group);
      }
      merchant.group = arr;
      return merchant;
    },
  },
  watch: {
    merchant(newValue) {
      if (!newValue) return;
      // if (this.swiper) this.swiper.destroy();

      this.$nextTick(() => {
        this.swiper = new Swiper(".newgoods-recommend", {
          autoplay: {
            delay: 2000,
            disableOnInteraction: false, //解决滑动后不能轮播的问题
          }, //可选选项，自动滑动
          loop: true, //循环模式选项
          // observer: true, //检测变化
          // //如果需要分页器
          // pagination: {
          //   el: ".swiper-pagination",
          // },
          // //如果需要前进后退按钮
          // navigation: {
          //   nextEl: ".swiper-button-next",
          //   prevEl: ".swiper-button-prev",
          // },
        });
      });
    },
  },
  methods: {
    //初始化数据
    async initData() {
      let _this = this;

      // _this.common.param.showGoodsNav = true; //显示商品分类菜单
      // 猜你喜欢
      AxiosAjax({
        url: `/client/shop/goods/view/25?labelId=1`,
        success(res) {
          _this.recommendList = res.data;
        },
      });
    },
    clickStoreHandle(i) {
      this.merchantIndex = i;
      this.swiper.destroy();
    },
  },
  mounted() {
    const _this = this;
    // 加载首页banner
    AxiosAjax({
      url: `/client/advert/view/3`,
      success(res) {
        _this.advertList = res.data[0];
        _this.advertStoreList = res.data[1];
        //首页轮换
        _this.$nextTick(() => {
          new Swiper(".goods-index-container", {
            autoplay: {
              delay: 3000,
              disableOnInteraction: false, //解决滑动后不能轮播的问题
            }, //可选选项，自动滑动
            loop: true, //循环模式选项
            observer: true, //检测变化
            //如果需要分页器
            pagination: {
              el: ".swiper-pagination",
            },
            //如果需要前进后退按钮
            navigation: {
              nextEl: ".swiper-button-next",
              prevEl: ".swiper-button-prev",
            },
          });
        });
      },
    });

    // 加载推荐商铺
    AxiosAjax({
      url: `/client/merchant/article/type/view/10`,
      success(res) {
        _this.merchantList = res.data;
      },
    });
  },
  created() {
    this.initData();
  },
};
</script>

<style scoped lang="scss">
.swiper-button-prev {
  width: 30px;
  height: 40px;
  line-height: 20px;
  border-radius: 0px 50px 50px 0px;
  background-color: rgba(16, 16, 16, 0.3);
  left: 224px !important;
  padding-right: 10px;
  &:hover {
    background-color: rgba(16, 16, 16, 0.45);
  }

  &:active {
    background-color: rgba(16, 16, 16, 0.45);
  }
  &:after {
    font-size: 20px;
  }
}
.swiper-button-next {
  width: 30px;
  height: 40px;
  line-height: 20px;
  border-radius: 50px 0px 0px 50px;
  background-color: rgba(16, 16, 16, 0.3);
  right: 0 !important;
  padding-left: 10px;
  &:hover {
    background-color: rgba(16, 16, 16, 0.45);
  }

  &:active {
    background-color: rgba(16, 16, 16, 0.45);
  }
  &:after {
    font-size: 20px;
  }
}

::v-deep .slider-banner .goods-index-container .swiper-pagination-bullet {
  width: 50px;
  height: 6px;
  line-height: 20px;
  border-radius: 33px;
  background-color: rgba(16, 16, 16, 0.31);
}

::v-deep
  .slider-banner
  .goods-index-container
  .swiper-pagination-bullet-active {
  background-color: #ff7900;
}

.new-goods {
  width: 1200px;
  height: 640px;
  background-color: rgba(255, 255, 255, 1);
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.11);
  margin-top: 20px;
  padding-right: 24px;
  .title {
    display: flex;
    align-items: center;
    height: 60px;
    padding: 0 24px;
    h2 {
      color: rgba(255, 121, 0, 1);
      font-size: 18px;
      margin-right: 42px;
    }
    span {
      color: rgba(172, 172, 172, 1);
      font-size: 14px;
    }
    a {
      color: rgba(106, 106, 106, 1);
      font-size: 14px;
      margin-left: auto;
    }
  }

  .content {
    height: 540px;
    display: flex;
    justify-content: flex-start;
    .store-list {
      overflow: auto;
      min-width: 132px;
      .store-item {
        width: 132px;
        height: 45px;
        line-height: 45px;
        text-align: center;
        font-size: 14px;
        color: #6a6a6a;
        cursor: pointer;
        &:hover {
          color: #ff7900;
        }
        &.active {
          border-radius: 0px 50px 50px 0px;
          background-color: rgba(255, 121, 0, 1);
          font-weight: bold;
          color: #fff;
        }
      }
    }

    .merchant {
      flex: 1;
      margin-left: 12px;
      display: flex;
      .merchant-img {
        width: 540px;
        height: 540px;
      }
      .merchant-goods-list {
        display: flex;
        flex-wrap: wrap;
        gap: 18px;
        .goods {
          width: 222px;
          height: 261px;
          background-color: rgba(255, 255, 255, 1);
          border: 1px solid rgba(245, 244, 244, 1);
          &:hover {
            img {
              transform: scale(1.2);
            }
          }
          .goods-content {
            width: 222px;
            height: 222px;
            overflow: hidden;
            img {
              width: 222px;
              height: 222px;
              transition: all 500ms;
            }
          }

          .sell-price {
            height: 36px;
            line-height: 36px;
            padding-left: 20px;
            color: #ff7900;
            font-size: 14px;
            .num {
              font-size: 18px;
            }
          }
        }
      }
    }
  }
}

.recommend {
  width: 1200px;
  line-height: 20px;
  background-color: rgba(255, 255, 255, 1);
  text-align: center;
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.11);
  margin-top: 20px;
  padding: 0 24px 24px;
  .title {
    display: flex;
    align-items: center;
    height: 60px;

    h2 {
      color: rgba(255, 121, 0, 1);
      font-size: 18px;
      margin-right: 42px;
    }
    span {
      color: rgba(172, 172, 172, 1);
      font-size: 14px;
    }
    a {
      color: rgba(106, 106, 106, 1);
      font-size: 14px;
      margin-left: auto;
    }
  }
}

.goods-list {
  margin: 0;
  justify-content: space-between;

  .list-box {
    margin: 0;
    width: 222px;
    margin-top: 10px;
    border: 1px solid rgba(245, 244, 244, 1);
    overflow: hidden;
    padding: 0;
  }
}

.advlist-store {
  width: 1200px;
  line-height: 20px;
  background-color: rgb(255, 255, 255);
  text-align: center;
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.11);
  margin-top: 20px;
  padding: 0 24px 24px;

  .title {
    display: flex;
    align-items: center;
    height: 60px;
    h2 {
      color: rgb(255, 121, 0);
      font-size: 18px;
      margin-right: 42px;
    }
    span {
      color: rgb(172, 172, 172);
      font-size: 14px;
    }
    a {
      color: rgb(106, 106, 106);
      font-size: 14px;
      margin-left: auto;
    }
  }
  ul {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 14px;
    li {
      flex: 19% 0 0;
      img {
        width: 100%;
      }
    }
  }
}

.newgoods-recommend {
  width: 491px;
  margin-left: 25px;
}


.mt-10 {
  margin-top: 10px;
}
.color-primary {
  color: #ff7900;
}
</style>
