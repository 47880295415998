<template>
  <div class="category-wrap">
    <span class="title">{{ title }}</span>
    <div class="category-box" :class="{ active: active }" :style="{
      height:$route.fullPath =='/home'?'420px':'unset'
    }">
      <div class="sort-box">
        <div v-for="(sitem, sindex) in categoryData" :key="sindex" class="list-box">
          <router-link tag="div" :to="link + sitem.id" class="title">{{ sitem.title }}</router-link>
          <nav :class="{'sub-category':true,'no-home':$route.fullPath !='/home'}" :style="{
            whiteSpace:$route.fullPath =='/home'?'nowrap':'unset'
          }">
            <router-link v-for="(item, index) in sitem.children" :key="index" class="sub-category-item" :to="link + sitem.id + '?keyword=' +item.title ">{{
              item.title }}</router-link>
          </nav>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AxiosAjax from '@/utils/axios.config';

export default {
  props: {
    title: {
      type: String,
      default: '商品分类',
    },
    active: {
      type: Boolean,
      default: false,
    },
    link: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      categoryData: [], //类别树数据
    };
  },
  methods: {
    //初始化数据
    initData() {
      let _this = this;
      //类别树列表
      AxiosAjax({
        url: `/client/shop/category`,
        success: function (res) {
          _this.categoryData = res.data;
        },
      });
    },
  },
  created() {
    this.initData();
  },
};
</script>
