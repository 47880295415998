<template>
  <div class="wrapper">
    <div class="account-box">
      <AccountNav :account="account" model="infoList" />

      <div class="right-box">
        <div class="tab-box">
          <div class="menu-box menu-box-header">
            <div class="item-box active">
              <span>我的信息</span>
            </div>
          </div>
        </div>
        <div class="content">
          <div v-if="infoList.length === 0" class="nodata">
            <span class="icon iconfont icon-msg"></span>
            <span class="text">暂无记录</span>
          </div>
          <div class="order-list">
            <PublicInfoItem
              class="info-item"
              v-for="(item, i) in infoList"
              :key="i"
              :info="item"
              :self="true"
              @removeInfo="removeInfo(item)"
            />
          </div>

          <div class="pager-box">
            <el-pagination
              background
              :hide-on-single-page="true"
              @current-change="handleCurrentChange"
              :page-size="pageSize"
              layout="prev, pager, next"
              :total="totalCount"
            >
            </el-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AxiosAjax from "@/utils/axios.config";
import AccountNav from "@/components/AccountNav.vue";
import PublicInfoItem from "@/components/PublicInfoItem";

export default {
  name: "AATAccountInfoList",
  components: {
    AccountNav,
    PublicInfoItem,
  },
  data() {
    return {
      totalCount: 0, //总数量
      pageSize: 10, //每页显示条数
      pageIndex: 1, //当前页码
      infoList: [], //数据列表
      account: null, //会员信息,
    };
  },

  methods: {
    //加载列表
    async loadData() {
      let _this = this;
      AxiosAjax({
        url: `/client/mymessage?pageSize=${_this.pageSize}&pageIndex=${_this.pageIndex}`,
        loading: true,
        success(res) {
          let pageInfo = JSON.parse(res.headers["x-pagination"]);
          _this.totalCount = pageInfo.totalCount;
          _this.totalPages = pageInfo.totalPages;
          _this.pageSize = pageInfo.pageSize;
          _this.pageIndex = pageInfo.pageIndex;

          const list = res.data.map((item) => {
            if (!item.editPhoto) {
              item.editPhoto = [];
            } else {
              item.editPhoto = item.editPhoto.split(",");
            }
            if (!item.editPhotoSmall) {
              item.editPhotoSmall = [];
            } else {
              item.editPhotoSmall = item.editPhotoSmall.split(",");
            }
            return item;
          });
          _this.infoList.push(...list);
        },
        error() {
          _this.infoList = [];
        },
      });
    },
    removeInfo(info) {
      const _this = this;
      AxiosAjax({
        url: `/client/merchant/message/${info.id}`,
        method: "delete",
        loading: true,
        success() {
          _this.$message.success("删除成功");
          _this.infoList = [];
          _this.pageIndex = 1
          _this.loadData();
        },
        error() {
          _this.$message.error("删除失败");
        },
      });
    },
    //初始化数据
    async initData() {
      let _this = this;
      //加载列表
      await _this.loadData();
      //加载会员信息
      await AxiosAjax({
        url: `/account/member`,
        success(res) {
          _this.account = res.data;
        },
      });
    },
    //跳转到第几页
    handleCurrentChange: function (val) {
      if (this.pageIndex != val) {
        this.pageIndex = val;
        this.loadData();
      }
    },
  },
  created() {
    this.initData();
  },
};
</script>

<style lang="scss" scoped>
.menu-box-header {
  width: 100%;
  display: flex;
  justify-content: space-between !important;
  align-items: center;
}
.menu-box-header-right {
  display: flex;
  gap: 10px;
}
.info-item:not(:first-child) {
  margin-top: 10px;
}
</style>
