<template>
  <div class="wrapper">
    <div class="account-box">
      <AccountNav :account="account" model="settled" />

      <div class="right-box">
        <div class="tab-box">
          <div class="menu-box">
            <div class="item-box active">
              <span>申请入驻</span>
            </div>
          </div>
        </div>
        <div class="content">
          <div v-if="model.isLock == 1" class="icon-box">
            <span class="icon iconfont icon-time"></span>
            <span class="text">资料审核中，请等待结果！</span>
            <img src="@/assets/images/qrcode.jpg" alt="" class="qrcode" />
            <span class="text">如需联系，请添加客服</span>
          </div>
          <div v-if="model.isLock == 2" class="icon-box">
            <span class="icon iconfont icon-close"></span>
            <span class="text">审核不通过，请修改后重新申请！</span>
          </div>
          <div v-if="model.isLock == 0" class="icon-box">
            <span class="icon iconfont icon-check"></span>
            <span class="text">已成为商户，请进入商户平台操作。</span>
            <a
              href="http://merchant.aiaitu.net/"
              target="_blank"
              class="merchant-link"
              >进入商户后台</a
            >
            <img src="@/assets/images/qrcode.jpg" alt="" class="qrcode" />
          </div>
          <el-form
            v-if="!model.id || model.isLock == 2"
            ref="editForm"
            :model="model"
            :rules="rules"
            label-position="right"
            label-width="120px"
          >
            <el-form-item prop="categoryId" label="商户分类">
              <el-select v-model="model.categoryId" placeholder="请选择...">
                <el-option
                  v-for="item in categoryList"
                  :key="item.id"
                  :label="item.title"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item prop="storeId" label="店铺类型">
              <el-select v-model="model.storeId" placeholder="请选择...">
                <el-option
                  v-for="item in storeList"
                  :key="item.id"
                  :label="item.title"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item prop="mainProduct" label="主打产品">
              <!-- <el-input
                v-model="model.mainProduct"
                placeholder="必填，请填写主打产品，多个产品用英文逗号：‘,’分割"
              ></el-input> -->
              <el-select
                v-model="mainProduct"
                multiple
                placeholder="必填，请填写主打产品，至少选择一项，便于优先推荐"
                :style="{
                  width: '400px',
                }"
              >
                <el-option
                  v-for="item in mainProductOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="是否自主代发">
              <el-radio-group v-model="model.MShopType">
                <el-radio-button label="淘宝">淘宝</el-radio-button>
                <el-radio-button label="拼多多">拼多多</el-radio-button>
                <el-radio-button label="抖音">抖音</el-radio-button>
                <el-radio-button label="小红书">小红书</el-radio-button>
              </el-radio-group>
            </el-form-item>
            <el-form-item prop="title" label="店铺名称">
              <el-input
                v-model="model.title"
                placeholder="必填，请填写店铺名称"
              ></el-input>
            </el-form-item>
            <el-form-item prop="address" label="店铺地址">
              <el-input
                v-model="model.address"
                placeholder="必填，请填写店铺详细地址"
              ></el-input>
            </el-form-item>
            <el-form-item prop="avatar" label="店铺LOGO">
              <dt-upload-text v-model="model.avatar"></dt-upload-text>
            </el-form-item>
            <el-form-item prop="certUrl" label="营业执照">
              <dt-upload-text v-model="model.certUrl"></dt-upload-text>
            </el-form-item>
            <el-form-item prop="realName" label="店主姓名">
              <el-input
                v-model="model.realName"
                placeholder="必填，请填写联系人姓名"
              ></el-input>
            </el-form-item>
            <el-form-item prop="mobile" label="联系电话">
              <el-input
                v-model="model.mobile"
                placeholder="必填，请填写手机号码"
              ></el-input>
            </el-form-item>
            <el-form-item prop="qq" label="qq">
              <el-input
                v-model="model.qq"
                placeholder="请填写qq号码"
              ></el-input>
            </el-form-item>
            <el-form-item prop="weixin" label="微信">
              <el-input
                v-model="model.weixin"
                placeholder="请填写微信号码"
              ></el-input>
            </el-form-item>
            <!-- <el-form-item prop="isSell" label="是否代发">
              <el-switch v-model="isSell"></el-switch>
            </el-form-item> -->
            <el-form-item label="店铺简介">
              <el-input
                type="textarea"
                :row="3"
                v-model="model.zhaiyao"
                show-word-limit
                maxlength="512"
              ></el-input>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="submitForm">提交申请</el-button>
              <el-button @click="common.back(-1)">返回上一页</el-button>
            </el-form-item>
          </el-form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AxiosAjax from "@/utils/axios.config";
import AccountNav from "@/components/AccountNav.vue";
export default {
  name: "AATShopStoreSettled",
  components: {
    AccountNav,
  },
  data() {
    return {
      account: {}, //账户信息
      categoryList: null, //商户分类
      storeList: null, //店铺类型
      model: {
        categoryId: null,
        storeId: null,
        title: null,
        address: null,
        mobile: null,
        avatar: null,
        realName: null,
        certUrl: null,
        intro: null,
        mainProduct: null,
        qq: null,
        weixin: null,
        isSell: 1,
        MShopType: null,
      },
      rules: {
        categoryId: [
          { required: true, message: "请选择商户类别", trigger: "change" },
        ],
        storeId: [
          { required: true, message: "请选择店铺类型", trigger: "change" },
        ],
        mainProduct: [
          { required: true, message: "请输入店铺主打产品", trigger: "blur" },
        ],
        title: [{ required: true, message: "请填写店铺名称", trigger: "blur" }],
        address: [
          { required: true, message: "请填写店铺地址", trigger: "blur" },
        ],
        mobile: [
          { required: true, message: "请填写联系人电话", trigger: "blur" },
          {
            pattern: /^1[0-9]{10}$/,
            message: "手机号码格式不正确",
            trigger: "blur",
          },
        ],

        realName: [
          { required: true, message: "请填写联系人名称", trigger: "blur" },
        ],
      },
      mainProductOptions: [],
    };
  },
  computed: {
    isSell: {
      get() {
        return this.model.isSell == 1;
      },
      set(val) {
        val
          ? this.$set(this.model, "isSell", 1)
          : this.$set(this.model, "isSell", 0);
      },
    },
    mainProduct: {
      get() {
        return this.model.mainProduct ? this.model.mainProduct.split(",") : [];
      },
      set(val) {
        if (val && val.length > 0) {
          this.$set(this.model, "mainProduct", val.toString());
        } else {
          this.$set(this.model, "mainProduct", "");
        }
      },
    },
  },
  methods: {
    //初始化数据
    async initData() {
      let _this = this;
      //获取商户分类
      await AxiosAjax({
        url: `/client/merchant/category/view/0`,
        success(res) {
          _this.categoryList = res.data;
          _this.model.storeId = 5;
        },
      });
      //获取店铺类型
      await AxiosAjax({
        url: `/client/merchant/store/view/0`,
        success(res) {
          _this.storeList = res.data;
        },
      });

      //获取会员信息
      await AxiosAjax({
        url: `/account/member`,
        success(res) {
          _this.account = res.data;
        },
      });

      //获取主打产品
      await AxiosAjax({
        url: `/client/shop/category`,
        success(res) {
          _this.mainProductOptions = res.data.map((item) => ({
            ...item,
            value: item.title,
            label: item.title,
          }));
        },
      });

      // 获取店铺信息
      await AxiosAjax({
        url: `/account/merchant`,
        success(res) {
          _this.model = res.data;
        },
      });
    },
    //提交表单
    submitForm: function () {
      let _this = this; //当前页面
      //多个表单验证
      const f1 = new Promise((resolve) => {
        _this.$refs["editForm"].validate((valid) => {
          if (valid) resolve();
        });
      });
      Promise.all([f1]).then(() => {
        if (_this.model.id > 0) {
          AxiosAjax({
            method: "put",
            url: "/account/merchant",
            data: _this.model,
            loading: true,
            successMsg: "修改成功",
            success() {
              _this.initData();
            },
          });
        } else {
          AxiosAjax({
            method: "post",
            url: "/account/merchant",
            data: _this.model,
            loading: true,
            successMsg: "申请成功",
            success() {
              _this.initData();
            },
          });
        }
      });
    },
  },
  created() {
    this.initData();
  },
};
</script>

<style lang="scss" scoped>
.qrcode {
  width: 500px;
}
.merchant-link {
  margin: 20px 0 10px;
  color: rgb(3 146 227);
}
</style>
