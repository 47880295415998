<template>
  <div>
    <!--导航菜单.开始-->
    <div class="wrapper">
      <div class="location">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item>首页</el-breadcrumb-item>
          <el-breadcrumb-item>在线支付</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
    </div>
    <!--导航菜单.结束-->

    <div class="wrapper">
      <div class="payment-box">
        <div class="head-box">
          <div class="title">
            <span class="icon iconfont icon-check"></span>
            <span>订单提交成功，去付款吧！</span>
          </div>
          <div class="item-box">
            <div class="item">
              <div class="left">交易编号</div>
              <div class="right">{{ model.tradeNo }}</div>
            </div>
            <div class="item">
              <div class="left">支付金额</div>
              <div class="right">
                <span class="price">{{ model.paymentAmount }}</span
                >元
              </div>
            </div>
          </div>
        </div>
        <div class="screen-box">
          <div class="nav-bar">支付方式</div>
          <div class="payment-list">
            <div
              v-for="(item, index) in paymentList"
              :key="index"
              @click="changePayment(item)"
              :class="{ active: paymentModel.id === item.id }"
              class="list-box"
            >
              <span
                v-if="item.providerType === 1"
                class="icon iconfont icon-balance"
              ></span>
              <span
                v-if="item.providerType === 2"
                class="icon iconfont icon-weixin"
              ></span>
              <span
                v-if="item.providerType === 3"
                class="icon iconfont icon-alipay"
              ></span>
              <div class="txt-box">
                <span class="name">{{ item.title }}</span>
                <span v-if="item.remark" class="text">{{ item.remark }}</span>
              </div>
            </div>
          </div>
          <div class="btn-box">
            <el-button type="danger" class="btn" @click="confirm"
              >提交订单</el-button
            >
          </div>
        </div>
      </div>
    </div>

    <el-dialog title="扫码支付" :visible.sync="codeVisible">
      <div class="native-box">
        <div class="img-box">
          <img :src="'data:text/css;base64,' + codeData" />
        </div>
        <span class="text">打开微信扫一扫完成支付</span>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import AxiosAjax from "@/utils/axios.config";

export default {
  name: "AATShopConfirm",

  data() {
    return {
      codeVisible: false, //二维码显示隐藏
      codeData: null, //BASE64图片
      paymentList: [], //支付方式列表
      paymentModel: {}, //当前支付方式
      model: {},
    };
  },

  methods: {
    //加载订单信息
    async loadData(no) {
      let _this = this;
      if (!no) {
        this.$alert("订单参数有误，请检查重试", "错误提示", {
          confirmButtonText: "确定",
          callback: () => {
            _this.common.back(-1);
          },
        });
        return;
      }
      await AxiosAjax({
        url: `/account/order/collection/${no}`,
        success(res) {
          _this.model = res.data;
          //console.log(_this.model)
        },
      });
      //判断是否已支付,如果是已支付则直接跳转到成功页面
      if (_this.model.status > 1) {
        _this.common.linkUrl(`/payment/result?no=${_this.model.tradeNo}`);
      }
    },
    //初始化数据
    async initData(no) {
      let _this = this;
      //加载订单信息
      await _this.loadData(no);
      //加载支付方式列表
      let sendUrl = `/client/payment/view/10`;
      if (_this.model.tradeType === 1) {
        sendUrl += `?types=pc,native`;
      } else {
        sendUrl += `?types=balance,pc,native`;
      }
      await AxiosAjax({
        url: sendUrl,
        success(res) {
          _this.paymentList = res.data;
        },
      });
      //选中订单支付方式
      var obj = _this.paymentList.find(
        (val) => val.id === _this.model.paymentId
      );
      if (obj) {
        _this.paymentModel = obj;
      }
    },
    //选择支付方式
    async changePayment(item) {
      let _this = this;
      if (_this.model.paymentId === item.id) {
        return;
      }
      //修改订单支付方式
      await AxiosAjax({
        method: "put",
        url: `/account/order/collection/payment`,
        loading: true,
        data: {
          id: _this.model.id,
          paymentId: item.id,
        },
        success() {
          _this.model.paymentId = item.id;
          _this.paymentModel = item;
        },
      });
    },
    //确认支付
    async confirm() {
      let _this = this;
      if (!_this.paymentModel.payType) {
        _this.$message({
          message: "请选择付款方式",
          type: "warning",
        });
        return;
      }
      //判断支付方式选择不同的接口
      if (_this.paymentModel.payType == "balance") {
        await _this.balancePay();
      }
      if (_this.paymentModel.payType == "pc") {
        await _this.pcPay();
      }
      if (_this.paymentModel.payType == "native") {
        await _this.nativePay();
      }
    },
    //余额支付
    async balancePay() {
      let _this = this;
      let pay = _this.paymentModel;
      let item = _this.model;
      //调用下单接口
      AxiosAjax({
        method: "post",
        url: `${pay.payUrl}`,
        loading: true,
        data: {
          outTradeNo: item.tradeNo,
        },
        success: function () {
          _this.common.linkUrl(`/payment/result?no=${item.tradeNo}`);
        },
      });
    },
    //电脑支付
    async pcPay() {
      let _this = this;
      let pay = _this.paymentModel;
      let item = _this.model;
      //获取当前页面
      let href = window.location.protocol + "//" + window.location.host;
      let uri = encodeURIComponent(href + `/payment/result?no=${item.tradeNo}`);
      //调用统一下单接口
      AxiosAjax({
        method: "post",
        url: `${pay.payUrl}`,
        loading: true,
        data: {
          outTradeNo: item.tradeNo,
          description: "商品订单",
          returnUri: uri,
        },
        success(res) {
          window.open(res.data.url, "_blank");
        },
      });
    },
    //扫码支付
    async nativePay() {
      let _this = this;
      let pay = _this.paymentModel;
      let item = _this.model;
      //调用统一下单接口
      AxiosAjax({
        method: "post",
        url: `${pay.payUrl}`,
        loading: true,
        data: {
          outTradeNo: item.tradeNo,
          description: "商品订单",
        },
        success(res) {
          _this.codeVisible = true;
          _this.codeData = res.data.codeData;
          //5秒后调用监听事件
          setTimeout(function () {
            _this.handleNative(item.tradeNo);
          }, 5000);
        },
      });
    },
    //查询订单是否支付
    async handleNative(no) {
      let _this = this;
      let status = false;
      await AxiosAjax({
        url: `/account/order/collection/${no}`,
        success(res) {
          if (res.data.status) {
            status = true;
          }
        },
      });
      //判断是否已支付,如果是已支付则直接跳转到成功页面
      if (status) {
        _this.common.linkUrl(`/payment/result?no=${no}`);
      } else if (_this.codeVisible) {
        //5秒后调用监听事件
        setTimeout(function () {
          _this.handleNative(no);
        }, 5000);
      }
    },
  },
  created() {
    let tradeNo = this.common.queryValue("no");
    this.initData(tradeNo);
  },
};
</script>

<style lang="scss" scoped></style>
