<template>
    <div class="wrapper">
      <div class="location">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item>首页</el-breadcrumb-item>
          <el-breadcrumb-item>共享直播间</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
  
      <!--查询条件.开始-->
      <div class="goods-order">
        <div
          :class="{ 'order-item': true, active: curOrderItem == i }"
          v-for="(item, i) in order"
          :key="i"
          @click="clickOrderItemHandle(item, i)"
        >
          {{ item.name }}
          <i class="el-icon-caret-bottom" v-if="item.status"></i>
          <i class="el-icon-caret-top" v-else></i>
        </div>
      </div>
      <!--查询条件.结束-->
  
      <div class="goods-list">
        <router-link
          class="list-box"
          :to="`/shop/goods/show/${item.id}`"
          v-for="(item, i) in goodsList"
          :key="i"
        >
          <Goods
            :imgUrl="item.imgUrl"
            :title="item.title"
            :sellPrice="item.sellPrice"
            publicTime="2023-11-11"
            :icons="[1]"
            storeName="abc"
          />
        </router-link>
      </div>
      <div v-if="!goodsList.length" class="comingsoon">敬请期待</div>
    </div>
  </template>
  
  <script>
  import AxiosAjax from "@/utils/axios.config";
  import Goods from "@/components/RecommendGoods.vue";
  export default {
    name: "AATShopRentOut",
    components: {
      Goods,
    },
    data() {
      return {
        curOrderItem: 0,
        order: [
          {
            name: "上新",
            status: 0,
          },
          {
            name: "热度",
            status: 0,
          },
          {
            name: "价格",
            status: 0,
          },
        ],
        goodsList: [],
      };
    },
    methods: {
      async initData() {
        let _this = this;
  
        // 猜你喜欢
        AxiosAjax({
          url: `/client/shop/goods/view/25?labelId=1`,
          success(res) {
            _this.goodsList = res.data;
          },
        });
      },
      clickOrderItemHandle(item, i) {
        this.curOrderItem = i;
        this.$set(item, "status", item.status == 0 ? 1 : 0);
      },
    },
    created() {
    //   this.initData();
    },
  };
  </script>
  
  <style lang="scss" scoped>
  .goods-order {
    background-color: #fff;
    height: 70px;
    margin-top: 25px;
    display: flex;
    .order-item {
      width: 146px;
      height: 70px;
      line-height: 70px;
      color: #6a6a6a;
      font-size: 14px;
      text-align: center;
      cursor: pointer;
      &.active,
      &:hover {
        background-color: #ff7900;
        color: #fff;
      }
    }
  }
  
  .goods-list {
    margin: 0;
    justify-content: space-between;
  
    .list-box {
      margin: 0;
      width: 222px;
      margin-top: 10px;
      border: 1px solid rgba(245, 244, 244, 1);
      overflow: hidden;
      padding: 0;
    }
  }

  .comingsoon{
    text-align: center;
    margin-top: 20px;
    font-size: 30px;
    font-weight: bolder;
}
  </style>
  