<template>
  <div class="wrapper">
    <div class="mt-4 mb-5 clear-both">
      <div class="article-list">
        <el-tree
          :data="articles"
          :props="defaultProps"
          highlight-current
          default-expand-all
          @current-change="nodeChange"
          node-key="id"
          ref="tree"
        >
          <template v-slot="scope">
            <span :data-article="scope.data.parent && scope.data.id">{{
              scope.data.title
            }}</span>
          </template>
        </el-tree>
      </div>
      <div class="content" v-html="article"></div>
    </div>
  </div>
</template>

<script>
import AxiosAjax from "@/utils/axios.config";

export default {
  name: "AATShopArticle",

  data() {
    return {
      articles: [],
      defaultProps: {
        children: "articleCategoryRelations",
        label: "title",
      },
      article: "",
    };
  },

  methods: {
    setCheckedKeys(id) {
      const el = document.querySelector(`[data-article='${id}']`);
      el && el.click();
    },
    //加载列表
    async loadData() {
      let _this = this;
      AxiosAjax({
        url: `/client/article/category`,
        loading: true,
        success: function (res) {
          _this.articles = _this.formatArticles(res.data);
          _this.$nextTick(() => {
            const articleId = _this.$route.query.article;
            articleId && _this.setCheckedKeys(articleId);
          });
        },
        error: function () {
          _this.listData = [];
        },
      });
    },
    //初始化数据
    async initData() {
      let _this = this;
      //加载列表
      await _this.loadData();
    },
    formatArticles(articles) {
      return articles.map((item) => {
        item.articleCategoryRelations = item.articleCategoryRelations.map(
          (article) => {
            return {
              ...article.article,
              parent: {
                ...article,
              },
            };
          }
        );
        return item;
      });
    },
    nodeChange(node, nodeObj) {
      if (!nodeObj.isLeaf) return;
      const _this = this;
      AxiosAjax({
        url: `/client/article/${node.id}`,
        loading: true,
        success: function (res) {
          _this.article = res.data.content;
        },
        error: function () {
          _this.article = "";
        },
      });
    },
  },
  mounted() {
    this.initData();
  },
  beforeRouteUpdate(_, __, next) {
    next();
    this.initData();
  },
};
</script>

<style lang="scss" scoped>
.mt-4 {
  margin-top: 16px;
}
.mb-5 {
  margin-bottom: 20px;
}
.article-list {
  float: left;
  width: 214px;
  background: #fff;
  padding-top: 10px;
}

.content {
  float: right;
  width: 966px;
}
.clear-both::after {
  content: "";
  display: block;
  clear: both;
}

::v-deep .el-tree > .el-tree-node > .el-tree-node__content {
  font-weight: bolder !important;
}
::v-deep .el-tree-node__label {
  color: #757578;
}
</style>
