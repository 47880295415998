import Vue from 'vue';
import App from './App.vue';
import router from './router';
import ElementUI from 'element-ui';
import DtTimer from '@/components/DtTimer.vue';
import PageCartCount from '@/components/PageCartCount.vue';
import PageCategoryNav from '@/components/PageCategoryNav.vue';
import 'element-ui/lib/theme-chalk/index.css';
import 'swiper/swiper-bundle.min.css';
import './css/iconfont/iconfont.css';
import './css/style.css';
import '@/utils/upload.component.js';
import '@/utils/vue.common.js';
import store from "@/store"
Vue.config.productionTip = false;

Vue.component('dt-timer', DtTimer);
Vue.component('page-cart-count', PageCartCount);
Vue.component('page-category-nav', PageCategoryNav);

Vue.use(ElementUI);

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
