<template>
  <div>
    <div class="img-box">
      <el-image :src="common.loadFile(imgUrl)"></el-image>
    </div>
    <div class="title">
      <span>{{ title }}</span>
    </div>
    <div class="goods-name" v-if="goodsName">
      <router-link to="#">
        {{ goodsName }}
      </router-link>
    </div>
    <div class="price">
      <span>¥</span>{{ sellPrice }}
      <span class="public-time">{{ publicTime }}</span>
    </div>
    <div class="tag">
      <div class="icons">
        <span class="medalCom dai" v-for="(icon, i) in icons" :key="i">{{
          icon.name
        }}</span>
        <!-- <span class="medalCom jing">精</span>
                <span class="medalCom chang">厂</span>
                <span class="medalCom ban">版</span>
                <span class="medalComs jinpai">金牌</span>
                <span class="recommend">推广</span> -->
      </div>
      <router-link to="#" class="store-name" v-if="storeName">
        <div>{{ storeName }}</div>
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: "AATShopGoods",
  props: {
    imgUrl: String,
    title: String,
    goodsName: String,
    sellPrice: Number,
    publicTime: String,
    icons: Array,
    storeName: String,
  },
  data() {
    return {};
  },

  mounted() {},

  methods: {},
};
</script>

<style lang="scss" scoped></style>
