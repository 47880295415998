<template>
  <div>
    <!--页面内容.开始-->
    <div class="wrapper">
      <div class="location">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item>首页</el-breadcrumb-item>
          <el-breadcrumb-item>购物商城</el-breadcrumb-item>
          <el-breadcrumb-item>商品详情</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
    </div>

    <div class="goods-info-box wrapper">
      <div class="goods-box">
        <div class="ginfo-box">
          <!--幻灯片.开始-->
          <div class="left-box">
            <GoodsShowGallery v-if="goods" :goods="goods" />
          </div>
          <!--幻灯片.结束-->
          <!--商品信息.开始-->
          <div class="right-box">
            <div class="gtitle">{{ goods && goods.title }}</div>
            <div class="stitle">{{ goods && goods.subTitle }}</div>
            <div class="info-box">
              <template v-if="speedProduct">
                <div class="price-box">
                  <span class="del"
                    >原价：￥{{ goods && goods.marketPrice }}</span
                  >
                  <span class="price">{{ speedProduct.price }}</span>
                </div>
                <div class="timer-box">
                  <span class="title">限时抢购</span>
                  <span
                    >距离结束还剩：<dt-timer
                      :end="speedProduct.endTime"
                    ></dt-timer
                  ></span>
                </div>
              </template>
              <template v-else-if="presellProduct">
                <div class="price-box">
                  <span class="del"
                    >原价：￥{{ goods && goods.marketPrice }}</span
                  >
                  <span class="price">{{ presellProduct.totalPrice }}</span>
                </div>
                <div class="timer-box">
                  <span class="title">商品预售</span>
                  <span
                    >距离结束还剩：<dt-timer
                      :end="presellProduct.actEndTime"
                    ></dt-timer
                  ></span>
                </div>
              </template>
              <template v-else>
                <div class="price-box">
                  <span class="del"
                    >原价：￥{{
                      skuProduct
                        ? skuProduct.marketPrice
                        : goods && goods.marketPrice
                    }}</span
                  >
                  <span class="price">{{
                    skuProduct ? skuProduct.sellPrice : goods && goods.sellPrice
                  }}</span>
                </div>
              </template>
              <div class="sale-box">
                <span class="num">{{ goods && goods.saleCount }}</span>
                <span class="text">累计销量</span>
              </div>
            </div>
            <div class="promote-box">
              <template v-if="presellProduct">
                <div class="list-box">
                  <span class="title">预售流程</span>
                  <div class="remark">
                    <span
                      >1.支付定金 2.支付尾款 3.活动结束{{
                        presellProduct.deliveryDay
                      }}天发货</span
                    >
                  </div>
                </div>
                <div class="list-box">
                  <span class="title">尾款时段</span>
                  <div class="remark">
                    <span
                      >{{ presellProduct.payStartTime }} -
                      {{ presellProduct.payEndTime }}</span
                    >
                  </div>
                </div>
              </template>
              <div v-if="couponList.length > 0" class="list-box">
                <span class="title">优惠券</span>
                <div class="coupon">
                  <div
                    v-for="(item, index) in couponList"
                    :key="index"
                    class="item-warp"
                  >
                    <div class="item-box">
                      <span class="num">￥{{ item.amount }}</span>
                      <span class="text">{{ item.title }}</span>
                    </div>
                    <span class="info">有效期至 {{ item.endTime }}</span>
                    <span class="btn" @click="addCoupon(item.id)">领取</span>
                  </div>
                </div>
              </div>
              <div v-if="promotionList.length > 0" class="list-box">
                <span class="title">促销活动</span>
                <div class="remark">
                  <span v-for="(item, index) in promotionList" :key="index">{{
                    item.title
                  }}</span>
                </div>
              </div>
            </div>
            <div class="spec-list">
              <div class="prop-name">选择颜色:</div>
              <div class="goods-type">
                <div
                  :class="[
                    'goods-type-item',
                    goodsTypeClassName(item.id),
                    {
                      'goods-type-item-selected': activeGoodsType.id == item.id,
                    },
                  ]"
                  v-for="(item, index) in skuList"
                  :key="index"
                  @click="clickGoodsTypeItem(item)"
                >
                  <img :src="common.loadFile(item.imgUrl)" :alt="item.title" />
                  <i class="selected-icon"></i>
                  <span class="goods-title">{{ item.title }}</span>
                </div>
              </div>
              <div class="goods-size">
                <div class="goods-size-label">选择尺寸:</div>
                <div class="goods-content">
                  <table>
                    <tbody v-if="goodsSize.length > 0">
                      <tr
                        v-for="(item, index) in goodsSize"
                        :key="index"
                        :class="
                          selectedGoods[findParentHandle(item).id].children[
                            item.id
                          ].stockQuantity == 0
                            ? ' gray-tr'
                            : ''
                        "
                      >
                        <td class="sizeT">{{ item.skuText.split(",")[1] }}</td>
                        <td class="weightT">{{ item.weight }}g</td>
                        <td class="priceT">{{ item.sellPrice }}元</td>
                        <td class="numT">
                          <div class="fortifyBox">
                            <i
                              class="reduction borClo defReduc"
                              @click="decreaseGoods(item)"
                            ></i>
                            <input
                              type="text"
                              maxlength="10"
                              class="iptText"
                              @input="changeGoodsCount($event, item)"
                              :value="
                                selectedGoods[findParentHandle(item).id]
                                  .children[item.id].count
                              "
                            />
                            <i class="add" @click="addGoods(item)"></i>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div class="meg-lits">
                <ul>
                  <li class="list-unit">
                    <span id="sldCotNum">{{ selectedTotalCount }}</span
                    >件
                  </li>
                  <li class="list-prics" id="sldCotPrice">
                    ￥{{ selectedGoodsAmount }}
                  </li>
                  <li class="list-checklist">
                    <div
                      class="nelenYx"
                      chekstate="0"
                      @click="toggleSelectdDetails"
                    >
                      已选清单<code class="menuBtnJust"></code>
                    </div>
                    <div
                      :class="{
                        'list-after': true,
                        'no-active': !showSelectdDetails,
                      }"
                      id="speclist_selected"
                    >
                      <table>
                        <tbody>
                          <template v-for="(parent, key) in selectedGoods">
                            <tr
                              class="selectcoloritem"
                              :key="key"
                              v-if="calculateTotal(parent) > 0"
                            >
                              <td class="propSt">
                                {{ parent.title }}
                              </td>
                              <td class="amount">
                                {{ calculateTotal(parent) }}件
                              </td>
                              <td class="desc">
                                <template
                                  v-for="(item, index) in parent.children"
                                >
                                  <div
                                    class="selkList"
                                    v-if="item.count > 0"
                                    :key="index"
                                  >
                                    <span>{{
                                      item.skuText.split(",")[1]
                                    }}</span>
                                    <span
                                      class="ultim"
                                      @mouseenter="
                                        toggleCountInput($event, true)
                                      "
                                    >
                                      （{{ item.count }}）
                                    </span>
                                    <p
                                      class="fortifyBox cngInt"
                                      :style="{ display: 'none' }"
                                      @mouseleave="
                                        toggleCountInput($event, false)
                                      "
                                    >
                                      <i
                                        class="reduction borClo defReduc"
                                        @click="decreaseGoods(item)"
                                      ></i>
                                      <input
                                        type="text"
                                        maxlength="10"
                                        class="iptText"
                                        @input="changeGoodsCount($event, item)"
                                        :value="
                                          selectedGoods[
                                            findParentHandle(item).id
                                          ].children[item.id].count
                                        "
                                      />
                                      <i
                                        class="add"
                                        @click="addGoods(item)"
                                      ></i>
                                    </p>
                                  </div>
                                </template>
                              </td>
                            </tr>
                          </template>
                        </tbody>
                      </table>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div class="btn-box">
              <el-button
                v-if="!speedProduct && !presellProduct"
                @click="addCart"
                >加入购物车</el-button
              >
              <el-button
                v-if="!speedProduct && !presellProduct"
                type="danger"
                @click="addOrder"
                >立即购买</el-button
              >
              <el-button v-if="!!speedProduct" type="danger" @click="addOrder"
                >立即抢购</el-button
              >
              <el-button v-if="!!presellProduct" type="danger" @click="addOrder"
                >支付定金：{{ presellProduct.frontAmount }}元</el-button
              >
            </div>
          </div>
          <!--商品信息.结束-->
        </div>
        <!--商品介绍.开始-->
        <div class="gdetail-box">
          <div class="head-box">
            <div
              class="item-box"
              @click="tabIndex = 0"
              :class="{ active: tabIndex === 0 }"
            >
              <span>商品详情</span>
            </div>
          </div>
          <div v-if="tabIndex === 0" class="content">
            <div class="param" v-if="goods">
              <span
                class="text"
                v-for="(item, index) in goods.fieldValues"
                :key="index"
                >{{ `${item.optionName}：${item.optionValue}` }}</span
              >
            </div>
            <div
              class="detail"
              v-if="goods"
              v-html="ReplaceImagesPath(goods.content)"
            ></div>
          </div>
        </div>
        <!--商品介绍.结束-->
      </div>
      <div class="store-box">
        <div v-if="merchantInfo" class="info-box">
          <div class="info-header">
            <div class="title">
              <!-- <span v-if="merchantInfo.isSelf == 1" class="label">自营</span> -->
              <span class="text">{{ merchantInfo.title }}</span>
              <span class="years">{{ live }}年店</span>
            </div>
          </div>
          <div class="row-list">
            <div class="list-box">
              <span class="label">店铺地址</span>
              <span class="text">{{ merchantInfo.address }}</span>
            </div>
            <div class="list-box">
              <span class="label">总热度</span>
              <span class="text">{{ merchantInfo.totaHeat }}</span>
            </div>
            <div class="list-box">
              <span class="label">主打产品</span>
              <span class="text">{{ merchantInfo.mainProduct }}</span>
            </div>
            <div class="list-box">
              <span class="label">关注人数</span>
              <span class="text">{{ merchantInfo.favoriteCount }} 人</span>
            </div>
            <div class="list-box">
              <span class="label">累积评价</span>
              <span class="text">{{ merchantInfo.evaluateCount }} 次</span>
            </div>
            <div class="list-box">
              <span class="label">qq</span>
              <span class="text" v-if="merchantInfo.qq">{{
                merchantInfo.qq.slice(0, 2) + "****" + merchantInfo.qq.slice(-2)
              }}</span>
            </div>
            <div class="list-box">
              <span class="label">微信</span>
              <span class="text" v-if="merchantInfo.weixin">{{
                merchantInfo.weixin.slice(0, 2) +
                "****" +
                merchantInfo.weixin.slice(-2)
              }}</span>
            </div>
            <div class="list-box">
              <span class="label">联系电话</span>
              <span class="text" v-if="merchantInfo.mobile">{{
                merchantInfo.mobile.slice(0, 2) +
                "****" +
                merchantInfo.mobile.slice(-2)
              }}</span>
            </div>
          </div>
          <div class="btn-list">
            <el-button
              v-if="goods"
              @click="common.linkUrl(`/shop/store/index/${goods.merchantId}`)"
              size="mini"
              >进店逛逛</el-button
            >
            <el-button
              v-if="!isMerchantFocus"
              size="mini"
              @click="addMerchantFocus"
              >关注店铺</el-button
            >
            <el-button v-else size="mini" @click="addMerchantFocus"
              >取消关注</el-button
            >
          </div>
        </div>
        <div class="head-box">
          <span>店铺推荐</span>
        </div>
        <div class="goods-list">
          <router-link
            v-for="item in redList"
            :to="'/shop/goods/show/' + item.id"
            class="list-box"
            :key="item.id"
          >
            <div class="img-box">
              <el-image :src="common.loadFile(item.imgUrl)"></el-image>
            </div>
            <div class="title">
              <span class="label">{{ item.brandTitle }}</span>
              <span>{{ item.title }}</span>
            </div>
            <div class="price"><span>¥</span>{{ item.sellPrice }}</div>
          </router-link>
        </div>
      </div>
    </div>
    <!--页面内容.结束-->
  </div>
</template>

<script>
import AxiosAjax from "@/utils/axios.config";
import { ReplaceImagesPath } from "@/utils/utilsHelper";
import GoodsShowGallery from "@/components/GoodsShowGallery.vue";
import { create, all } from "mathjs";
import { mapActions } from "vuex";
const mathjs = create(all);
mathjs.config({ number: "BigNumber", precision: 32 });
export default {
  name: "AATShopShow",
  components: {
    GoodsShowGallery,
  },
  data() {
    return {
      tabIndex: 0, //TAB选项
      isMerchantFocus: false, //店铺收藏
      goodsId: this.$route.params.id, //商品ID
      goodsSkus: [], //商品规格
      goodsProducts: [], //商品货品
      couponList: [], //优惠券列表
      promotionList: [], //活动列表
      evaluateList: [], //评价列表
      skuList: [], //商品规格
      speedProduct: null, //限时抢购
      presellProduct: null, //预售商品
      // skuProduct: null, //已选中货品
      merchantInfo: null, //店铺信息
      redList: [], //推荐商品
      type: 0, //筛选类别0全部1有图2好评3中差评
      totalCount: 0, //总数量
      pageSize: 10, //每页显示条数
      pageIndex: 1, //当前页码
      goods: null,
      selectedGoods: {},
      activeGoodsType: null,
      showSelectdDetails: false,
    };
  },
  methods: {
    ...mapActions({
      getCartCount: "getCartCount",
    }),
    selectedColorsData(skus) {
      const colorSku = skus.find((sku) => sku.parentId == 0);
      return skus.filter((sku) => sku.parentId == colorSku.skuId);
    },
    //加载评价列表
    async loadData() {
      let _this = this;
      let sendUrl = `/client/goods/evaluate/${_this.goodsId}?pageSize=${_this.pageSize}&pageIndex=${_this.pageIndex}`;
      //有图
      if (_this.type === 1) {
        sendUrl += `&&image=1`;
      }
      //好评
      else if (_this.type === 2) {
        sendUrl += `&&score=3`;
      }
      //中差评
      else if (_this.type === 3) {
        sendUrl += `&&score=2`;
      }
      AxiosAjax({
        url: sendUrl,
        loading: true,
        success: function (res) {
          _this.evaluateList = res.data;
          let pageInfo = JSON.parse(res.headers["x-pagination"]);
          _this.totalCount = pageInfo.totalCount;
          _this.pageSize = pageInfo.pageSize;
          _this.pageIndex = pageInfo.pageIndex;
        },
        error: function () {
          _this.evaluateList = [];
        },
      });
    },
    //初始化数据
    async initData(productId = 0) {
      let _this = this;
      let goods = null;
      //检查商品信息
      await AxiosAjax({
        url: `/client/shop/goods/${_this.goodsId}`,
        success: function (res) {
          goods = _this.goods = res.data;
          _this.goodsSkus = goods.goodsSkus;
          _this.goodsProducts = goods.goodsProducts;
        },
      });

      if (_this.goodsId === 0) {
        return;
      }
      //赋值给规格列表
      // _this.skuList = goods.goodsSkus.filter(val => val.parentId === 0);
      _this.skuList = this.selectedColorsData(goods.goodsSkus);
      // 初始化已选商品
      const selectedGoods = {};
      _this.skuList.forEach((item) => {
        const childrenArr = goods.goodsProducts.filter(
          (val) => val.skuIds.split(",")[0] == item.skuId
        );
        const children = {};
        childrenArr.forEach((item) => {
          children[item.id] = { ...item, count: 0 };
        });
        selectedGoods[item.id] = { ...item, children };
      });
      _this.selectedGoods = selectedGoods;
      _this.activeGoodsType = _this.skuList[0];
      _this.skuList.forEach((item) => {
        item.children = goods.goodsProducts.filter(
          (val) => val.skuIds.split(",")[0] == item.skuId
        );
      });
      //如果指定选中规格
      if (productId > 0) {
        //查找货品
        let productModel = goods.goodsProducts.find(
          (val) => val.id === productId
        );
        if (productModel) {
          let skuIds = productModel.skuIds.split(",").map(Number);
          _this.skuList.forEach((item) => {
            for (let citem of item.children) {
              for (let sid of skuIds) {
                if (citem.skuId === sid) {
                  _this.$set(citem, "selected", true);
                  break;
                }
              }
            }
          });
        }
      }
      //默认选中第一个规格
      else {
        _this.skuList.forEach((item) => {
          for (let citem of item.children) {
            if (citem.parentId === item.skuId) {
              _this.$set(citem, "selected", true);
              break;
            }
          }
        });
      }

      // _this.filterProduct(); //查找出已选中的货品
      _this.loadData(); //加载评价列表

      //加载店铺信息
      AxiosAjax({
        url: `/client/merchant/${goods.merchantId}`,
        success(res) {
          _this.merchantInfo = res.data;
        },
      });
      //加载人气前5条商品列表
      AxiosAjax({
        url: `/client/shop/goods/view/5?merchantId=${goods.merchantId}&orderBy=-Click`,
        success(res) {
          _this.redList = res.data;
        },
      });
      //加载优惠券列表
      AxiosAjax({
        url: `/client/shop/coupon/view/10?merchantId=${goods.merchantId}`,
        success(res) {
          _this.couponList = res.data;
        },
      });
      //加载活动列表
      AxiosAjax({
        url: `/client/shop/promotion/view/10?merchantId=${goods.merchantId}`,
        success(res) {
          _this.promotionList = res.data;
        },
      });
      //加载店铺收藏信息
      AxiosAjax({
        url: `/account/merchant/favorite/${goods.merchantId}`,
        success() {
          _this.isMerchantFocus = true;
        },
      });
    },
    //选中规格
    selectSku(list, val) {
      let _this = this;
      //取消同级选中
      list.forEach((item) => {
        _this.$set(item, "selected", false);
      });
      //选中自身
      _this.$set(val, "selected", true);
      //查找出已选中的货品
      // _this.filterProduct();
    },
    //查找选中的货品
    // async filterProduct(load = false) {
    //     console.log(this.selectedGoods);
    //     let _this = this;
    //     let skuIds = [];
    //     _this.skuList.forEach(item => {
    //         var obj = item.children.find(val => val.selected);
    //         if (obj) {
    //             skuIds.push(obj.skuId);
    //         }
    //     });
    //     _this.skuProduct = _this.goodsProducts.find(val => val.skuIds.split(',').map(Number).sort().toString() == skuIds.sort().toString());
    //     // simba添加
    //     if (!_this.skuProduct) return
    //     _this.$set(_this.skuProduct, 'quantity', 1);
    //     //查找限时抢购信息
    //     await AxiosAjax({
    //         url: `/client/shop/speed/product/${_this.skuProduct.id}`,
    //         loading: load,
    //         next: true,
    //         success(res) {
    //             _this.speedProduct = res.data;
    //         },
    //         error() {
    //             _this.speedProduct = null;
    //         }
    //     });
    //     //查找商品预售信息
    //     if (_this.speedProduct == null) {
    //         await AxiosAjax({
    //             url: `/client/shop/presell/product/${_this.skuProduct.id}`,
    //             loading: load,
    //             success(res) {
    //                 _this.presellProduct = res.data;
    //             },
    //             error() {
    //                 _this.presellProduct = null;
    //             }
    //         });
    //     }
    // },
    //添加/取消店铺收藏
    async addMerchantFocus() {
      let _this = this;
      if (!_this.isMerchantFocus) {
        _this.goods &&
          (await AxiosAjax({
            url: `/account/merchant/favorite/${_this.goods.merchantId}`,
            method: "post",
            successMsg: "店铺关注成功",
            success: function () {
              _this.isMerchantFocus = true;
            },
          }));
      } else {
        _this.goods &&
          (await AxiosAjax({
            url: `/account/merchant/favorite/${_this.goods.merchantId}`,
            method: "delete",
            successMsg: "取消关注成功",
            success: function () {
              _this.isMerchantFocus = false;
            },
          }));
      }
    },
    //领取优惠券
    async addCoupon(val) {
      await AxiosAjax({
        method: "post",
        url: `/account/shop/coupon/add`,
        data: {
          couponId: val,
        },
        successMsg: "优惠券领取成功",
        success: function () {
          //console.log(res)
        },
      });
    },
    //加入购物车
    addCart() {
      let _this = this;
      if (_this.selectedTotalCount == 0) {
        _this.$message({
          message: "请选择商品规格",
          type: "warning",
        });
        return;
      }
      const goodsData = [];
      Object.values(_this.selectedGoods).forEach((item) => {
        if (item.children) {
          Object.values(item.children).forEach((child) => {
            if (child.count > 0) goodsData.push(child);
          });
        }
      });
      AxiosAjax({
        method: "post",
        url: `/account/shop/cart/addList`,
        loading: true,
        data: goodsData.map((product) => ({
          productId: product.id,
          quantity: product.count,
        })),
        successMsg: "加入购物车成功",
        success: function () {
          _this.getCartCount();
          //console.log(res)
        },
      });
    },
    //立即购买
    addOrder() {
      let _this = this;
      //检查是否已选规格
      if (_this.selectedTotalCount == 0) {
        _this.$message({
          message: "请选择商品规格",
          type: "warning",
        });
        return;
      }
      // //检查是否抢购商品
      // if (_this.speedProduct && _this.speedProduct.id) {
      //     window.location.href = `/shop/order/confirm?type=1&id=${_this.speedProduct.id}`;
      // }
      // //是否预售商品
      // else if (_this.presellProduct && _this.presellProduct.id) {
      //     window.location.href = `/shop/order/confirm?type=2&id=${_this.presellProduct.id}`;
      // }
      // else {
      //     //组合已选规格货口
      //     let goodsData = [];
      //     goodsData.push({
      //         productId: _this.skuProduct.id,
      //         quantity: _this.skuProduct.quantity
      //     })
      //     window.location.href = `/shop/order/confirm?type=0&data=${JSON.stringify(goodsData)}`;
      // }
      let goodsData = [];
      Object.values(_this.selectedGoods).forEach((item) => {
        if (item.children) {
          Object.values(item.children).forEach((child) => {
            if (child.count > 0) goodsData.push(child);
          });
        }
      });
      goodsData = goodsData.map((product) => ({
        productId: product.id,
        quantity: product.count,
      }));
      this.$router.push(
        `/shop/order/confirm?type=0&data=${JSON.stringify(goodsData)}`
      );
    },
    //点击评价筛选按钮
    tabClick(type) {
      this.type = type;
      //重新加载
      this.loadData();
    },
    //跳转到评价第几页
    handleCurrentChange: function (val) {
      if (this.pageIndex != val) {
        this.pageIndex = val;
        this.loadData();
      }
    },
    ReplaceImagesPath,
    goodsTypeClassName(goodsTypeId) {
      if (goodsTypeId == this.activeGoodsType.id) return "";
      const arr = Object.values(this.selectedGoods[goodsTypeId].children);
      if (arr.some((item) => item.count > 0))
        return "goods-type-item-selected-no-active";
      return "";
    },
    addGoods(goods) {
      const { id } = this.findParentHandle(goods);
      if (
        this.selectedGoods[id].children[goods.id].count >=
          this.selectedGoods[id].children[goods.id].stockQuantity ||
        this.selectedGoods[id].children[goods.id].stockQuantity <= 0
      )
        return;
      this.$set(
        this.selectedGoods[id].children[goods.id],
        "count",
        (this.selectedGoods[id].children[goods.id].count += 1)
      );
    },
    decreaseGoods(goods) {
      const { id } = this.findParentHandle(goods);
      if (this.selectedGoods[id].children[goods.id].count > 0)
        this.$set(
          this.selectedGoods[id].children[goods.id],
          "count",
          (this.selectedGoods[id].children[goods.id].count -= 1)
        );
    },
    toggleSelectdDetails() {
      if (!this.showSelectdDetails && this.selectedTotalCount == 0) return;
      this.showSelectdDetails = !this.showSelectdDetails;
    },
    findParentHandle(item) {
      const parentId = item.skuIds.split(",")[0];
      return (
        this.goodsSkus.find((goodsSku) => goodsSku.skuId == parentId) || {}
      );
    },
    calculateTotal(goodsType) {
      let sum = 0;
      for (const key in goodsType.children) {
        if (Object.hasOwnProperty.call(goodsType.children, key)) {
          sum += goodsType.children[key].count;
        }
      }
      return sum;
    },
    toggleCountInput(e, show = true) {
      if (show) {
        e.target.style.display = "none";
        e.target.nextElementSibling.style.display = "inline-block";
      } else {
        e.target.style.display = "none";
        e.target.previousElementSibling.style.display = "inline-block";
      }
    },
    changeGoodsCount(e, goods) {
      const count = Number(e.target.value);
      if (isNaN(count) || count < 0)
        return (e.target.value =
          this.selectedGoods[this.findParentHandle(goods).id].children[
            goods.id
          ].count);
      this.$set(
        this.selectedGoods[this.findParentHandle(goods).id].children[goods.id],
        "count",
        count > goods.stockQuantity ? goods.stockQuantity : count
      );
      e.target.value =
        count > goods.stockQuantity ? goods.stockQuantity : count;
    },
    clickGoodsTypeItem(item) {
      const imgSrc = this.common.loadFile(item.imgUrl);
      this.activeGoodsType = item;
      Array.from(document.querySelectorAll("[data-name=thumb-img]")).forEach(
        (el) => {
          el.src = imgSrc;
        }
      );
      const el = document.querySelector("[data-name=thumb-video]");
      el && el.remove();
    },
  },
  computed: {
    live() {
      if (this.merchantInfo) {
        return (
          new Date().getFullYear() -
          new Date(this.merchantInfo.addTime).getFullYear()
        );
      }
      return 0;
    },
    goodsSelectedList() {
      return "";
    },
    goodsSize() {
      const result = this.skuList.find(
        (goodsType) => goodsType.id == this.activeGoodsType.id
      );
      return result?.children || [];
    },
    selectedGoodsAmount() {
      let sum = 0;
      for (const key in this.selectedGoods) {
        for (const jKey in this.selectedGoods[key].children) {
          sum = mathjs.evaluate(
            `${this.selectedGoods[key].children[jKey].sellPrice} * ${this.selectedGoods[key].children[jKey].count} + ${sum}`
          );
        }
      }
      return sum;
    },
    selectedTotalCount() {
      let total = 0;
      for (const key in this.selectedGoods) {
        for (const jKey in this.selectedGoods[key].children) {
          total += this.selectedGoods[key].children[jKey].count;
        }
      }
      return total;
    },
    skuProduct() {
      if (this.activeGoodsType && this.activeGoodsType.children)
        return this.activeGoodsType.children[0] || {};
      return {};
    },
  },
  created() {
    let productId = parseInt(this.common.queryValue("pid"));
    this.initData(productId);
  },
  beforeRouteUpdate(to, _, next) {
    this.goodsId = to.params.id;
    this.goods = null;
    this.skuList = [];
    let productId = parseInt(this.common.queryValue("pid"));
    this.initData(productId);
    window.scrollTo({
      top: 0,
    });
    next();
  },
};
</script>

<style lang="scss" scoped>
.prop-name {
  color: #767676;
  line-height: 30px;
  font-size: 12px;
}

.goods-type {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #f0f0f0;

  .goods-type-item {
    border: 1px solid #d7d7d7;
    display: inline-block;
    height: 68px;
    line-height: 30px;
    margin-bottom: 10px;
    margin-left: 15px;
    min-width: 20px;
    text-align: center;
    cursor: pointer;
    position: relative;
    box-sizing: content-box;

    &.goods-type-item-selected-no-active {
      border: 1px solid #dabbaa;
      height: 68px;
      padding: 0;
      line-height: 28px;

      .selected-icon {
        position: absolute;
        bottom: 0;
        right: 0;
        display: block;
        width: 14px;
        height: 14px;
        background-image: url("@/assets/images/iconImg.png");
        background-repeat: no-repeat;
      }
    }

    &.goods-type-item-selected {
      border: 1px solid #f31e1e;
      height: 68px;
      padding: 0;
      line-height: 28px;
    }

    img {
      height: 51px;
      width: 51px;
      display: block;
    }

    .goods-title {
      display: block;
      line-height: 17px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: 51px;
      color: #333;
      background: #f5f5f5;
      text-align: center;
    }
  }
}

.goods-size {
  border-bottom: 1px solid #f0f0f0;

  &::after {
    content: "";
    display: block;
    clear: both;
  }

  .goods-size-label {
    color: #767676;
    line-height: 30px;
    font-size: 12px;
    float: left;
  }

  .goods-content {
    color: #525252;
    font-size: 12px;
    float: left;
    width: 445px;

    table {
      width: 100%;

      td {
        height: 43px;
        border-bottom: 1px dotted #e5e5e5;
      }

      tr:last-child {
        td {
          border-bottom: 0;
        }
      }

      .sizeT {
        width: 180px;
        padding-right: 10px;
        font-weight: bold;
        color: #545353;
        padding-left: 16px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }

      .weightT,
      .priceT {
        width: 74px;
        text-align: center;
        padding-right: 10px;
      }
    }
  }
}

.fortifyBox {
  i {
    width: 26px;
    height: 26px;
    line-height: 22px;
    cursor: pointer;
    background: #f5f5f5;
    vertical-align: middle;
    background-image: url("@/assets/images/bg.png");
    background-repeat: no-repeat;
    border: 1px solid #d9d9d9;
    background-position: -32px -52px;
    display: inline-block;
    margin-right: -1px;
    margin-left: -1px;
    -moz-user-select: none;
    -khtml-user-select: none;
    user-select: none;
  }

  .iptText {
    width: 35px;
    padding: 0 4px;
    height: 26px;
    vertical-align: top;
    cursor: text;
    text-align: center;
    border: 1px solid #d9d9d9;
    box-sizing: content-box;
  }

  .defReduc {
    background-position: -32px 8px;
    cursor: pointer;

    &:hover {
      background-position: -32px -22px;
    }
  }

  .add {
    background-position: 8px 8px;

    &:hover {
      background-position: 8px -22px;
    }
  }
}

.meg-lits {
  height: 49px;
  line-height: 49px;
  background-color: #fbfbfb;

  ul {
    width: 100%;
    position: relative;

    li {
      float: left;
      height: 100%;
      font-size: 14px;
    }

    .list-unit {
      color: #db2121;
      width: 90px;
      text-align: right;
      padding-right: 19px;
      border-right: 1px solid #f0f0f0;
    }
  }

  .list-prics {
    padding-left: 15px;
    width: 300px;
    color: #db2121;
    font-weight: bold;
    font-size: 16px;
    border-right: 1px solid #f0f0f0;
  }

  .nelenYx:hover {
    color: #f7641a;

    .menuBtnJust {
      background-image: url("@/assets/images/menuBtnHov.png");
    }
  }

  .list-checklist {
    padding-left: 18px;
    font-size: 14px;
    color: #767676;
    cursor: pointer;
    -moz-user-select: none;
    -khtml-user-select: none;
    user-select: none;

    .menuBtnJust {
      width: 14px;
      height: 12px;
      background: url("@/assets/images/menuBtn.png") no-repeat;
      display: inline-block;
      margin-left: 7px;
    }

    .list-after {
      width: 100%;
      left: 0;
      bottom: 100%;
      position: absolute;
      background: #fff;
      border-top: 1px solid #e7e7e7;
      box-shadow: 0 -3px 4px -3px #d5d5d5;

      &.no-active {
        display: none;
      }

      table {
        width: 100%;
        font-size: 14px;
        border-collapse: collapse;
        border-spacing: 0;
      }

      td {
        border: 1px solid #e7e7e7;
        border-top: none;
      }

      .propSt {
        width: 89px;
        padding: 0 20px;
        color: #545353;
        font-weight: 700;
      }

      .amount {
        width: 60px;
        padding: 0 20px;
        box-sizing: content-box;
      }

      .desc {
        padding: 0 15px;
      }

      .selkList {
        float: left;
        margin-right: 10px;
      }

      .ultim {
        width: 75px;
        display: inline-block;
      }

      .cngInt {
        display: inline-block;
        margin-left: 8px;

        i {
          width: 17px;
          background-position: -36px -52px;
        }

        .defReduc {
          background-position: -36px 8px;
          cursor: pointer;

          &:hover {
            background-position: -36px -22px;
          }
        }

        .iptText {
          width: 23px;
          vertical-align: middle;
        }

        .add {
          background-position: 4px 8px;

          &:hover {
            background-position: 4px -22px;
          }
        }
      }
    }
  }
}

.gray-tr {
  position: relative;
  background: #eee;

  &::after {
    display: block;
    content: "";
    height: 100%;
    width: 100%;
    background: #e6e2e2;
    opacity: 0.5;
    position: absolute;
    left: 0;
    top: 0;
  }
}

table {
  border-collapse: collapse;
}
.years {
  width: 40px;
  height: 16px;
  border: 1px solid #e3c71c;
  display: inline-block;
  color: #783400;
  text-align: center;
  line-height: 16px;
  border-radius: 1px;
  background: #fbde2d;
  margin-left: 1px;
}
</style>
