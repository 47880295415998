<template>
    <div>
        <div class="wrapper">
            <div class="account-box">

                <AccountNav :account="account" model="order" />

                <div class="right-box">
                    <div class="tab-box">
                        <div class="menu-box">
                            <div class="item-box active">
                                <span>售后详情</span>
                            </div>
                        </div>
                    </div>
                    <div class="content">
                        <div class="order-detail">
                            <div class="screen-box">
                                <div class="nav-bar">
                                    <span>商品信息</span>
                                </div>
                                <div class="goods-box">
                                    <div v-for="(item, index) in model.refundGoods" :key="index" class="list-box">
                                        <div class="img-box">
                                            <img :src="common.loadFile(item.orderGoods.imgUrl)">
                                        </div>
                                        <div class="txt-box">
                                            <span class="title">{{ item.orderGoods.goodsTitle }}</span>
                                            <span class="info">{{ item.orderGoods.skuText }} x
                                                {{ item.orderGoods.quantity }}</span>
                                            <span class="price">{{ item.orderGoods.realPrice }}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="screen-box">
                                <div class="nav-bar">
                                    <span>处理状态</span>
                                    <el-button v-if="model.handleStatus == 1" size="small" type="warning"
                                        @click="handleDelivery">已寄出，填写物流信息</el-button>
                                </div>
                                <div class="item-box">
                                    <div class="item">
                                        <div class="left">处理状态</div>
                                        <div class="right">
                                            <span v-if="model.handleStatus === 1" class="remark">待买家寄回</span>
                                            <span v-else-if="model.handleStatus === 2" class="remark">待商家发货</span>
                                            <span v-else-if="model.handleStatus === 3" class="remark">已完成</span>
                                            <span v-else-if="model.handleStatus === 4" class="remark">拒绝</span>
                                            <span v-else class="remark">处理中</span>
                                        </div>
                                    </div>
                                    <div v-if="model.handleTime" class="item">
                                        <div class="left">处理时间</div>
                                        <div class="right">{{ model.handleTime }}</div>
                                    </div>
                                    <div v-if="model.handleRemark" class="item">
                                        <div class="left">处理说明</div>
                                        <div class="right">{{ model.handleRemark }}</div>
                                    </div>
                                    <div v-if="model.type === 1 && model.handleStatus === 3" class="item">
                                        <div class="left">退款金额</div>
                                        <div class="right">￥{{ model.refundAmount }} 元</div>
                                    </div>
                                </div>
                            </div>

                            <div class="screen-box">
                                <div class="nav-bar">
                                    <span>订单信息</span>
                                </div>
                                <div class="item-box">
                                    <div class="item">
                                        <div class="left">订单编号</div>
                                        <div class="right">{{ model.orderNo }}</div>
                                    </div>
                                    <div class="item">
                                        <div class="left">售后类型</div>
                                        <div class="right">
                                            <span v-if="model.type === 1">申请退款</span>
                                            <span v-else>申请换货</span>
                                        </div>
                                    </div>
                                    <div class="item">
                                        <div class="left">申请时间</div>
                                        <div class="right">{{ model.applyTime }}</div>
                                    </div>
                                    <div class="item">
                                        <div class="left">申请理由</div>
                                        <div class="right">{{ model.applyReason }}</div>
                                    </div>
                                    <div class="item">
                                        <div class="left">申请凭证</div>
                                        <div class="right">
                                            <div v-if="model.refundAlbums.length > 0" class="album">
                                                <div v-for="(item, index) in model.refundAlbums" :key="index"
                                                    class="img-box">
                                                    <el-image fit="cover" :src="common.loadFile(item.thumbPath)"
                                                        :preview-src-list="[common.loadFile(item.originalPath)]"></el-image>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div v-if="model.uExpressTime" class="screen-box">
                                <div class="nav-bar">
                                    <span>买家发货</span>
                                </div>
                                <div class="item-box">
                                    <div class="item">
                                        <div class="left">快递公司</div>
                                        <div class="right">{{ model.uExpressTitle }}</div>
                                    </div>
                                    <div class="item">
                                        <div class="left">快递单号</div>
                                        <div class="right">{{ model.uExpressCode }}</div>
                                    </div>
                                    <div class="item">
                                        <div class="left">寄回时间</div>
                                        <div class="right">{{ model.uExpressTime }}</div>
                                    </div>
                                </div>
                            </div>

                            <div v-if="model.sExpressTime" class="screen-box">
                                <div class="nav-bar">
                                    <span>卖家发货</span>
                                </div>
                                <div class="item-box">
                                    <div class="item">
                                        <div class="left">快递公司</div>
                                        <div class="right">{{ model.sExpressTitle }}</div>
                                    </div>
                                    <div class="item">
                                        <div class="left">快递单号</div>
                                        <div class="right">{{ model.sExpressCode }}</div>
                                    </div>
                                    <div class="item">
                                        <div class="left">发货时间</div>
                                        <div class="right">{{ model.sExpressTime }}</div>
                                    </div>
                                </div>
                            </div>


                        </div>
                    </div>
                </div>
            </div>
        </div>
        <el-dialog title="填写物流信息" :visible.sync="showFormDialog">
            <el-form :model="expressModel" :rules="rules" ref="editForm" label-width="120px">
                <el-form-item label="选择快递" prop="uExpressId">
                    <el-select v-model="expressModel.uExpressId" placeholder="请选择...">
                        <el-option v-for="(item, index) in expressList" :key="index" :label="item.title"
                            :value="item.id"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="物流单号" prop="uExpressCode">
                    <el-input v-model="expressModel.uExpressCode" placeholder="物流/快递单号"></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="showFormDialog = false">取 消</el-button>
                <el-button type="primary" @click="submitForm">确 定</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import AxiosAjax from "@/utils/axios.config";
import AccountNav from "@/components/AccountNav.vue";

export default {
    name: 'AATShopRefundShow',
    components: {
        AccountNav
    },
    data() {
        return {
            id: this.$route.params.id,
            showFormDialog: false,
            account: {}, //账户信息
            //售后详情
            model: {
                refundAlbums: [],
                refundGoods: []
            },
            //快递信息
            expressModel: {
                uExpressId: null,
                uExpressCode: null
            },
            //快递列表
            expressList: [],
            //验证规则
            rules: {
                uExpressId: [
                    { required: true, message: '请选择快递公司', trigger: 'change' }
                ],
                uExpressCode: [
                    { required: true, message: '请填写快递单号', trigger: 'blur' },
                ],
            }
        };
    },
    methods: {
        //初始化数据
        async initData(id) {
            let _this = this;
            //获取会员信息
            await AxiosAjax({
                url: `/account/member`,
                success: function (res) {
                    _this.account = res.data;
                }
            });
            //加载售后信息
            await AxiosAjax({
                url: `/account/order/refund/${id}`,
                success(res) {
                    _this.model = res.data;
                }
            });
            //加载快递列表
            await AxiosAjax({
                url: `/client/shop/express/view/0`,
                success: function (res) {
                    _this.expressList = res.data;
                    //买家快递名称
                    if (_this.model.uExpressId) {
                        let obj = _this.expressList.find(val => val.id == _this.model.uExpressId);
                        if (obj) {
                            _this.$set(_this.model, 'uExpressTitle', obj.title);
                        }
                    }
                    //商家快递名称
                    if (_this.model.sExpressId) {
                        let obj = _this.expressList.find(val => val.id == _this.model.sExpressId);
                        if (obj) {
                            _this.$set(_this.model, 'sExpressTitle', obj.title);
                        }
                    }
                }
            });

        },
        //填写物流信息
        handleDelivery() {
            let _this = this;
            _this.showFormDialog = true;
        },
        //提交表单
        async submitForm() {
            let _this = this;
            let id = _this.model.id;
            let model = _this.expressModel;
            //多个表单验证
            const f1 = new Promise((resolve) => {
                _this.$refs['editForm'].validate((valid) => {
                    if (valid) resolve()
                });
            });
            Promise.all([f1]).then(() => {
                AxiosAjax({
                    method: 'put',
                    url: `/account/order/refund/return/${id}`,
                    data: model,
                    loading: true,
                    successMsg: '提交成功',
                    success: function () {
                        _this.showFormDialog = false;
                        _this.initData(id);
                    }
                });
            });
        },
    },
    created() {
        this.initData(this.id);
    }
};
</script>

<style lang="scss" scoped></style>