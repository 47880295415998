<template>
    <div class="wrapper">
        <div class="account-box">
            <AccountNav :account="account" model="order"/>
            <div class="right-box">
                <div class="tab-box">
                    <div class="menu-box">
                        <div class="item-box active">
                            <span>订单评价</span>
                        </div>
                    </div>
                </div>
                <div class="content">
                    <div class="order-form">
                        <!--循环.开始-->
                        <div v-for="(item, index) in goodsList" :key="index" class="list-box">
                            <div class="goods-box">
                                <div class="img-box">
                                    <el-image fit="cover" :src="common.loadFile(item.imgUrl)"></el-image>
                                </div>
                                <div class="txt-box">
                                    <span class="title">{{ item.goodsTitle }}</span>
                                    <span class="info">{{ item.specText }} x {{ item.quantity }}</span>
                                    <span class="price">{{ item.realPrice }}</span>
                                </div>
                            </div>
                            <div class="form-box">
                                <div class="item-box">
                                    <div class="left">满意度</div>
                                    <div class="right">
                                        <el-radio-group v-model="item.goodsScore">
                                            <el-radio :label="3">好评</el-radio>
                                            <el-radio :label="2">中评</el-radio>
                                            <el-radio :label="1">差评</el-radio>
                                        </el-radio-group>
                                    </div>
                                </div>
                                <div class="item-box">
                                    <div class="left">评价内容</div>
                                    <div class="right">
                                        <el-input v-model="item.content" type="textarea" :rows="3"
                                            placeholder="请输入评价内容"></el-input>
                                    </div>
                                </div>
                                <div class="item-box">
                                    <div class="left">评价晒图</div>
                                    <div class="right">
                                        <dt-upload-album v-model="item.evaluateAlbums" :limit="5"></dt-upload-album>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <!--循环.结束-->

                        <div class="list-box">
                            <div class="form-box">
                                <div class="item-box">
                                    <div class="left">服务评分</div>
                                    <div class="right">
                                        <el-rate v-model="serviceScore"></el-rate>
                                    </div>
                                </div>
                                <div class="item-box">
                                    <div class="left">物流评分</div>
                                    <div class="right">
                                        <el-rate v-model="matterScore"></el-rate>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="btn-box">
                            <el-button @click="common.back(-1)">返回上一页</el-button>
                            <el-button type="danger" @click="submitFrom">确认提交</el-button>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</template>

<script>
import AxiosAjax from "@/utils/axios.config";
import AccountNav from "@/components/AccountNav.vue";
export default {
    name: 'AATShopEvaluateEdit',
    components: {
        AccountNav
    },
    data() {
        return {
            orderId: this.$route.params.id,
            serviceScore: 0, //服务评分
            matterScore: 0, //物流评分
            goodsList: [], //订单货品列表
            account: {}, //账户信息  
        };
    },
    methods: {
        //初始化数据
        async initData(orderId) {
            let _this = this;
            //加载订单信息
            await AxiosAjax({
                url: `/account/order/${orderId}`,
                success: function (res) {
                    const orderModel = res.data;
                    _this.orderId = orderModel.id;
                    _this.goodsList = orderModel.orderGoods.filter(val => val.deliveryStatus === 1 && val.evaluateStatus === 0);
                    _this.goodsList.forEach(item => {
                        _this.$set(item, 'goodsScore', 0);
                        _this.$set(item, 'content', '');
                        _this.$set(item, 'evaluateAlbums', []);
                    })
                }
            });
            //获取会员信息
            await AxiosAjax({
                url: `/account/member`,
                success: function (res) {
                    _this.account = res.data;
                }
            });
        },
        //桷认提交
        async submitFrom() {
            let _this = this;
            let evaluateList = []; //待提交数组

            if (_this.serviceScore === 0) {
                _this.$message({
                    message: '提示，请选择服务评分',
                    type: 'warning'
                });
                return;
            }
            if (_this.matterScore === 0) {
                _this.$message({
                    message: '提示，请选择物流评分',
                    type: 'warning'
                });
                return;
            }
            //开始遍历商品列表
            for (let item of _this.goodsList) {
                if (item.goodsScore === 0) {
                    _this.$message({
                        message: '提示，请选择商品评分',
                        type: 'warning'
                    });
                    return;
                }
                if (!item.content) {
                    _this.$message({
                        message: '提示，请填写商品评价内容',
                        type: 'warning'
                    });
                    return;
                }
                //添加进数组
                evaluateList.push({
                    serviceScore: _this.serviceScore,
                    matterScore: _this.matterScore,
                    orderGoodsId: item.id,
                    goodsScore: item.goodsScore,
                    content: item.content,
                    evaluateAlbums: item.evaluateAlbums
                });
            }
            //提交表单
            await AxiosAjax({
                method: "post",
                url: `/account/order/evaluate/${_this.orderId}`,
                data: evaluateList,
                loading: true,
                successMsg: '交易评价成功',
                success: function () {
                    setTimeout(() => {
                        _this.common.linkUrl(`/account/order/show/${_this.orderId}`);
                    }, 1500);
                }
            });
        },
    },
    created() {
        this.initData(this.orderId)
    }
};
</script>

<style lang="scss" scoped></style>