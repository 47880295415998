<template>
    <div class="wrapper">
        <div class="account-box">

            <AccountNav :account="account" model="order" />

            <div class="right-box">
                <div class="tab-box">
                    <div class="menu-box">
                        <div class="item-box active">
                            <span>售后服务</span>
                        </div>
                    </div>
                </div>
                <div class="content">
                    <div v-if="listData.length === 0" class="nodata">
                        <span class="icon iconfont icon-msg"></span>
                        <span class="text">暂无记录</span>
                    </div>
                    <div class="order-list">
                        <div v-for="(item, index) in listData" :key="index" class="list-box">
                            <div class="head-box">
                                <div>
                                    <span v-if="item.type === 1" class="text">退款</span>
                                    <span v-else class="text">换货</span>
                                    <span class="time">{{ item.applyTime }}</span>
                                </div>
                                <span class="state" :class="{ red: item.handleStatus === 1 }">{{
                                    orderStateExp(item.handleStatus) }}</span>
                            </div>
                            <div v-for="(goods, gindex) in item.refundGoods" :key="gindex" class="item-box">
                                <div class="img-box">
                                    <img :src="common.loadFile(goods.orderGoods.imgUrl)">
                                </div>
                                <div class="txt-box">
                                    <span class="text">{{ goods.orderGoods.goodsTitle }}</span>
                                    <span class="info">{{ goods.orderGoods.skuText }} x {{ goods.orderGoods.quantity
                                    }}</span>
                                    <span class="price">{{ goods.orderGoods.realPrice }}</span>
                                </div>
                            </div>
                            <div v-if="item.type === 1 && item.handleStatus === 3" class="total-box">
                                <div class="right">
                                    共计退款 <span class="price">￥{{ item.refundAmount }} 元</span>
                                </div>
                            </div>
                            <div class="btn-box">
                                <el-button type="primary"
                                    @click="common.linkUrl('/account/refund/show/' + item.id)">查看详情</el-button>
                                <el-button v-if="item.handleStatus === 1"
                                    @click="common.linkUrl('/account/refund/show/' + item.id)" type="danger">去发货</el-button>
                            </div>
                        </div>
                    </div>

                    <div class="pager-box">
                        <el-pagination background :hide-on-single-page="true" @current-change="handleCurrentChange"
                            :page-size="pageSize" layout="prev, pager, next" :total="totalCount">
                        </el-pagination>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import AxiosAjax from "@/utils/axios.config";
import AccountNav from "@/components/AccountNav.vue";

export default {
    name: 'AATShopRefundList',
    components: {
        AccountNav
    },
    data() {
        return {
            account: {}, //账户信息
            orderNo: null, //订单号
            totalCount: 0, //总数量
            pageSize: 10, //每页显示条数
            pageIndex: 1, //当前页码
            listData: [], //数据列表
        };
    },

    methods: {
        //加载列表
        async loadData() {
            let _this = this;
            let sendUrl = `/account/order/refund?pageSize=${_this.pageSize}&pageIndex=${_this.pageIndex}`;
            if (_this.orderNo) {
                sendUrl += `&orderNo=${_this.orderNo}`;
            }
            AxiosAjax({
                url: sendUrl,
                loading: true,
                success: function (res) {
                    _this.listData = res.data;
                    let pageInfo = JSON.parse(res.headers["x-pagination"]);
                    _this.totalCount = pageInfo.totalCount;
                    _this.pageSize = pageInfo.pageSize;
                    _this.pageIndex = pageInfo.pageIndex;
                },
                error: function () {
                    _this.listData = []
                }
            });
        },
        //初始化数据
        async initData() {
            let _this = this;
            //加载列表
            await _this.loadData();
            //获取会员信息
            await AxiosAjax({
                url: `/account/member`,
                success: function (res) {
                    _this.account = res.data;
                }
            });
        },
        //处理进度
        orderStateExp(status) {
            let stateTip = '处理中';
            if (status === 1) {
                stateTip = '待买家发货';
            } else if (status === 2) {
                stateTip = '待商家发货';
            } else if (status === 3) {
                stateTip = '已完成';
            } else if (status === 4) {
                stateTip = '已拒绝';
            }
            return stateTip;
        },
        //跳转到第几页
        handleCurrentChange: function (val) {
            if (this.pageIndex != val) {
                this.pageIndex = val;
                this.loadData();
            }
        },
    },
    created() {
        this.orderNo = this.common.queryValue("no");
        this.initData();
    }
};
</script>

<style lang="scss" scoped></style>