<template>
    <div class="goods-thumb">
        <div class="swiper-gallery">
            <div id="gallery" class="swiper-container">
                <div class="swiper-wrapper">
                    <div class="swiper-slide" @mouseenter="enterThumb" @mouseleave="leaveThumb" @mousemove="moveInThumb">
                        <img :src="common.loadFile(thumbImg)" data-name="thumb-img">
                        <div class="winSelector" name="winSelector"></div>
                        <div alt="播放" class="video-start" @click="playVideo()" v-if="goods.videoUrl && thumbActive == 0"></div>
                    </div>
                </div>
            </div>
            <div class="player" v-if="playing" data-name="thumb-video">
                <video width="400" height="400" preload="auto" controls autoplay loop class="object-fill">
                    <source :src="common.loadFile(videoSourcePath)" type="video/mp4">
                </video>
                <div class="close-btn" @click="closeVideo"></div>
            </div>
        </div>
        <GoodsShowThumb :active="thumbActive" :goodsAlbums="goods.goodsAlbums" @click="clickThumb"
            v-if="goods.goodsAlbums.length > 0" />
        <div class="btn-box">
            <span v-if="isFavorite" class="icon iconfont icon-star-full" @click="cancelFavorite(goods.id)">已收藏</span>
            <span v-else class="icon iconfont icon-star" @click="addFavorite(goods.id)">收藏</span>
            <span class="icon iconfont icon-service">联系客服</span>
        </div>

        <div id="bigView">
            <div id="bigViewImgDiv" :style="{
                left: (widthOfBigImgContainer - widthAndHeightOfBigImg) * shadowOffsetX + 'px',
                top: (heightOfBigImgContainer - widthAndHeightOfBigImg) * shadowOffsetY + 'px'
            }">
                <img :src="common.loadFile(thumbImg)" data-name="thumb-img">
            </div>
        </div>
    </div>
</template>

<script>
import AxiosAjax from '@/utils/axios.config';
import GoodsShowThumb from "@/components/GoodsShowThumb.vue";
export default {
    name: 'AATShopGoodsShowGallery',
    components: {
        GoodsShowThumb
    },
    props: {
        goods: Object,
    },
    data() {
        return {
            isFavorite: false,
            thumbImg: this.goods.goodsAlbums[0].originalPath,
            shadowOffsetX: 0,
            shadowOffsetY: 0,
            widthAndHeightOfBigImg: 800,
            widthOfBigImgContainer: 499,
            heightOfBigImgContainer: 524,
            videoSourcePath: "",
            playing: false,
            thumbActive: 0,
        }
    },
    methods: {
        async addFavorite(val) {
            let _this = this;
            await AxiosAjax({
                method: "post",
                url: `/account/member/favorite`,
                data: {
                    goodsId: val
                },
                successMsg: '商品收藏成功',
                success: function () {
                    _this.isFavorite = true;
                },
            });
        },
        async cancelFavorite(val) {
            let _this = this;
            await AxiosAjax({
                method: "delete",
                url: `/account/member/favorite/goods/${val}`,
                successMsg: '取消商品收藏成功',
                success: function () {
                    _this.isFavorite = false;
                },
            });
        },
        loadFavorite() {
            const _this = this
            // 加载商品收藏信息
            AxiosAjax({
                url: `/account/member/favorite/goods/${_this.goods.id}`,
                success() {
                    _this.isFavorite = true;
                },
            });
        },
        setWinSelectorPosition(e) {
            const { offsetX, offsetY } = e
            const widthAndHeightOfThumb = 400;
            const widthAndHeightOfWinSelector = 262
            let winSelectorEl = e.target
            if (winSelectorEl.getAttribute("name") != "winSelector") winSelectorEl = winSelectorEl.children[1]
            let { left, top } = getComputedStyle(winSelectorEl)
            left = parseInt(left)
            top = parseInt(top)
            const offsetx = offsetX - widthAndHeightOfWinSelector / 2
            const offsety = offsetY - widthAndHeightOfWinSelector / 2
            if (left + offsetx < 0) {
                winSelectorEl.style.left = 0
                this.shadowOffsetX = 0
            }
            else if (left + offsetx > widthAndHeightOfThumb - widthAndHeightOfWinSelector) {
                winSelectorEl.style.left = widthAndHeightOfThumb - widthAndHeightOfWinSelector + "px";
                this.shadowOffsetX = 1
            }
            else {
                winSelectorEl.style.left = left + offsetx + "px";
                this.shadowOffsetX = (left + offsetx) / (widthAndHeightOfThumb - widthAndHeightOfWinSelector)
            }
            if (top + offsety < 0) { winSelectorEl.style.top = 0; this.offsetY = 0 }
            else if (top + offsety > widthAndHeightOfThumb - widthAndHeightOfWinSelector) {
                winSelectorEl.style.top = widthAndHeightOfThumb - widthAndHeightOfWinSelector + "px";
                this.shadowOffsetY = 1
            }
            else {
                winSelectorEl.style.top = top + offsety + "px"
                this.shadowOffsetY = (top + offsety) / (widthAndHeightOfThumb - widthAndHeightOfWinSelector)
            }
        },
        enterThumb(e) {
            const winSelectorEl = e.target.children[1]
            document.querySelector("#bigView").style.display = winSelectorEl.style.display = "block"
            this.setWinSelectorPosition(e)
        },
        leaveThumb(e) {
            const winSelectorEl = e.target.children[1]
            document.querySelector("#bigView").style.display = winSelectorEl.style.display = "none"
        },
        moveInThumb(e) {
            if (e.target.getAttribute("name") != "winSelector") return;
            this.setWinSelectorPosition(e)
        },
        isVideo(path) {
            if (!path) return;
            return path.split(".")[1] == "mp4"
        },
        playVideo() {
            this.playing = true
            this.videoSourcePath = this.goods.videoUrl
        },
        closeVideo() {
            this.playing = false
        },

        clickThumb(on) {
            this.thumbActive = on;
            this.closeVideo()
            this.thumbImg = this.goods.goodsAlbums[this.thumbActive].originalPath
        },
    },
    mounted() {
        this.loadFavorite()
    },
};
</script>

<style lang="scss" scoped>
.goods-thumb {
    position: relative;
}

.winSelector {
    top: 0;
    left: 0;
    display: none;
    position: absolute;
    cursor: crosshair;
    filter: alpha(opacity=15);
    -moz-opacity: 0.15;
    opacity: 0.15;
    background-color: #000;
    border: 1px solid #fff;
    width: 262px;
    height: 262px;
    z-index: 99;
}

#bigView {
    display: none;
    position: absolute;
    border: 1px solid #959595;
    overflow: hidden;
    z-index: 999;
    width: 499px;
    height: 524px;
    top: 0px;
    left: 415px;
    background: rgb(255, 255, 255);

    #bigViewImgDiv {
        position: absolute;
        text-align: center;

        img {
            max-width: 800px;
            height: 800px;
        }
    }
}

.video-start {
    width: 68px !important;
    height: 68px !important;
    background: url("@/assets/images/detail1.png") no-repeat;
    position: absolute;
    top: 166px;
    left: 166px;
    cursor: pointer;
    z-index: 999;

    &:hover {
        background-image: url("@/assets/images/detail3.png");
    }
}

.player {
    // display: none;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 999;

    .close-btn {
        position: absolute;
        top: 10px;
        right: 5px;
        width: 36px;
        height: 36px;
        cursor: pointer;
        background: url("@/assets/images/J_stopVideo.png") no-repeat;
    }
}

.goods-info-box .goods-box .ginfo-box .left-box .swiper-thumbs .swiper-slide {
    width: 51px;
    height: 51px;
}

.swiper-gallery {
    img {
        width: 100%;
        height: 100%;
    }
}
.object-fill{
    object-fit: fill;
}
</style>