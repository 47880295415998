<template>
  <div class="store">
    <div class="boxLf-sj">
      <div class="shopInf">
        <div class="brandInf">
          <div class="badge"></div>
          <div class="badge-name">
            <div class="nameTop">
              <h2>
                <router-link
                  target="_blank"
                  :to="'/shop/store/index/' + store.id"
                  >{{ store.title }}</router-link
                >
              </h2>
              <span class="years">{{ live }}年店</span>
            </div>
            <div class="address">{{ store.address }}</div>
          </div>
        </div>
        <div class="medal" v-if="store.isSell == 1">
          <span class="medalCom dai" title="支持官方代发">代</span>
        </div>
      </div>
      <div class="shopMain_meg">
        <div class="main_megList">
          <div class="shopMegRow">
            店铺地址：
            <span>{{ store.address }}</span>
          </div>
          <div class="shopMegRow">
            目前有货：<span
              ><i>{{ store.shopCount }}</i
              >款</span
            >
          </div>
          <div class="shopMegRow">
            联系电话：<span id="contactBossMobile1_68">{{ phone }}</span>
          </div>
        </div>
        <div class="megRight">
          <div class="shopMegRow">
            主打产品：<span :title="store.mainProduct">{{
              store.mainProduct
            }}</span>
          </div>
          <div class="shopMegRow">
            QQ：<span id="contactQQ_68">{{ qq }}</span>
          </div>
          <div class="shopMegRow">
            微信：<span id="contactWX_68">{{ weixin }}</span>
          </div>
        </div>
      </div>
      <!-- <div class="allNum">
        <ul>
          <li class="numData1">
            <p>总热度</p>
            <p>
              <i class="sortI1 ml-0">{{ store.totaHeat }}</i>
            </p>
          </li>
          <li class="numData2">
            <p>
              退货率<i class="reduceP2">{{ store.returnRate }}</i>
            </p>
            <p>
              好于<i class="sortI2 ml-0">{{ store.preReturnRate }}</i
              >的同行
            </p>
          </li>
          <li class="sortLi3">
            <p>
              缺货率<i class="reduceP2">{{ store.outRate }}</i>
            </p>
            <p>
              好于<i class="sortI2 ml-0">{{ store.preOutRate }}</i
              >的同行
            </p>
          </li>
        </ul>
      </div> -->
      <div class="detail-btn">
        <router-link
          target="_blank"
          :to="'/shop/store/index/' + store.id"
          class="enterInto"
          >进入店铺</router-link
        >
        <a
          href="javascript:;"
          rel="nofollow"
          brandid="68"
          class="collectionBrand Brand_Collect_Button"
          collectid="68"
          @click="collect"
          >{{ isMerchantFocus ? "取消关注" : "收藏店铺" }}</a
        >
        <a
          href="javascript:;"
          rel="nofollow"
          brandid="68"
          class="collectionBrand Brand_Collect_Button"
          @click="showConcat"
          >查看联系方式</a
        >
      </div>
    </div>
    <div class="boxRg-shig">
      <div class="wrapper">
        <div class="swiper">
          <div class="swiper-wrapper">
            <div
              class="swiper-slide"
              v-for="(item, i) in store.shopGoods"
              :key="i"
            >
              <div class="item">
                <div class="slide-fl">
                  <div class="listImg">
                    <router-link
                      target="_blank"
                      :to="'/shop/goods/show/' + item.id"
                    >
                      <img
                        :src="common.loadFile(item.imgUrl)"
                        :title="item.title"
                        :alt="item.zhaiyao"
                      />
                    </router-link>
                  </div>
                  <p class="shopName">
                    {{ store.title + " " + item.title }}
                  </p>
                  <div class="mey-eyes">
                    <p>￥{{ item.sellPrice }}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <template v-if="store.shopGoods.length > 4">
            <div class="swiper-button-prev"></div>
            <div class="swiper-button-next"></div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Swiper from "swiper/swiper-bundle.min.js";
import AxiosAjax from "@/utils/axios.config";

export default {
  name: "AATShopStore",
  props: {
    store: Object,
  },
  data() {
    return {
      qq: this.store.qq
        ? this.store.qq[0] + "****" + this.store.qq[this.store.qq.length - 1]
        : "_****",
      weixin: this.store.weixin
        ? this.store.weixin[0] +
          "****" +
          this.store.weixin[this.store.weixin.length - 1]
        : "_****",
      phone: this.store.mobile
        ? this.store.mobile[0] +
          "****" +
          this.store.mobile[this.store.mobile.length - 1]
        : "_****",
      isMerchantFocus: false,
    };
  },
  computed: {
    live() {
      return (
        new Date().getFullYear() - new Date(this.store.addTime).getFullYear()
      );
    },
  },
  methods: {
    initData() {
      const _this = this;
      //加载店铺收藏信息
      AxiosAjax({
        url: `/account/merchant/favorite/${this.store.id}`,
        success() {
          _this.isMerchantFocus = true;
        },
      });
    },
    async showConcat() {
      await AxiosAjax({ url: `/account/member` });
      this.qq = this.store.qq;
      this.weixin = this.store.weixin;
      this.phone = this.store.mobile;
    },
    collect() {
      const _this = this;
      if (this.isMerchantFocus) {
        AxiosAjax({
          url: `/account/merchant/favorite/${_this.store.id}`,
          method: "delete",
          successMsg: "取消关注成功",
          success: function () {
            _this.isMerchantFocus = false;
          },
        });
      } else {
        AxiosAjax({
          url: `/account/merchant/favorite/${_this.store.id}`,
          method: "post",
          successMsg: "店铺关注成功",
          success() {
            _this.isMerchantFocus = true;
          },
        });
      }
    },
    
  },
  mounted() {
    new Swiper(".swiper", {
      slidesPerView: 4,
      observer: true, //检测变化
      // 如果需要前进后退按钮
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
    });
  },
  created() {
    this.initData();
  },
};
</script>

<style lang="scss" scoped>
.store {
  overflow: hidden;
  margin-bottom: 12px;
  background: #fff;
  padding: 20px 0 20px 20px;
  border: 1px solid #e4e4e4;
  font-size: 12px;
  line-height: 150%;
  font-family: "Microsoft YaHei";

  .boxLf-sj {
    float: left;
    width: 388px;

    .shopInf {
      height: 38px;
      padding-bottom: 9px;
      border-bottom: 1px solid #e4e4e4;
      box-sizing: content-box;
    }

    .badge {
      float: left;
    }

    .badge-name {
      float: left;
      margin-left: 5px;
      margin-top: 3px;
      width: 225px;
      position: relative;
    }

    .nameTop {
      h2 {
        display: inline-block;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        max-width: 170px;
        vertical-align: top;

        a {
          color: #3c3c3c;
          font-size: 18px;
          font-weight: bold;
        }
      }

      .years {
        width: 40px;
        height: 16px;
        border: 1px solid #e3c71c;
        display: inline-block;
        color: #783400;
        text-align: center;
        line-height: 16px;
        border-radius: 1px;
        background: #fbde2d;
        margin-left: 1px;
      }
    }

    .brandInf {
      float: left;
      .address {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    .medal {
      float: right;
      margin-top: 14px;
    }

    .shopErWm {
      float: left;
      height: 15px;
      margin-left: 13px;
      border-left: 1px solid #e6e6e6;
      margin-top: 18px;
      position: relative;
      background: url(https://img.rongqu.net/images/def-code.png) no-repeat 12px
        0px;
      width: 28px;
      cursor: pointer;

      .showErm {
        position: absolute;
        top: 23px;
        left: -66px;
        display: none;
        width: 170px;
        height: 200px;
        background: url(https://img.rongqu.net/imgs/ewm_shop.png?v=02) no-repeat;

        img {
          height: 160px;
          width: 160px;
          margin-top: 12px;
          margin-left: 5px;
        }
      }
    }

    .shopMain_meg {
      overflow: hidden;
      padding-top: 9px;
      padding-bottom: 14px;
      border-bottom: 1px solid #e4e4e4;

      .main_megList {
        float: left;
        color: #979797;
        width: 227px;
      }

      a {
        color: #707070;
      }

      span {
        color: #707070;
      }

      i {
        color: #e84446;
      }

      .shopMegRow {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        margin-bottom: 9px;
      }

      .megRight {
        float: left;
        width: 145px;
        color: #979797;
        padding-left: 15px;
        height: 73px;
        border-left: 1px dashed #e4e4e4;
      }
    }

    .allNum {
      overflow: hidden;

      li {
        float: left;
        margin-top: 9px;
        border-right: 1px solid #e9e9e9;
        text-align: center;

        p {
          color: #979797;
          margin-bottom: 5px;
        }
      }

      .sortI1 {
        color: #f65405;
      }

      .ml-0 {
        margin-left: 0 !important;
      }

      .numData1 {
        text-align: left;
        width: 140px;
      }

      .numData2 {
        width: 122px;
      }

      .sortI2 {
        background-color: #ec3e36;
        width: 33px;
        height: 15px;
        line-height: 15px;
        background-repeat: no-repeat;
        background-position: 30px 2px;
        text-align: center;
        color: #fff;
        border-radius: 2px;
      }

      .sortLi3 {
        border-right: none;
        width: 122px;
      }
    }

    .detail-btn {
      margin-top: 13px;

      a {
        display: inline-block;
        width: 110px;
        height: 33px;
        line-height: 33px;
        text-align: center;
        margin-right: 14px;
        color: #999999;
        border: 1px solid #ededed;

        &:hover {
          background-color: #fafafa;
        }
      }

      .enterInto {
        color: #e84446;
        border: 1px solid #e84446;

        &:hover {
          background: #e84446;
          color: #fff;
        }
      }
    }
  }

  .boxRg-shig {
    float: left;
    margin-left: 22px;
    overflow: hidden;

    .wrapper {
      width: 744px;
      height: 235px;

      .item {
        width: 158px;
        height: 209px;
        padding: 12px 14px 12px 12px;
        border: 1px solid #e4e4e4;
        margin-right: -1px;
        box-sizing: content-box;

        .listImg {
          position: relative;

          img {
            width: 162px;
            height: 161px;
          }
        }

        .shopName {
          margin-top: 8px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;

          a {
            color: #636363;
          }
        }

        .mey-eyes {
          margin-top: 7px;
          overflow: hidden;

          p {
            float: left;
            color: #df454e;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }
      }
    }
  }

  .dai {
    background: #48ae77;
    color: #fff;
  }

  .medalCom {
    width: 20px;
    height: 19px;
    line-height: 19px;
    border-radius: 2px;
    display: inline-block;
    vertical-align: middle;
    text-align: center;
  }

  .reduceP2 {
    color: #2ea067 !important;
  }
}
</style>
