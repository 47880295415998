<template>
    <div class="wrapper">
        <div class="account-box">


            <AccountNav :account="account" model="balance" />

            <div class="right-box">
                <div class="tab-box">
                    <div class="menu-box">
                        <router-link to="/account/balance/list" class="item-box">
                            <span>账户余额</span>
                        </router-link>
                        <router-link to="/account/recharge/list" class="item-box active">
                            <span>充值记录</span>
                        </router-link>
                    </div>
                    <div class="btn-box">
                        <el-link :underline="false" icon="el-icon-circle-plus-outline"
                            href="/account/recharge/add">充值</el-link>
                        <el-link type="danger" :underline="false" icon="el-icon-delete" @click="deleteCheckAll">删除</el-link>
                    </div>
                </div>
                <div class="content">
                    <div v-if="listData.length === 0" class="nodata">
                        <span class="icon iconfont icon-msg"></span>
                        <span class="text">暂无记录</span>
                    </div>
                    <el-table v-else ref="multipleTable" :data="listData" stripe :show-header="true" class="table-list"
                        @selection-change="handleSelectionChange">
                        <el-table-column type="selection" width="45" :selectable="selectable"></el-table-column>
                        <el-table-column label="备注" min-width="150">
                            <template slot-scope="scope">
                                <h4>
                                    {{ scope.row.rechargeNo }}
                                </h4>
                                <span class="date">
                                    [{{ scope.row.paymentTitle }}]
                                    <i class="el-icon-time"></i>
                                    {{ scope.row.addTime }}
                                </span>
                            </template>
                        </el-table-column>
                        <el-table-column label="金额" width="100">
                            <template slot-scope="scope">
                                <span class="price">{{ scope.row.amount }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column label="状态" width="100">
                            <template slot-scope="scope">
                                <el-tag v-if="scope.row.status == 1" type="warning" size="small">待付款</el-tag>
                                <el-tag v-else-if="scope.row.status == 2" type="success" size="small">已完成</el-tag>
                                <el-tag v-else type="info" size="small">已取消</el-tag>
                            </template>
                        </el-table-column>
                        <el-table-column label="操作" align="center" width="160">
                            <template slot-scope="scope">
                                <el-button size="mini" type="success" :disabled="scope.row.status == 2"
                                    @click="common.linkUrl('/payment/confirm?no=' + scope.row.tradeNo)">去支付</el-button>
                                <el-button size="mini" type="danger" icon="el-icon-delete" @click="deleteItem(scope.row.id)"
                                    :disabled="scope.row.status == 2"></el-button>
                            </template>
                        </el-table-column>
                    </el-table>

                    <div class="pager-box">
                        <el-pagination background :hide-on-single-page="true" @current-change="handleCurrentChange"
                            :page-size="pageSize" layout="prev, pager, next" :total="totalCount">
                        </el-pagination>
                    </div>

                </div>
            </div>
        </div>
    </div>
</template>

<script>
import AxiosAjax from "@/utils/axios.config";
import AccountNav from "@/components/AccountNav.vue";

export default {
    name: 'AATShopRechargeList',
    components: {
        AccountNav
    },
    data() {
        return {
            account: null, //会员信息
            totalCount: 0, //总数量
            pageSize: 10, //每页显示条数
            pageIndex: 1, //当前页码
            listData: [],//数据列表
        };
    },
    methods: {
        //加载列表
        async loadData() {
            let _this = this;
            AxiosAjax({
                url: `/account/member/recharge?pageSize=${_this.pageSize}&pageIndex=${_this.pageIndex}`,
                loading: true,
                success(res) {
                    _this.listData = res.data;
                    let pageInfo = JSON.parse(res.headers["x-pagination"]);
                    _this.totalCount = pageInfo.totalCount;
                    _this.pageSize = pageInfo.pageSize;
                    _this.pageIndex = pageInfo.pageIndex;
                },
                error: function () {
                    _this.listData = []
                }
            });
        },
        //初始化数据
        async initData() {
            let _this = this;
            //加载列表
            await _this.loadData();
            //加载会员信息
            await AxiosAjax({
                url: `/account/member`,
                success(res) {
                    _this.account = res.data;
                }
            });
        },
        //跳转到第几页
        handleCurrentChange: function (val) {
            if (this.pageIndex != val) {
                this.pageIndex = val;
                this.loadData();
            }
        },
        //多选删除
        deleteCheckAll() {
            let _this = this; //当前页面
            //拿到选中的数据
            let list = _this.multipleSelection;
            //检查是否有选中
            if (!list) {
                _this.$message({ type: 'warning', message: '请选择要删除的记录！' });
                return false;
            }
            //执行删除操作
            _this.$confirm('确认要删除该记录吗？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(function () {
                let delIds = [];//创建一个数组
                list.forEach((item) => {
                    //遍历源数据
                    _this.listData.forEach((v) => {
                        if (item.id == v.id) {
                            delIds.push(item.id);
                        }
                    })
                });
                AxiosAjax({
                    method: 'delete',
                    url: '/account/member/recharge?ids=' + delIds.toString(),
                    loading: true,
                    successMsg: '已删除成功',
                    success() {
                        _this.pageIndex = 1;
                        _this.totalCount = 0;
                        _this.loadData(); //重新加载列表
                    }
                })
            }).catch(function () { });
        },
        //单项删除
        deleteItem(id) {
            let _this = this;
            _this.$confirm('确认要删除该记录吗？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                AxiosAjax({
                    method: "delete",
                    url: `/account/member/recharge/${id}`,
                    loading: true,
                    successMsg: '已删除成功',
                    success() {
                        _this.pageIndex = 1;
                        _this.totalCount = 0;
                        _this.loadData();
                    }
                });
            }).catch(() => { });
        },
        //选中第几行
        handleSelectionChange(val) {
            this.multipleSelection = val;
        },
        //列是否可选
        selectable(row) {
            if (row.status === 1) {
                return false;
            }
            return true;
        }
    },
    created() {
        this.initData()
    }
};
</script>

<style lang="scss" scoped></style>