<template>
    <div class="wrapper">
        <div class="account-box">
            <AccountNav :account="account" model="favorite"/>
            <div class="right-box">
                <div class="tab-box">
                    <div class="menu-box">
                        <div class="item-box active">
                            <span>我的收藏</span>
                        </div>
                    </div>
                    <div class="btn-box">
                        <el-link type="danger" :underline="false" icon="el-icon-delete"
                            @click="deleteCheckAll">删除</el-link>
                    </div>
                </div>
                <div class="content">
                    <el-table ref="multipleTable" :data="listData" stripe :show-header="true" class="table-list"
                        @selection-change="handleSelectionChange">
                        <el-table-column type="selection" width="45"></el-table-column>
                        <el-table-column label="商品信息" min-width="150">
                            <template slot-scope="scope">
                                <router-link :to="'/shop/goods/show/' + scope.row.goodsId" class="img-list">
                                    <div class="img-box">
                                        <img :src="common.loadFile(scope.row.imgUrl)">
                                    </div>
                                    <div class="txt-box">
                                        <h4>{{ scope.row.title }}</h4>
                                        <span class="date">
                                            <i class="el-icon-time"></i>
                                            {{ scope.row.addTime }}
                                        </span>
                                    </div>
                                </router-link>
                            </template>
                        </el-table-column>
                        <el-table-column label="操作" align="center" width="110">
                            <template slot-scope="scope">
                                <el-button size="mini" type="danger" icon="el-icon-delete"
                                    @click="deleteItem(scope.row.id)"></el-button>
                            </template>
                        </el-table-column>
                    </el-table>

                    <div class="pager-box">
                        <el-pagination background :hide-on-single-page="true" @current-change="handleCurrentChange"
                            :page-size="pageSize" layout="prev, pager, next" :total="totalCount">
                        </el-pagination>
                    </div>

                </div>
            </div>
        </div>
    </div>
</template>

<script>
import AxiosAjax from "@/utils/axios.config";
import AccountNav from "@/components/AccountNav.vue";
export default {
    name: 'AATShopFavoriteList',
    components: {
        AccountNav
    },
    data() {
        return {
            account: null, //会员信息
            totalCount: 0, //总数量
            pageSize: 10, //每页显示条数
            pageIndex: 1, //当前页码
            listData: [],
        };
    },
    methods: {
        //加载列表
        async loadData() {
            let _this = this;
            AxiosAjax({
                url: `/account/member/favorite?pageSize=${_this.pageSize}&pageIndex=${_this.pageIndex}`,
                loading: true,
                success: function (res) {
                    _this.listData = res.data;
                    let pageInfo = JSON.parse(res.headers["x-pagination"]);
                    _this.totalCount = pageInfo.totalCount;
                    _this.pageSize = pageInfo.pageSize;
                    _this.pageIndex = pageInfo.pageIndex;
                },
                error: function () {
                    _this.listData = []
                }
            });
        },
        //初始化数据
        async initData() {
            let _this = this;
            //加载列表
            await _this.loadData();
            //加载会员信息
            await AxiosAjax({
                url: `/account/member`,
                success: function (res) {
                    _this.account = res.data;
                }
            });
        },
        //跳转到第几页
        handleCurrentChange(val) {
            if (this.pageIndex != val) {
                this.pageIndex = val;
                this.loadData();
            }
        },
        //多选删除
        deleteCheckAll() {
            let _this = this; //当前页面
            //拿到选中的数据
            let list = _this.multipleSelection;
            //检查是否有选中
            if (!list) {
                _this.$message({ type: 'warning', message: '请选择要删除的记录！' });
                return false;
            }
            //执行删除操作
            _this.$confirm('确认要删除该记录吗？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(function () {
                let delIds = [];//创建一个数组
                list.forEach((item) => {
                    //遍历源数据
                    _this.listData.forEach((v) => {
                        if (item.id == v.id) {
                            delIds.push(item.id);
                        }
                    })
                });
                AxiosAjax({
                    method: 'delete',
                    url: '/account/member/favorite?ids=' + delIds.toString(),
                    loading: true,
                    successMsg: '已删除成功',
                    success: function () {
                        _this.pageIndex = 1;
                        _this.totalCount = 0;
                        _this.loadData(); //重新加载列表
                    }
                })
            }).catch(function () { });
        },
        //单项删除
        deleteItem(id) {
            let _this = this;
            _this.$confirm('确认要删除该记录吗？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                AxiosAjax({
                    method: "delete",
                    url: `/account/member/favorite/${id}`,
                    loading: true,
                    successMsg: '已删除成功',
                    success: function () {
                        _this.pageIndex = 1;
                        _this.totalCount = 0;
                        _this.loadData();
                    }
                });
            }).catch(() => { });
        },
        //选中第几行
        handleSelectionChange(val) {
            this.multipleSelection = val;
        },
    },
    created() {
        this.initData()
    }
};
</script>

<style lang="scss" scoped></style>