<template>
  <div>
    <div class="img-box">
      <el-image :src="common.loadFile(imgUrl)"></el-image>
    </div>
    <div class="title" v-if="title">
      <span>{{ title }}</span>
    </div>
    <div class="goods-name" v-if="goodsName">
      {{ goodsName }}
    </div>
    <div class="price">
      <span>¥</span>{{ sellPrice }}
      <span class="public-time">{{ publicTime }}</span>
    </div>
    <div class="tag">
      <div class="icons">
        <span class="medalCom dai" v-for="(icon, i) in icons" :key="i">{{
          icon.name
        }}</span>
        <!-- <span class="medalCom jing">精</span>
                <span class="medalCom chang">厂</span>
                <span class="medalCom ban">版</span>
                <span class="medalComs jinpai">金牌</span>
                <span class="recommend">推广</span> 
        <span class="medalComs protect">处于保护期</span>-->
      </div>
      <router-link to="#" class="store-name" v-if="storeName">
        <div>{{ storeName }}</div>
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: "AATShopGoods",
  props: {
    imgUrl: String,
    title: String,
    goodsName: String,
    sellPrice: Number,
    publicTime: String,
    icons: Array,
    storeName: String,
  },
  data() {
    return {};
  },

  mounted() {},

  methods: {},
};
</script>

<style lang="scss" scoped>
.goods-list .list-box .img-box {
  width: 222px;
  height: 222px;
}
.goods-list .list-box .title,
.goods-list .list-box .price {
  padding-left: 14px;
  padding-right: 14px;
}
.goods-list .list-box .price {
  margin-top: 5px;
  font-size: 18px;
}
.protect {
  width: 66px;
  background-color: #8f43d6;
  color: #fff;
}
.tag {
  padding: 0 14px 14px;
}
</style>
