<template>
    <div class="wrapper">
        <div class="account-box">
            
            <AccountNav :account="account" model="order"/>

            <div class="right-box">
                <div class="tab-box">
                    <div class="menu-box">
                        <div class="item-box">
                            <span>查看物流</span>
                        </div>
                    </div>
                    <div class="btn-box">
                        <el-link :underline="false" icon="el-icon-back" @click="common.back(-1)">返回上一页</el-link>
                    </div>
                </div>
                <div class="content">

                    <div class="order-list">
                        <!--循环.开始-->
                        <div v-for="(item, index) in listData" :key="index" class="list-box">
                            <div class="head-box">
                                <span class="time">{{ item.orderNo }}</span>
                                <span class="state">{{ item.express.title }} ({{ item.expressCode }})</span>
                            </div>
                            <div v-for="(gitem, gindex) in item.deliveryGoods" :key="gindex" class="item-box">
                                <div @click="common.linkUrl('/shop/goods/show/' + gitem.orderGoods.goodsId)"
                                    class="img-box">
                                    <img :src="common.loadFile(gitem.orderGoods.imgUrl)">
                                </div>
                                <div class="txt-box">
                                    <span class="text">{{ gitem.orderGoods.goodsTitle }}</span>
                                    <span class="info">{{ gitem.orderGoods.skuText }} x {{ gitem.orderGoods.quantity
                                    }}</span>
                                    <span class="price">{{ gitem.orderGoods.realPrice }}</span>
                                </div>
                            </div>
                            <div class="express-box">
                                <div v-if="item.options && item.options.length === 0" @click="loadExpress(item)"
                                    class="more-box">
                                    <span class="text">显示物流信息</span>
                                    <span class="icon iconfont icon-left-arrow"></span>
                                </div>
                                <el-timeline>
                                    <el-timeline-item v-for="(option, index) in item.options" :key="index"
                                        :timestamp="option.desc">
                                        {{ option.title }}
                                    </el-timeline-item>
                                </el-timeline>
                            </div>
                        </div>
                        <!--循环.结束-->
                    </div>

                </div>
            </div>
        </div>
    </div>
</template>

<script>
import AxiosAjax from "@/utils/axios.config";
import AccountNav from "@/components/AccountNav.vue";
export default {
    name: 'AATShopOrderDelivery',
    components: {
        AccountNav
    },
    data() {
        return {
            account: {}, //账户信息
            listData: [],
        };
    },
    methods: {
        //初始化数据
        async initData(orderNo) {
            let _this = this;
            //加载配送信息
            await AxiosAjax({
                url: `/account/order/delivery/${orderNo}`,
                success(res) {
                    _this.listData = res.data;
                }
            });
            _this.listData.forEach(item => {
                _this.$set(item, 'options', []);
            });
            //获取会员信息
            await AxiosAjax({
                url: `/account/member`,
                success(res) {
                    _this.account = res.data;
                }
            });
        },
        //加载物流信息
        async loadExpress(item) {
            await AxiosAjax({
                url: `/account/order/delivery/express/${item.id}`,
                success(res) {
                    if (res.data.message != "ok") {
                        item.options.push({
                            title: "物流查询出错，请联系管理员"
                        })
                        return;
                    }
                    //增加物流列表
                    res.data.data.forEach(val => {
                        item.options.push({
                            title: val.context,
                            desc: val.time
                        })
                    });
                }
            });
        }
    },
    created() {
        let orderNo = this.common.queryValue("no");
        this.initData(orderNo);
    }
};
</script>

<style lang="scss" scoped></style>