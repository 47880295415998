<template>
  <div class="wrapper">
    <div class="account-box">
      <AccountNav :account="account" model="cargo" />

      <div class="right-box">
        <div class="tab-box">
          <div class="menu-box menu-box-header">
            <div class="item-box active">
              <span>我的配货清单</span>
            </div>
            <el-button type="primary" @click="downloadExcelHandler"
              >导出为Excel</el-button
            >
          </div>
        </div>
        <div class="content">
          <div v-if="listData.length === 0" class="nodata">
            <span class="icon iconfont icon-msg"></span>
            <span class="text">暂无记录</span>
          </div>
          <el-table :data="tableData" stripe v-else>
            <el-table-column prop="order.addTime" label="日期" width="200">
            </el-table-column>
            <el-table-column
              prop="order.merchantTitle"
              label="店铺名称"
              width="200"
            >
            </el-table-column>
            <el-table-column
              prop="order.merchantAddress"
              label="店铺地址"
              width="350"
            >
            </el-table-column>
            <el-table-column label="产品信息">
              <el-table-column prop="goodsTitle" label="产品名称" width="200">
              </el-table-column>
              <el-table-column prop="size" label="尺寸" width="120">
              </el-table-column>
              <el-table-column prop="color" label="颜色" width="120">
              </el-table-column>
            </el-table-column>
            <el-table-column prop="quantity" label="产品数量" width="120">
            </el-table-column>
            <el-table-column prop="goodsPrice" label="单价" width="120">
            </el-table-column>
            <el-table-column fixed="right" label="操作" width="230">
              <template slot-scope="scope">
                <div class="btn-box">
                  <el-button
                    type="primary"
                    @click="
                      common.linkUrl('/account/order/show/' + scope.row.orderId)
                    "
                    >查看订单</el-button
                  >
                  <el-button type="danger" @click="deleteGood(scope.row.id)"
                    >删除商品</el-button
                  >
                  <!-- <el-button
                    v-if="
                      scope.row.order.status < 3 &&
                      scope.row.order.paymentStatus == 0
                    "
                    @click="cancelOrder(scope.row.order)"
                    type="warning"
                    >取消订单</el-button
                  > -->
                </div>
              </template>
            </el-table-column>
          </el-table>
          <div class="pager-box">
            <el-pagination
              background
              :hide-on-single-page="true"
              @current-change="handleCurrentChange"
              :page-size="pageSize"
              layout="prev, pager, next"
              :total="totalCount"
            >
            </el-pagination>
          </div>
        </div>
      </div>
    </div>
    <el-dialog title="确定删除？" :visible.sync="dialogVisible">
      <span>确定删除该商品吗？</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="confirmDeleteGood">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import AxiosAjax from "@/utils/axios.config";
import AccountNav from "@/components/AccountNav.vue";
import ExcelJS from "exceljs";
import FileSaver from "file-saver";
export default {
  name: "AATShopOrderList",
  components: {
    AccountNav,
  },
  data() {
    return {
      status: 0, //订单类型
      totalCount: 0, //总数量
      pageSize: 10, //每页显示条数
      pageIndex: 1, //当前页码
      listData: [], //数据列表
      account: null, //会员信息,
      publicPath: process.env.BASE_URL,
      tableData: [],
      dialogVisible: false,
      deleteId: "",
    };
  },

  methods: {
    //加载列表
    async loadData() {
      let _this = this;
      AxiosAjax({
        url: `/account/order?pageSize=${_this.pageSize}&pageIndex=${_this.pageIndex}&status=${_this.status}&ordertype=4`,
        loading: true,
        success(res) {
          _this.listData = res.data;
          let pageInfo = JSON.parse(res.headers["x-pagination"]);
          _this.totalCount = pageInfo.totalCount;
          _this.pageSize = pageInfo.pageSize;
          _this.pageIndex = pageInfo.pageIndex;
          //循环查找收款信息赋值
          _this.listData.forEach((item) => {
            //判断是否预售
            if (item.orderType === 2) {
              //检查预付款是否已付
              let collectionType = 2; //默认查找尾款
              if (item.paymentStatus === 0) {
                collectionType = 1;
              }
              let modelt = item.collectionRelations.find(
                (val) => val.collection.collectionType == collectionType
              );
              if (modelt) {
                let collection = modelt.collection;
                let tradeStatus = _this.checkDate(
                  collection.startTime,
                  collection.endTime
                );
                _this.$set(item, "tradeNo", collection.tradeNo);
                _this.$set(item, "tradeStatus", tradeStatus);
              }
            } else {
              let collection = item.collectionRelations[0].collection;
              let tradeStatus = _this.checkDate(
                collection.startTime,
                collection.endTime
              );
              _this.$set(item, "tradeNo", collection.tradeNo);
              _this.$set(item, "tradeStatus", tradeStatus);
            }
          });

          for (let i = 0; i < _this.listData.length; i++) {
            const { orderGoods } = _this.listData[i];
            for (let j = 0; j < orderGoods.length; j++) {
              const item = orderGoods[j];
              item.order = _this.listData[i];
              [item.color, item.size] = item.skuText.split(",");
              _this.tableData.push(item);
            }
          }
        },
        error() {
          _this.listData = [];
        },
      });
    },
    //初始化数据
    async initData() {
      let _this = this;
      this.tableData = [];
      //加载列表
      await _this.loadData();
      //加载会员信息
      await AxiosAjax({
        url: `/account/member`,
        success(res) {
          _this.account = res.data;
        },
      });
    },
    //跳转到第几页
    handleCurrentChange: function (val) {
      if (this.pageIndex != val) {
        this.pageIndex = val;
        this.loadData();
      }
    },
    //订单状态
    orderStateExp(item) {
      let stateTip = "已下单";
      if (item.status === 1) {
        stateTip = "待付款";
        //如果是预售订单
        if (item.orderType == 2) {
          if (item.paymentStatus == 1) {
            stateTip = "待付尾款";
          }
          if (item.paymentStatus == 1 && item.tradeStatus == 2) {
            stateTip = "活动已失效";
          }
        }
      } else if (item.status === 2) {
        stateTip = "待发货";
      } else if (item.status === 3) {
        stateTip = "待收货";
      } else if (item.status === 4) {
        stateTip = "已签收";
      } else if (item.status === 5) {
        stateTip = "交易成功";
        if (item.orderGoods.find((val) => val.evaluateStatus === 0)) {
          stateTip = "待评价";
        }
      } else if (item.status === 6) {
        stateTip = "交易取消";
      } else if (item.status === 7) {
        stateTip = "交易作废";
      }
      return stateTip;
    },
    //取消订单
    cancelOrder(item) {
      let _this = this;
      _this
        .$confirm("确认要取消订单吗？", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
        .then(() => {
          AxiosAjax({
            method: "put",
            url: `/account/order/cancel/${item.id}`,
            loading: true,
            successMsg: "订单已取消",
            success: function () {
              item.status = 6; //更改订单状态
            },
          });
        })
        .catch(() => {});
    },

    //返回时间对比状态
    checkDate(beginDateStr, endDateStr) {
      var curDate = new Date(),
        beginDate = new Date(beginDateStr),
        endDate = new Date(endDateStr);
      if (curDate < beginDate) {
        return 1;
      }
      if (curDate > endDate) {
        return 2;
      }
      return 0;
    },
    async downloadExcelHandler() {
      try {
        const workbook = new ExcelJS.Workbook();
        workbook.creator = this.account.userName;
        workbook.created = new Date();
        const worksheet = workbook.addWorksheet("配货清单" + this.pageIndex);
        // 配置表头
        worksheet.columns = [
          {
            header: "日期",
            key: "date",
            width: 20,
          },
          {
            header: "店铺名称",
            key: "storeName",
            width: 20,
          },
          {
            header: "店铺地址",
            key: "address",
            width: 35,
          },
          {
            header: "产品名称",
            key: "goodsName",
            width: 20,
          },
          {
            header: "尺寸",
            key: "goodsSize",
            width: 12,
          },
          {
            header: "颜色",
            key: "goodsColor",
            width: 12,
          },
          {
            header: "产品数量",
            key: "count",
            width: 12,
          },
          {
            header: "单价",
            key: "price",
            width: 12,
          },
        ];

        // 添加行数据
        this.tableData.forEach((item) => {
          worksheet.addRow({
            date: item.order.addTime,
            storeName: item.order.merchantTitle,
            address: item.order.merchantAddress,
            goodsName: item.goodsTitle,
            goodsSize: item.size,
            goodsColor: item.color,
            count: item.quantity,
            price: item.goodsPrice,
          });
        });
        const buffer = await workbook.xlsx.writeBuffer();
        let file = new Blob([buffer], { type: "application/octet-stream" });
        FileSaver.saveAs(file, "配货清单" + this.pageIndex + ".xlsx");
      } catch (e) {
        console.log(e);
      }
    },
    deleteGood(id) {
      this.deleteId = id;
      this.dialogVisible = true;
    },
    confirmDeleteGood() {
      const _this = this;
      this.dialogVisible = false;
      AxiosAjax({
        url: `/account/order?id=${_this.deleteId}`,
        method: "delete",
        loading: true,
        success() {
          _this.initData();
        },
      });
    },
  },

  created() {
    let status = parseInt(this.common.queryValue("status"));
    this.status = status ? status : 0;
    this.initData();
  },
  beforeRouteUpdate(to, _, next) {
    if (to.name == "OrderList") {
      let status = parseInt(this.common.queryValue("status"));
      this.status = status ? status : 0;
      this.initData();
    }
    next();
  },
};
</script>

<style lang="scss" scoped>
.menu-box-header {
  width: 100%;
  display: flex;
  justify-content: space-between !important;
  align-items: center;
}
.menu-box-header-right {
  display: flex;
  gap: 10px;
}
</style>
