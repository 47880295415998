<template>
    <div class="login-box">
        <div class="wrap-box">
            <div class="left-box">
                <div class="tab-box">
                    <span @click="toggleMethod(1, 'phoneForm')" class="text"
                        :class="{ active: findMethod === 1 }">手机找回</span>
                    <span @click="toggleMethod(2, 'emailForm')" class="text"
                        :class="{ active: findMethod === 2 }">邮箱找回</span>
                </div>
                <!--手机找回.开始-->
                <el-form v-if="findMethod === 1" :model="phoneModel" :rules="rules" ref="phoneForm" @submit.native.prevent>
                    <el-form-item prop="phone">
                        <el-input type="text" v-model="phoneModel.phone" placeholder="请输入手机号码"
                            prefix-icon="el-icon-user"></el-input>
                    </el-form-item>
                    <el-form-item prop="codeValue">
                        <el-input type="text" v-model="phoneModel.codeValue" placeholder="请输入手机验证码"
                            prefix-icon="el-icon-key">
                            <template slot="append">
                                <el-button v-if="timer > 0">重新获取({{ timer }}s)</el-button>
                                <el-button v-else @click="sendPhoneCode">获取验证码</el-button>
                            </template>
                        </el-input>
                    </el-form-item>
                    <el-form-item prop="newPassword">
                        <el-input type="password" v-model="phoneModel.newPassword" placeholder="请输入新密码"
                            prefix-icon="el-icon-lock"></el-input>
                    </el-form-item>
                    <el-form-item prop="confirmPassword">
                        <el-input type="password" v-model="phoneModel.confirmPassword" placeholder="请再次输入新密码"
                            prefix-icon="el-icon-lock"></el-input>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" class="btn" native-type="submit" :loading="btnLoading"
                            :disabled="btnLoading" @click="submitForm('phoneForm')">确认修改</el-button>
                    </el-form-item>
                </el-form>
                <!--手机找回.结束-->
                <!--邮箱找回.开始-->
                <el-form v-if="findMethod === 2" :model="emailModel" :rules="rules" ref="emailForm" @submit.native.prevent>
                    <el-form-item prop="email">
                        <el-input type="text" v-model="emailModel.email" placeholder="请输入邮箱账户"
                            prefix-icon="el-icon-user"></el-input>
                    </el-form-item>
                    <el-form-item prop="codeValue">
                        <el-input type="text" v-model="emailModel.codeValue" placeholder="请输入邮箱验证码"
                            prefix-icon="el-icon-key">
                            <template slot="append">
                                <el-button v-if="timer > 0">重新获取({{ timer }}s)</el-button>
                                <el-button v-else @click="sendEmailCode">获取验证码</el-button>
                            </template>
                        </el-input>
                    </el-form-item>
                    <el-form-item prop="newPassword">
                        <el-input type="password" v-model="emailModel.newPassword" placeholder="请输入新密码"
                            prefix-icon="el-icon-lock"></el-input>
                    </el-form-item>
                    <el-form-item prop="confirmPassword">
                        <el-input type="password" v-model="emailModel.confirmPassword" placeholder="请再次输入新密码"
                            prefix-icon="el-icon-lock"></el-input>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" class="btn" native-type="submit" :loading="btnLoading"
                            :disabled="btnLoading" @click="submitForm('emailForm')">确认修改</el-button>
                    </el-form-item>
                </el-form>
                <!--邮箱找回.结束-->
                <div class="foot-box">
                    <span class="text">还没有账号？<router-link to="/account/register">马上注册</router-link></span>
                    <span class="text"><router-link to="/account/login">账户登录</router-link></span>
                </div>
            </div>

            <div class="right-box">
                <div class="head-box">
                    <span class="title">找回密码</span>
                    <span class="text">登录后即可享受更多的会员权益</span>
                </div>
                <div class="img-box">
                    <img src="@/assets/images/img-1.png">
                </div>
                <div class="foot-box">
                    <router-link to="/home" class="icon iconfont icon-home">返回首页</router-link>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import AxiosAjax from "@/utils/axios.config";
export default {
    name: 'AATShopResetPassword',

    data() {
        return {
            btnLoading: false,
            timer: 0, //计时器
            findMethod: 1, //1手机找回2邮箱找回
            phoneModel: {
                method: 1,
                phone: null,
                newPassword: null,
                confirmPassword: null,
                codeKey: null,
                codeValue: null
            },
            emailModel: {
                method: 2,
                email: null,
                newPassword: null,
                confirmPassword: null,
                codeKey: null,
                codeValue: null
            },
            rules: {
                email: [
                    { required: true, message: '请输入邮箱账户', trigger: 'blur' },
                    { pattern: /^(\w)+(\.\w+)*@@(\w)+((\.\w+)+)$/, message: '邮箱格式有误', trigger: 'blur' }
                ],
                phone: [
                    { required: true, message: '请输入手机号码', trigger: 'blur' },
                    { pattern: /^1[0-9]{10}$/, message: '手机号码格式不正确', trigger: 'blur' }
                ],
                codeValue: [
                    { required: true, message: '请输入验证码', trigger: 'blur' },
                    { lmin: 4, max: 8, message: '请输入 4 到 8 位的验证码', trigger: 'blur' }
                ],
                newPassword: [
                    { required: true, message: '请输入新密码', trigger: 'blur' },
                    { min: 6, max: 128, message: '密码长度在至少6位', trigger: 'blur' },
                    { pattern: /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,20}$/, message: '必须英文字母数字组合', trigger: 'blur' }
                ],
                confirmPassword: [
                    { required: true, message: '请输入确认密码', trigger: 'blur' },
                    { required: true, validator: this.validatePass, trigger: 'blur' }
                ]
            },
        };
    },
    methods: {
        //切换注册方式
        toggleMethod(type, formName) {
            this.findMethod = type;
            this.$nextTick(() => {
                this.$refs[formName].clearValidate();
            });
        },
        //发送手机验证码
        sendPhoneCode() {
            let _this = this;
            if (!/(^1[3|4|5|7|8][0-9]{9}$)/.test(_this.phoneModel.phone)) {
                _this.$message({
                    message: '提示，请输入正确的手机号码',
                    type: 'warning'
                });
                return;
            }
            //发送短信
            AxiosAjax({
                url: `/verifycode/mobile/${_this.phoneModel.phone}`,
                success: function (res) {
                    _this.timer = 120; //设置120秒
                    _this.countDown();//开始倒计时
                    _this.phoneModel.codeKey = res.data.codeKey;
                }
            });
        },
        //发送邮件验证码
        sendEmailCode() {
            let _this = this;
            if (!/(^[_a-z0-9-]+(\.[_a-z0-9-]+)*@@[a-z0-9-]+(\.[a-z0-9-]+)*(\.[a-z]{2,})$)/.test(_this.emailModel.email)) {
                _this.$message({
                    message: '提示，请输入正确的邮箱账户',
                    type: 'warning'
                });
                return;
            }
            //发送邮件
            AxiosAjax({
                url: `/verifycode/email/${_this.emailModel.email}`,
                success: function (res) {
                    _this.timer = 120; //设置120秒
                    _this.countDown();//开始倒计时
                    _this.emailModel.codeKey = res.data.codeKey;
                }
            });
        },
        //计时器
        countDown() {
            let _this = this;
            if (_this.timer > 0) {
                _this.timer--;
                setTimeout(function () {
                    _this.countDown();
                }, 1000)
            }
        },
        //提交表单
        submitForm(formName) {
            let _this = this;
            //表单验证
            _this.$refs[formName].validate((valid) => {
                if (valid) {
                    if (_this.findMethod === 1) {
                        _this.submitData(_this.phoneModel);

                    } else if (_this.findMethod === 2) {
                        _this.submitData(_this.emailModel);
                    }
                } else {
                    return false;
                }
            });
        },
        //请求API接口
        submitData(model) {
            let _this = this;
            AxiosAjax({
                url: `/auth/reset`,
                method: "post",
                data: model,
                beforeSend() {
                    _this.btnLoading = true;
                },
                complete() {
                    _this.btnLoading = false;
                },
                success: function () {
                    _this.$confirm('找回成功，是否登录会员账户？', '提示', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'success'
                    }).then(() => {
                        _this.common.linkUrl('/account/login');
                    }).catch(() => {
                        _this.common.linkUrl('/home');
                    });
                }
            });
        },
        validatePass: function (rule, value, callback) {
            let newPassword = this.phoneModel.newPassword;
            if (this.findMethod === 2) {
                newPassword = this.emailModel.newPassword;
            }
            if (value === '') {
                callback(new Error('请输入确认密码'));
            } else if (value !== newPassword) {
                callback(new Error('两次输入密码不一致'));
            } else {
                callback();
            }
        }
    },
};
</script>

<style lang="scss" scoped></style>