<template>
    <div class="wrapper">
        <div class="account-box">
            <AccountNav :account="account" model="balance" />
            <div class="right-box">
                <div class="tab-box">
                    <div class="menu-box">
                        <router-link to="/account/balance/list" class="item-box active">
                            <span>账户余额</span>
                        </router-link>
                        <router-link to="/account/recharge/list" class="item-box">
                            <span>充值记录</span>
                        </router-link>
                    </div>
                </div>
                <div class="content">
                    <div v-if="listData.length === 0" class="nodata">
                        <span class="icon iconfont icon-msg"></span>
                        <span class="text">暂无记录</span>
                    </div>
                    <el-table v-else ref="multipleTable" :data="listData" stripe :show-header="true" class="table-list">
                        <el-table-column prop="userName" label="用户名" width="120"></el-table-column>
                        <el-table-column label="备注" min-width="150">
                            <template slot-scope="scope">
                                <h4>
                                    {{ scope.row.remark }}
                                </h4>
                            </template>
                        </el-table-column>
                        <el-table-column label="金额" width="120">
                            <template slot-scope="scope">
                                <span class="price" v-if="scope.row.value > 0">+{{ scope.row.value }}</span>
                                <span class="price" v-else>{{ scope.row.value }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column label="交易时间" width="180">
                            <template slot-scope="scope">
                                <span class="date">
                                    <i class="el-icon-time"></i>
                                    {{ scope.row.addTime }}
                                </span>
                            </template>
                        </el-table-column>
                    </el-table>

                    <div class="pager-box">
                        <el-pagination background :hide-on-single-page="true" @current-change="handleCurrentChange"
                            :page-size="pageSize" layout="prev, pager, next" :total="totalCount">
                        </el-pagination>
                    </div>

                </div>
            </div>
        </div>
    </div>
</template>

<script>
import AccountNav from "@/components/AccountNav.vue";
import AxiosAjax from "@/utils/axios.config";
import { RouterLink } from "vue-router";
export default {
    name: 'AATShopBalanceList',
    components: {
        AccountNav,
        RouterLink
    },
    data() {
        return {
            account: null, //会员信息
            totalCount: 0, //总数量
            pageSize: 10, //每页显示条数
            pageIndex: 1, //当前页码
            listData: [],//数据列表
        };
    },
    methods: {
        //加载列表
        async loadData() {
            let _this = this;
            AxiosAjax({
                url: `/account/member/amount?pageSize=${_this.pageSize}&pageIndex=${_this.pageIndex}`,
                loading: true,
                success: function (res) {
                    _this.listData = res.data;
                    let pageInfo = JSON.parse(res.headers["x-pagination"]);
                    _this.totalCount = pageInfo.totalCount;
                    _this.pageSize = pageInfo.pageSize;
                    _this.pageIndex = pageInfo.pageIndex;
                },
                error: function () {
                    _this.listData = []
                }
            });
        },
        //初始化数据
        async initData() {
            let _this = this;
            //加载列表
            await _this.loadData();
            //加载会员信息
            await AxiosAjax({
                url: `/account/member`,
                success: function (res) {
                    _this.account = res.data;
                }
            });
        },
        //跳转到第几页
        handleCurrentChange: function (val) {
            if (this.pageIndex != val) {
                this.pageIndex = val;
                this.loadData();
            }
        },
    },
    created() {
        this.initData()
    }
};
</script>

<style lang="scss" scoped></style>