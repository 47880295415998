<template>
  <div class="wrapper">
    <div class="location">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item>首页</el-breadcrumb-item>
        <el-breadcrumb-item>购物商城</el-breadcrumb-item>
        <el-breadcrumb-item>店铺列表</el-breadcrumb-item>
      </el-breadcrumb>
    </div>

    <!--店铺列表.开始-->
    <div class="goods-index">
      <div class="store-list">
        <div v-if="listData.length === 0" class="nodata">暂无店铺记录</div>
        <div v-for="(item, index) in listData" :key="index" class="list-box">
          <Store :store="item" />
        </div>
      </div>

      <div class="pager-box">
        <el-pagination
          background
          :hide-on-single-page="false"
          @current-change="handleCurrentChange"
          :page-size="pageSize"
          layout="prev, pager, next"
          :total="totalCount"
        >
        </el-pagination>
      </div>
    </div>

    <!--店铺列表.结束-->
  </div>
</template>

<script>
import AxiosAjax from "@/utils/axios.config";
import Store from "@/components/Store.vue";
export default {
  name: "AATShopStoreList",
  components: {
    Store,
  },
  data() {
    return {
      totalCount: 0, //总数量
      pageSize: 20, //每页显示条数
      pageIndex: 1, //当前页码
      listData: [], //数据列表，
      keyword: "",
    };
  },
  methods: {
    //加载商家列表
    async loadData() {
      let _this = this;
      let url = `/client/merchant/goods/6?pageSize=${_this.pageSize}&pageIndex=${_this.pageIndex}`;
      if (this.keyword) url += "&keyword=" + this.keyword;
      AxiosAjax({
        url,
        loading: true,
        success(res) {
          _this.listData = res.data;
          let pageInfo = JSON.parse(res.headers["x-pagination"]);
          _this.totalCount = pageInfo.totalCount;
          _this.pageSize = pageInfo.pageSize;
          _this.pageIndex = pageInfo.pageIndex;
        },
        error: function () {
          _this.listData = [];
        },
      });
    },
    //初始化数据
    async initData() {
      let _this = this;
      //商品列表
      _this.loadData();
    },
    //跳转到第几页
    handleCurrentChange: function (val) {
      if (this.pageIndex != val) {
        this.pageIndex = val;
        this.loadData();
      }
    },
  },
  created() {
    this.keyword = this.common.queryValue("keyword");
    this.initData();
  },
  beforeRouteUpdate(_, __, next) {
    next()
    this.keyword = this.common.queryValue("keyword");
    this.initData();
  },
};
</script>

<style lang="scss" scoped></style>
