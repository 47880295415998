<template>
  <div>
    <img src="@/assets/images/banner/store.jpg" alt="" class="store-banner" />
    <!--页面内容.开始-->
    <div class="wrapper">
      <div class="location">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item>首页</el-breadcrumb-item>
          <el-breadcrumb-item>购物商城</el-breadcrumb-item>
          <el-breadcrumb-item>店铺主页</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
    </div>

    <div class="goods-info-box wrapper">
      <div class="store-box">
        <div class="info-box" v-if="merchant">
          <div class="info-header">
            <div class="title">
              <!-- <span class="label" v-if="merchant && merchant.isSelf == 1"
                >自营</span
              > -->
              <span class="text">{{ merchant.title }}</span>
              <span class="years">{{ live }}年店</span>
            </div>
          </div>
          <div class="row-list">
            <div class="list-box">
              <span class="label">店铺地址</span>
              <span class="text">{{ merchant.address }}</span>
            </div>
            <div class="list-box">
              <span class="label">总热度</span>
              <span class="text">{{ merchant.totaHeat }}</span>
            </div>
            <div class="list-box">
              <span class="label">主打产品</span>
              <span class="text">{{ merchant.mainProduct }}</span>
            </div>
            <div class="list-box">
              <span class="label">关注人数</span>
              <span class="text"
                >{{ merchant && merchant.favoriteCount }} 人</span
              >
            </div>
            <div class="list-box">
              <span class="label">累积评价</span>
              <span class="text"
                >{{ merchant && merchant.evaluateCount }} 次</span
              >
            </div>
            <div class="list-box">
              <span class="label">qq</span>
              <span class="text">{{ qq }}</span>
            </div>
            <div class="list-box">
              <span class="label">微信</span>
              <span class="text">{{ weixin }}</span>
            </div>
            <div class="list-box">
              <span class="label">联系电话</span>
              <span class="text">{{ phone }}</span>
            </div>
          </div>
          <div class="btn-list">
            <el-button
              v-if="!isMerchantFocus"
              size="mini"
              @click="addMerchantFocus"
              >关注店铺</el-button
            >
            <el-button v-else size="mini" @click="addMerchantFocus"
              >取消关注</el-button
            >
            <el-button size="mini" @click="showConcat">查看联系方式</el-button>
          </div>
        </div>
      </div>
      <div class="store-goods" v-if="isSecret">
        <div class="goods-lock">
          <div>
            <div>
              <img src="@/assets/images/lock.png" alt="lock" />
            </div>
            <el-button
              loading
              class="access-btn"
              @click="openAccessDialog = true"
              >申请</el-button
            >
          </div>
        </div>
      </div>
      <div class="store-goods" v-else>
        <!--查询条件.开始-->
        <div class="goods-screen">
          <div class="list-box">
            <span class="title">促销</span>
            <div class="item-box">
              <span
                @click="handleLabelChange(0)"
                :class="{ active: labelId === 0 }"
                class="text"
                >全部</span
              >
              <span
                v-for="(item, index) in labelList"
                :key="index"
                @click="handleLabelChange(item.id)"
                :class="{ active: labelId === item.id }"
                class="text"
                >{{ item.title }}</span
              >
            </div>
          </div>
          <div class="list-box">
            <span class="title">排序</span>
            <div class="item-box">
              <span
                @click="handleFilterChange(0)"
                :class="{ active: filterIndex === 0 }"
                class="text"
                >默认</span
              >
              <span
                @click="handleFilterChange(1)"
                :class="{ active: filterIndex === 1 }"
                class="text"
                >销量</span
              >
              <span
                @click="handleFilterChange(2)"
                :class="{ active: filterIndex === 2 }"
                class="text"
                >人气</span
              >
              <div @click="handleFilterChange(3)" class="text">
                价格
                <span class="icon iconfont icon-sort-full"></span>
              </div>
              <div class="input">
                <el-input v-model="minPrice" size="mini" clearable>
                  <template slot="prepend"> ￥ </template>
                </el-input>
              </div>
              <span class="line">-</span>
              <div class="input">
                <el-input v-model="maxPrice" size="mini" clearable>
                  <template slot="prepend"> ￥ </template>
                </el-input>
              </div>
              <div class="btn">
                <el-button @click="handlePriceChange" size="mini"
                  >确认</el-button
                >
              </div>
            </div>
          </div>
        </div>
        <!--查询条件.结束-->
        <div class="goods-list">
          <div v-if="listData.length === 0" class="nodata">
            暂无查询到相关商品
          </div>
          <router-link
            v-for="(item, index) in listData"
            :key="index"
            :to="'/shop/goods/show/' + item.id"
            class="list-box"
          >
            <div class="img-box">
              <el-image :src="common.loadFile(item.imgUrl)"></el-image>
            </div>
            <div class="title">
              <span class="label">{{ item.brandTitle }}</span>
              <span>{{ item.title }}</span>
            </div>
            <div class="price"><span>¥</span>{{ item.sellPrice }}</div>
          </router-link>
        </div>
        <div class="pager-box">
          <el-pagination
            background
            :hide-on-single-page="false"
            @current-change="handleCurrentChange"
            :page-size="pageSize"
            layout="prev, pager, next"
            :total="totalCount"
          >
          </el-pagination>
        </div>
      </div>
    </div>
    <!--页面内容.结束-->
    <el-dialog
      :visible.sync="openAccessDialog"
      width="516px"
      title="申请店铺"
      center
    >
      <el-form
        label-position="right"
        label-width="80px"
        :model="accessForm"
        :rules="rules"
      >
        <el-form-item label="姓名" prop="realName">
          <el-input
            v-model="accessForm.realName"
            aria-placeholder="请输入姓名"
            placeholder="请输入姓名"
            class="access-input"
          ></el-input>
        </el-form-item>
        <el-form-item label="电话" prop="userPhone">
          <el-input
            v-model="accessForm.userPhone"
            aria-placeholder="请输入电话"
            placeholder="请输入电话"
            class="access-input"
          ></el-input>
        </el-form-item>
        <el-form-item label="店铺名" prop="userShop">
          <el-input
            v-model="accessForm.userShop"
            aria-placeholder="请输入店铺名"
            placeholder="请输入店铺名"
            class="access-input"
          ></el-input>
        </el-form-item>
        <el-button
          :loading="loading"
          type="submit"
          class="submit-btn"
          @click="submitAccessHandler"
          >提交</el-button
        >
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import AxiosAjax from "@/utils/axios.config";

export default {
  name: "AATShopStoreIndex",

  data() {
    return {
      merchantId: this.$route.params.id, //店铺ID
      isMerchantFocus: false, //店铺收藏
      filterIndex: 0, //排序索引
      priceOrder: 0, //1价格从低到高 2价格从高到低
      labelId: 0, //标签ID
      minPrice: "", //最低价格
      maxPrice: "", //最高价格
      totalCount: 0, //总数量
      pageSize: 20, //每页显示条数
      pageIndex: 1, //当前页码
      labelList: [], //标签列表
      redList: [], //推荐商品
      listData: [], //数据列表
      merchant: null,
      qq: "",
      weixin: "",
      phone: "",
      isSecret: true,
      openAccessDialog: false,
      loading: false,
      accessForm: {
        realName: "",
        userPhone: "",
        userShop: "",
      },
      rules: {
        realName: [{ required: true, message: "请输入姓名", trigger: "blur" }],
        userPhone: [{ required: true, message: "请输入电话", trigger: "blur" }],
        userShop: [
          { required: true, message: "请输入店铺名", trigger: "blur" },
        ],
      },
    };
  },
  computed: {
    live() {
      if (this.merchant) {
        return (
          new Date().getFullYear() -
          new Date(this.merchant.addTime).getFullYear()
        );
      }
      return 0;
    },
  },
  methods: {
    //加载商品列表
    async loadData() {
      let _this = this;
      let sendUrl = `/client/shop/goods?merchantId=${_this.merchantId}`;
      sendUrl += "&labelId=" + _this.labelId;
      sendUrl += "&pageSize=" + _this.pageSize;
      sendUrl += "&pageIndex=" + _this.pageIndex;
      //关健字
      if (_this.common.param.keyword) {
        sendUrl += "&keyword=" + encodeURI(_this.common.param.keyword);
      }
      //价格
      if (_this.minPrice > 0) {
        sendUrl += `&minPrice=${_this.minPrice}`;
      }
      if (_this.maxPrice > 0) {
        sendUrl += `&maxPrice=${_this.maxPrice}`;
      }
      //排序
      if (_this.filterIndex === 1) {
        sendUrl += `&orderBy=-SaleCount`;
      }
      if (_this.filterIndex === 2) {
        sendUrl += `&orderBy=-Click`;
      }
      if (_this.filterIndex === 3) {
        if (_this.priceOrder == 1) {
          sendUrl += `&orderBy=SellPrice`;
        } else {
          sendUrl += `&orderBy=-SellPrice`;
        }
      }
      AxiosAjax({
        url: sendUrl,
        loading: true,
        success(res) {
          _this.listData = res.data;
          let pageInfo = JSON.parse(res.headers["x-pagination"]);
          _this.totalCount = pageInfo.totalCount;
          _this.pageSize = pageInfo.pageSize;
          _this.pageIndex = pageInfo.pageIndex;
        },
        error() {
          _this.listData = [];
        },
      });
    },
    async showConcat() {
      await AxiosAjax({ url: `/account/member` });
      this.qq = this.merchant.qq;
      this.weixin = this.merchant.weixin;
      this.phone = this.merchant.mobile;
    },
    //初始化数据
    async initData() {
      let _this = this;
      //加载店铺收藏信息
      AxiosAjax({
        url: `/client/merchant/${_this.merchantId}`,
        success(res) {
          _this.merchant = res.data;
          const { qq, weixin, mobile, isSecret } = res.data;
          _this.qq = qq ? qq.slice(0, 2) + "****" + qq.slice(-2) : "";
          _this.weixin = weixin
            ? weixin.slice(0, 2) + "****" + weixin.slice(-2)
            : "";
          _this.phone = mobile
            ? mobile.slice(0, 2) + "****" + mobile.slice(-2)
            : "";

          if (isSecret === 1) {
            _this.getSecretHandler();
          } else {
            _this.isSecret = false;
          }
        },
      });
      AxiosAjax({
        url: `/account/merchant/favorite/${_this.merchantId}`,
        success() {
          _this.isMerchantFocus = true;
        },
      });
      //加载标签
      AxiosAjax({
        url: `/client/shop/label/view/0`,
        success: function (res) {
          _this.labelList = res.data;
        },
      });
      //加载人气前3条商品列表
      AxiosAjax({
        url: `/client/shop/goods/view/3?merchantId=${_this.merchantId}&orderBy=-Click`,
        success(res) {
          _this.redList = res.data;
        },
      });
      //加载商品列表
      _this.loadData();
    },
    //添加/取消店铺收藏
    async addMerchantFocus() {
      let _this = this;
      if (!_this.isMerchantFocus) {
        _this.merchant &&
          (await AxiosAjax({
            url: `/account/merchant/favorite/${_this.merchant.id}`,
            method: "post",
            successMsg: "店铺关注成功",
            success: function () {
              _this.isMerchantFocus = true;
            },
          }));
      } else {
        _this.merchant &&
          (await AxiosAjax({
            url: `/account/merchant/favorite/${_this.merchant.id}`,
            method: "delete",
            successMsg: "取消关注成功",
            success: function () {
              _this.isMerchantFocus = false;
            },
          }));
      }
    },
    // 查询是不是隐私店铺
    async getSecretHandler() {
      const data = await AxiosAjax({
        url: `/client/merchant/secret/${this.merchantId}`,
      });
      if (data) {
        this.isSecret = false;
      } else {
        this.isSecret = true;
      }
    },
    async submitAccessHandler() {
      const _this = this;
      _this.loading = true;
      AxiosAjax({
        url: `/client/merchant/secret/add`,
        method: "post",
        data: {
          merchantId: _this.merchant.id,
          realName: _this.accessForm.realName,
          userPhone: _this.accessForm.userPhone,
          userShop: _this.accessForm.userShop,
        },
        success() {
          _this.$message({
            message: "申请提交成功，请等候审核通过。。。",
            type: "success",
          });
          _this.openAccessDialog = false;
        },
        finally() {
          _this.loading = false;
        },
      });
    },
    //筛选标签
    handleLabelChange(val) {
      this.labelId = val;
      this.pageIndex = 1;
      this.totalCount = 0;
      this.loadData();
    },
    //排序
    handleFilterChange(val) {
      this.filterIndex = val;
      //价格筛选
      if (val === 3) {
        this.priceOrder = this.priceOrder === 1 ? 2 : 1;
      } else {
        this.priceOrder = 0;
      }
      this.pageIndex = 1;
      this.totalCount = 0;
      this.loadData();
    },
    //确认
    handlePriceChange() {
      this.pageIndex = 1;
      this.totalCount = 0;
      this.loadData();
    },
    //跳转到第几页
    handleCurrentChange: function (val) {
      if (this.pageIndex != val) {
        this.pageIndex = val;
        this.loadData();
      }
    },
  },
  created() {
    this.initData();
  },
};
</script>

<style lang="scss" scoped>
.store-banner {
  width: 100%;
  aspect-ratio: 1920 / 250;
}
.years {
  width: 40px;
  height: 16px;
  border: 1px solid #e3c71c;
  display: inline-block;
  color: #783400;
  text-align: center;
  line-height: 16px;
  border-radius: 1px;
  background: #fbde2d;
  margin-left: 1px;
}
.goods-lock {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.access-btn {
  background: #ff7900;
  color: white;
  width: 100px;
  height: 40px;
  margin-top: 37px;
}
.submit-btn {
  width: 380px;
  height: 40px;
  background-color: #ff7900;
  color: white;
  margin: auto;
  margin-top: 57px;
  display: block;
}
.access-input {
  width: 330px;
  height: 40px;
  ::v-deep input:focus {
    border-color: #ff7900;
  }
}
</style>
