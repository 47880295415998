<template>
  <div class="wrapper">
    <div class="location">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item>首页</el-breadcrumb-item>
        <el-breadcrumb-item>购物商城</el-breadcrumb-item>
        <el-breadcrumb-item>商品列表</el-breadcrumb-item>
      </el-breadcrumb>
    </div>

    <!--查询条件.开始-->
    <div class="goods-screen">
      <div class="list-box">
        <span class="title">类别</span>
        <div class="item-box">
          <span
            @click="handleCategoryChange(0)"
            :class="{ active: categoryId === 0 }"
            class="text"
            >全部</span
          >
          <span
            v-for="(item, index) in categoryList"
            :key="index"
            @click="handleCategoryChange(item.id)"
            :class="{ active: categoryId == item.id }"
            class="text"
            >{{ item.title }}</span
          >
        </div>
      </div>
      <div class="list-box">
        <span class="title">分类</span>
        <div class="item-box">
          <span
            @click="handleSortChange(0)"
            :class="{ active: sortId === 0 }"
            class="text"
            >全部</span
          >
          <span
            v-for="(item, index) in sortList"
            :key="index"
            @click="handleSortChange(item.id)"
            :class="{ active: sortId == item.id }"
            class="text"
            >{{ item.title }}</span
          >
        </div>
      </div>
      <div class="list-box">
        <span class="title">品牌</span>
        <div class="item-box">
          <span
            @click="handleBrandChange(0)"
            :class="{ active: brandId === 0 }"
            class="text"
            >全部</span
          >
          <span
            v-for="(item, index) in brandList"
            :key="index"
            @click="handleBrandChange(item.id)"
            :class="{ active: brandId === item.id }"
            class="text"
            >{{ item.title }}</span
          >
        </div>
      </div>
      <div class="list-box">
        <span class="title">促销</span>
        <div class="item-box">
          <span
            @click="handleLabelChange(0)"
            :class="{ active: labelId === 0 }"
            class="text"
            >全部</span
          >
          <span
            v-for="(item, index) in labelList"
            :key="index"
            @click="handleLabelChange(item.id)"
            :class="{ active: labelId === item.id }"
            class="text"
            >{{ item.title }}</span
          >
        </div>
      </div>
      <div class="list-box">
        <span class="title">排序</span>
        <div class="item-box">
          <span
            @click="handleFilterChange(0)"
            :class="{ active: filterIndex === 0 }"
            class="text"
            >默认</span
          >
          <span
            @click="handleFilterChange(1)"
            :class="{ active: filterIndex === 1 }"
            class="text"
            >销量</span
          >
          <span
            @click="handleFilterChange(2)"
            :class="{ active: filterIndex === 2 }"
            class="text"
            >人气</span
          >
          <div @click="handleFilterChange(3)" class="text">
            价格
            <span class="icon iconfont icon-sort-full"></span>
          </div>
          <div class="input">
            <el-input v-model="minPrice" size="mini" clearable>
              <template slot="prepend"> ￥ </template>
            </el-input>
          </div>
          <span class="line">-</span>
          <div class="input">
            <el-input v-model="maxPrice" size="mini" clearable>
              <template slot="prepend"> ￥ </template>
            </el-input>
          </div>
          <div class="btn">
            <el-button @click="handlePriceChange" size="mini">确认</el-button>
          </div>
        </div>
      </div>
    </div>
    <!--查询条件.结束-->
    <!--商品列表.开始-->
    <div class="goods-index">
      <div class="goods-list">
        <div v-if="listData.length === 0" class="nodata">
          暂无查询到相关商品
        </div>
        <router-link
          v-for="(item, index) in listData"
          :key="index"
          :to="'/shop/goods/show/' + item.id"
          class="list-box"
        >
          <Goods
            :imgUrl="item.imgUrl"
            :title="item.merchantTitle + ' ' + item.title"
            :goodsName="item.title"
            :sellPrice="item.sellPrice"
            :publicTime="item.addTime.split(' ')[0]"
            :icons="[]"
            :storeName="item.merchantTitle"
          />
        </router-link>
      </div>

      <div class="pager-box">
        <el-pagination
          background
          :hide-on-single-page="false"
          @current-change="handleCurrentChange"
          :page-size="pageSize"
          layout="prev, pager, next"
          :total="totalCount"
        >
        </el-pagination>
      </div>
    </div>

    <!--商品列表.结束-->
  </div>
</template>

<script>
import AxiosAjax from "@/utils/axios.config";
import Goods from "@/components/Goods.vue";
export default {
  name: "AATShopList",
  components: {
    Goods,
  },
  data() {
    return {
      filterIndex: 0, //排序索引
      priceOrder: 0, //1价格从低到高 2价格从高到低
      labelId: 0, //标签ID
      brandId: 0, //品牌ID
      sortId: 0,
      categoryId: this.$route.params.categoryId || 0, //分类ID
      minPrice: "", //最低价格
      maxPrice: "", //最高价格
      totalCount: 0, //总数量
      pageSize: 20, //每页显示条数
      pageIndex: 1, //当前页码
      categoryList: [], //分类列表
      labelList: [], //标签列表
      brandList: [], //品牌列表
      listData: [], //数据列表
    };
  },
  computed: {
    sortList() {
      const _this = this;
      if (_this.categoryId == 0) {
        return _this.categoryList.reduce((accumulator, currentValue) => {
          if (currentValue.children && currentValue.children.length > 0) {
            return accumulator.concat(currentValue.children);
          }
          return accumulator;
        }, []);
      }
      const curCategory = _this.categoryList.find(
        (category) => category.id == _this.categoryId
      );
      return curCategory && curCategory.children ? curCategory.children : [];
    },
  },
  methods: {
    //加载商品列表
    async loadData() {
      let _this = this;
      let sendUrl = `/client/shop/goods?categoryId=${_this.categoryId}`;
      sendUrl += "&brandId=" + _this.brandId;
      sendUrl += "&labelId=" + _this.labelId;
      sendUrl += "&pageSize=" + _this.pageSize;
      sendUrl += "&pageIndex=" + _this.pageIndex;
      //关健字
      if (_this.common.param.keyword) {
        sendUrl += "&keyword=" + encodeURI(_this.common.param.keyword);
      }
      //价格
      if (_this.minPrice > 0) {
        sendUrl += `&minPrice=${_this.minPrice}`;
      }
      if (_this.maxPrice > 0) {
        sendUrl += `&maxPrice=${_this.maxPrice}`;
      }
      //排序
      if (_this.filterIndex === 1) {
        sendUrl += `&orderBy=-SaleCount`;
      }
      if (_this.filterIndex === 2) {
        sendUrl += `&orderBy=-Click`;
      }
      if (_this.filterIndex === 3) {
        if (_this.priceOrder == 1) {
          sendUrl += `&orderBy=SellPrice`;
        } else {
          sendUrl += `&orderBy=-SellPrice`;
        }
      }
      AxiosAjax({
        url: sendUrl,
        loading: true,
        success: function (res) {
          _this.listData = res.data;
          let pageInfo = JSON.parse(res.headers["x-pagination"]);
          _this.totalCount = pageInfo.totalCount;
          _this.pageSize = pageInfo.pageSize;
          _this.pageIndex = pageInfo.pageIndex;
        },
        error: function () {
          _this.listData = [];
        },
      });
    },
    //初始化数据
    async initData() {
      let _this = this;
      //加载分类
      AxiosAjax({
        url: `/client/shop/category`,
        success: function (res) {
          _this.categoryList = res.data;
          const curCategory = _this.categoryList.find(
            (category) => category.id == _this.categoryId
          );
          const sortList =
            curCategory && curCategory.children ? curCategory.children : [];
          const item = sortList.find(
            (item) => item.title == _this.common.param.keyword
          );
          _this.sortId = item ? item.id : 0;
        },
      });
      //加载品牌
      AxiosAjax({
        url: `/client/shop/brand/view/0`,
        success: function (res) {
          _this.brandList = res.data;
        },
      });
      //加载标签
      AxiosAjax({
        url: `/client/shop/label/view/0`,
        success: function (res) {
          _this.labelList = res.data;
        },
      });
      //加载商品列表
      _this.loadData();
    },
    //筛选类别
    handleCategoryChange(val) {
      this.categoryId = val;
      this.pageIndex = 1;
      this.totalCount = 0;
      this.common.param.keyword = "";
      this.sortId = 0;
      this.loadData();
    },
    //筛选分类
    handleSortChange(val) {
      this.sortId = val;
      this.pageIndex = 1;
      this.totalCount = 0;
      if (val == 0) {
        this.common.param.keyword = "";
      } else {
        const item = this.sortList.find((item) => item.id == val);
        this.common.param.keyword = item.title;
      }
      this.loadData();
    },
    //筛选品牌
    handleBrandChange(val) {
      this.brandId = val;
      this.pageIndex = 1;
      this.totalCount = 0;
      this.loadData();
    },
    //筛选标签
    handleLabelChange(val) {
      this.labelId = val;
      this.pageIndex = 1;
      this.totalCount = 0;
      this.loadData();
    },
    //排序
    handleFilterChange(val) {
      this.filterIndex = val;
      //价格筛选
      if (val === 3) {
        this.priceOrder = this.priceOrder === 1 ? 2 : 1;
      } else {
        this.priceOrder = 0;
      }
      this.pageIndex = 1;
      this.totalCount = 0;
      this.loadData();
    },
    //确认
    handlePriceChange() {
      this.pageIndex = 1;
      this.totalCount = 0;
      this.loadData();
    },
    //跳转到第几页
    handleCurrentChange: function (val) {
      if (this.pageIndex != val) {
        this.pageIndex = val;
        this.loadData();
      }
    },
  },
  created() {
    this.common.param.keyword = this.common.queryValue("keyword");
    this.initData();
  },
  beforeRouteUpdate(to, _, next) {
    this.categoryId = to.params.categoryId || 0;
    this.common.param.keyword = this.common.queryValue("keyword");
    this.initData();
    next();
  },
};
</script>

<style lang="scss" scoped></style>
