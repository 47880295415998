<template>
    <div class="wrapper">
        <div class="account-box">
            <AccountNav :account="account" />
            <div class="index-box">
                <div class="info-index">
                    <div class="avatar-box">
                        <div class="img-box">
                            <el-image v-if="account.avatar" :src="common.loadFile(account.avatar)"></el-image>
                            <span v-else class="icon iconfont icon-user-full"></span>
                            <router-link to="/account/setting/info" class="edit">
                                <span class="icon iconfont icon-edit"></span>
                            </router-link>
                        </div>
                        <div class="txt-box">
                            <div class="item-box">
                                <span class="title">{{ account.userName }}</span>
                            </div>
                            <div class="item-box">
                                <span class="text">用户组</span>
                                <span class="title">{{ account.groupTitle }}</span>
                            </div>
                            <div class="item-box">
                                <span class="text">手机</span>
                                <span class="title">{{ account.phone }}</span>
                            </div>
                            <div class="item-box">
                                <span class="text">注册时间</span>
                                <span class="title">{{ account.regTime }}</span>
                            </div>
                        </div>
                    </div>

                    <div class="num-box">
                        <span class="num">{{ account.amount }}</span>
                        <span class="text">账户余额</span>
                    </div>
                    <div class="num-box">
                        <span class="num">{{ account.point }}</span>
                        <span class="text">账户积分</span>
                    </div>

                </div>

                <div class="section-box">
                    <div class="title">
                        <span>我的订单</span>
                    </div>
                    <div class="content">
                        <div class="order-icon">
                            <router-link class="item-box" to="/account/order/list">
                                <span class="icon iconfont icon-order"></span>
                                <span class="text">全部订单</span>
                            </router-link>
                            <router-link class="item-box" to="/account/order/list?status=1">
                                <span class="icon iconfont icon-payment"></span>
                                <span class="text">待付款</span>
                            </router-link>
                            <router-link class="item-box" to="/account/order/list?status=3">
                                <span class="icon iconfont icon-deliver"></span>
                                <span class="text">待收货</span>
                            </router-link>
                            <router-link class="item-box" to="/account/order/list?status=4">
                                <span class="icon iconfont icon-invite"></span>
                                <span class="text">待评价</span>
                            </router-link>
                            <router-link class="item-box" to="/account/refund/list">
                                <span class="icon iconfont icon-refund"></span>
                                <span class="text">售后/退款</span>
                            </router-link>
                        </div>
                    </div>
                </div>
                <div class="list-wrap">
                    <div class="section-box">
                        <div class="title">
                            <span>待付款订单</span>
                        </div>
                        <div class="content">
                            <div v-if="paymentOrder.length === 0" class="nodata">
                                <span class="icon iconfont icon-msg"></span>
                                <span class="text">暂无需处理订单</span>
                            </div>
                            <div class="txt-list">
                                <div v-for="(item, index) in paymentOrder" :key="index" class="list-box">
                                    <div class="left">
                                        <h5>{{ item.orderNo }}</h5>
                                        <span class="time iconfont icon-time">{{ item.addTime }}</span>
                                    </div>
                                    <div class="right">
                                        <el-button type="warning" size="mini"
                                            @click="common.linkUrl('/payment/confirm?no=' + item.tradeNo)">去付款</el-button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="section-box">
                        <div class="title">
                            <span>待评价订单</span>
                        </div>
                        <div class="content">
                            <div v-if="evaluateOrder.length === 0" class="nodata">
                                <span class="icon iconfont icon-msg"></span>
                                <span class="text">暂无需处理订单</span>
                            </div>
                            <div class="txt-list">
                                <div v-for="(item, index) in evaluateOrder" :key="index" class="list-box">
                                    <div class="left">
                                        <h5>{{ item.orderNo }}</h5>
                                        <span class="time iconfont icon-time">{{ item.addTime }}</span>
                                    </div>
                                    <div class="right">
                                        <el-button type="warning" size="mini"
                                            @click="common.linkUrl('/account/evaluate/edit/' + item.id)">去评价</el-button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="section-box">
                        <div class="title">
                            <span>账户充值</span>
                        </div>
                        <div class="content">
                            <div v-if="rechargeList.length === 0" class="nodata">
                                <span class="icon iconfont icon-msg"></span>
                                <span class="text">暂无需付款记录</span>
                            </div>
                            <div class="txt-list">
                                <div v-for="(item, index) in rechargeList" :key="index" class="list-box">
                                    <div class="left">
                                        <h5>{{ item.tradeNo }}</h5>
                                        <span class="time iconfont icon-time">{{ item.addTime }}</span>
                                    </div>
                                    <div class="right">
                                        <el-button type="success" size="mini"
                                            @click="common.linkUrl('/payment/confirm?no=' + item.tradeNo)">去付款</el-button>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
import AccountNav from "@/components/AccountNav.vue";
import AxiosAjax from "@/utils/axios.config";

export default {
    name: 'Account',

    data() {
        return {
            account: {}, //会员信息
            paymentOrder: [], //未付款订单
            evaluateOrder: [], //待评价订单
            rechargeList: [], //待付款充值
        };
    },
    components: {
        AccountNav
    },
    methods: {
        //初始化数据
        async initData() {
            let _this = this;
            //获取未付款订单
            AxiosAjax({
                url: `/account/order/view/4?status=1`,
                success(res) {
                    _this.paymentOrder = res.data;
                    //循环查找收款信息赋值
                    _this.paymentOrder.forEach(item => {
                        //判断是否预售
                        if (item.orderType === 2) {
                            //检查预付款是否已付
                            let collectionType = 2; //默认查找尾款
                            if (item.paymentStatus === 0) {
                                collectionType = 1;
                            }
                            let modelt = item.collectionRelations.find(val => val.collection.collectionType == collectionType)
                            if (modelt) {
                                let collection = modelt.collection;
                                _this.$set(item, 'tradeNo', collection.tradeNo);
                            }
                        } else {
                            let collection = item.collectionRelations[0].collection;
                            _this.$set(item, 'tradeNo', collection.tradeNo);
                        }
                    });
                }
            });
            //获取待评价订单
            AxiosAjax({
                url: `/account/order/view/4?status=4`,
                success(res) {
                    _this.evaluateOrder = res.data;
                }
            });
            //获取付款充值
            AxiosAjax({
                url: `/account/member/recharge/view/4?status=1`,
                success(res) {
                    _this.rechargeList = res.data;
                }
            });
            //获取会员信息
            await AxiosAjax({
                url: `/account/member`,
                success(res) {
                    _this.account = res.data;
                }
            });
            //获取会员优惠券数量
            await AxiosAjax({
                url: `/account/shop/coupon/count`,
                success(res) {
                    _this.$set(_this.account, 'coupon', res.data);
                }
            });
        },
    },
    created() {
        this.initData();
    }
};
</script>

<style lang="scss" scoped></style>