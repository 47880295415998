<template>
  <div>
    <img src="@/assets/images/banner/store.jpg" alt="" class="store-banner" />
    <div class="wrapper top-title">
      <div class="location">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item>首页</el-breadcrumb-item>
          <el-breadcrumb-item>信息发布</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
      <div class="location"><PublicInfoBtn /></div>
    </div>
    <div class="goods-info-box wrapper">
      <div class="store-box">
        <div class="info-box">
          <div
            :class="{ 'info-type': true, active: infoType == 0 }"
            @click="infoType = 0"
          >
            最新信息
          </div>
        </div>
      </div>

      <div class="info-list">
        <div class="no-info" v-if="infoList.length == 0">暂无信息</div>
        <template v-else>
          <PublicInfoItem
            class="info-item"
            v-for="(item, i) in infoList"
            :key="i"
            :info="item"
          />
        </template>
        <div id="loading"></div>
      </div>
    </div>
  </div>
</template>

<script>
import AxiosAjax from "@/utils/axios.config";
import PublicInfoBtn from "@/components/PublicInfoBtn";
import PublicInfoItem from "@/components/PublicInfoItem";

export default {
  name: "AATShopRentOut",
  components: { PublicInfoBtn, PublicInfoItem },
  data() {
    return {
      infoType: 0,
      pageIndex: 1,
      pageSize: 3,
      totalCount: 0,
      infoList: [],
      loading: true,
      observer: null,
    };
  },

  methods: {
    initData() {
      const _this = this;
      AxiosAjax({
        url: `/client/message?pageSize=${_this.pageSize}&pageIndex=${_this.pageIndex}`,
        loading: true,
        success(res) {
          let pageInfo = JSON.parse(res.headers["x-pagination"]);
          _this.totalCount = pageInfo.totalCount;
          _this.totalPages = pageInfo.totalPages;
          _this.pageSize = pageInfo.pageSize;
          _this.pageIndex = pageInfo.pageIndex;

          const list = res.data.map((item) => {
            if (!item.editPhoto) {
              item.editPhoto = [];
            } else {
              item.editPhoto = item.editPhoto.split(",");
            }
            if (!item.editPhotoSmall) {
              item.editPhotoSmall = [];
            } else {
              item.editPhotoSmall = item.editPhotoSmall.split(",");
            }
            return item;
          });
          _this.infoList.push(...list);
          setTimeout(() => {
            _this.loading = false;
          }, 500);
        },
        error() {
          _this.infoList = [];
        },
      });
    },
  },
  created() {
    this.initData();
  },
  mounted() {
    let options = {
      root: null,
      rootMargin: "100px",
    };
    let callback = () => {
      if (this.pageIndex < this.totalPages && !this.loading) {
        this.loading = true;
        this.pageIndex += 1;
        this.initData();
      }
    };
    let observer = new IntersectionObserver(callback, options);
    const loadingEl = document.getElementById("loading");
    observer.observe(loadingEl);
    this.observer = observer;
  },
  destroyed() {
    this.observer.disconnect();
  },
};
</script>

<style lang="scss" scoped>
.top-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.store-banner {
  width: 100%;
  aspect-ratio: 1920 / 250;
}
.store-box {
  height: 100vh;
  position: sticky;
  top: 0;
}
.info-box {
  padding: 0 !important;
  height: 100%;
  .info-type {
    height: 50px;
    line-height: 50px;
    text-align: center;
    font-size: 16px;
    color: #6a6a6a;
    cursor: pointer;
    &.active,
    &:hover {
      color: #ff7900;
    }
  }
}
.info-list {
  width: 955px;
  min-height: 1353px;
}
.info-item:not(:first-child) {
  margin-top: 10px;
}
.tupian {
  width: 100px;
  height: 100px;
}
.no-info {
  width: 955px;
}
</style>
