<template>
    <div class="wrapper">
        <div class="account-box">

            <AccountNav :account="account" model="order" />

            <div class="right-box">
                <div class="tab-box">
                    <div class="menu-box">
                        <div class="item-box active">
                            <span>我的订单</span>
                        </div>
                    </div>
                </div>
                <div class="content">
                    <div class="order-detail">
                        <div class="screen-box">
                            <div class="nav-bar">
                                <span>订单信息</span>
                                <span class="tip">{{ stateTip }}</span>
                            </div>
                            <div class="item-box">
                                <div class="item">
                                    <div class="left">订单编号</div>
                                    <div class="right">{{ model.orderNo }}</div>
                                </div>
                                <div class="item">
                                    <div class="left">订单日期</div>
                                    <div class="right">{{ model.addTime }}</div>
                                </div>
                                <div class="item">
                                    <div class="left">订单类型</div>
                                    <div v-if="model.orderType === 0" class="right">普通订单</div>
                                    <div v-if="model.orderType === 1" class="right">限时抢购</div>
                                    <div v-if="model.orderType === 2" class="right">商品预售</div>
                                    <div v-if="model.orderType === 3" class="right">积分换购</div>
                                </div>
                                <div class="item">
                                    <div class="left">支付方式</div>
                                    <div class="right">{{ model.paymentTitle }}</div>
                                </div>
                                <div v-if="model.delivery" class="item">
                                    <div class="left">配送方式</div>
                                    <div class="right">{{ model.delivery.title }}</div>
                                </div>
                            </div>
                        </div>

                        <div class="screen-box">
                            <div class="nav-bar">
                                <span>收货信息</span>
                            </div>
                            <div class="item-box">
                                <div class="item">
                                    <div class="left">收货人</div>
                                    <div class="right">{{ model.acceptName }}</div>
                                </div>
                                <div class="item">
                                    <div class="left">收货地址</div>
                                    <div class="right">{{ model.address }}</div>
                                </div>
                                <div class="item">
                                    <div class="left">联系电话</div>
                                    <div class="right">{{ model.mobile }}</div>
                                </div>
                                <div class="item">
                                    <div class="left">订单留言</div>
                                    <div class="right">{{ model.postscript ? model.postscript : '暂无备注' }}</div>
                                </div>
                            </div>
                        </div>

                        <div class="screen-box">
                            <div class="nav-bar">
                                <span>{{ model.merchantTitle }}</span>
                                <router-link target="_blank" :to="'/shop/store/index/' + model.merchantId"
                                    class="tip">查看店铺</router-link>
                            </div>
                            <div class="goods-box">
                                <div v-for="(item, index) in model.orderGoods" :key="index" class="list-box">
                                    <router-link :to="'/shop/goods/show/' + item.goodsId" class="img-box">
                                        <img :src="common.loadFile(item.imgUrl)">
                                    </router-link>
                                    <div class="txt-box">
                                        <span class="title">{{ item.goodsTitle }}</span>
                                        <span class="info">{{ item.skuText }} x {{ item.quantity }}</span>
                                        <span class="price">{{ item.realPrice }}</span>
                                    </div>
                                    <div v-if="model.paymentStatus > 0" class="btn-box">
                                        <el-tag v-if="item.deliveryStatus === 2" type="info">已退货</el-tag>
                                        <el-button v-if="item.deliveryStatus === 1" size="small" type="warning"
                                            @click="common.linkUrl('/account/refund/add/' + item.id)">申请售后</el-button>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="total-box">
                            <span class="text">共 {{ model.orderGoods.length }} 件商品</span>
                            <span class="text">商品金额：<b class="price">{{ model.realAmount }}</b></span>
                            <span v-if="model.orderType === 2" class="text">预售定金：<b class="price">{{ model.frontAmount
                            }}</b></span>
                            <span v-if="model.realFreight > 0" class="text">配送费用：<b class="price">{{ model.realFreight
                            }}</b>元</span>
                             <span v-if="model.realServices > 0" class="text">服务费用合计：<b class="price">{{ model.realServices
                            }}</b>元</span>
                            <span v-if="model.couponAmount > 0" class="text">优惠券抵：-<b class="price">{{ model.couponAmount
                            }}</b>元</span>
                            <span v-if="model.promotionAmount > 0" class="text">促销优惠：-<b class="price">{{
                                model.promotionAmount }}元</b></span>
                            <span v-if="model.discountAmount > 0" class="text">其它优惠：-<b class="price">{{
                                model.discountAmount }}</b>元</span>
                            <span v-if="model.orderType === 3" class="text">兑换积分：<b class="point">{{ model.point
                            }}</b>分</span>
                            
                        </div>

                        <div class="amount-box">
                            实付金额：<span class="price">{{ model.orderAmount }}元</span>
                        </div>

                        <div class="btn-box">
                            <el-button v-if="model.deliveryStatus > 0"
                                @click="common.linkUrl('/account/order/delivery?no=' + model.orderNo)"
                                type="primary">查看物流</el-button>
                            <el-button v-if="model.status < 3 && model.paymentStatus == 0" @click="cancelOrder"
                                type="warning">取消订单</el-button>
                            <el-button v-if="model.status == 1 && model.paymentStatus == 0"
                                @click="common.linkUrl('/payment/confirm?no=' + model.tradeNo)"
                                type="success">去付款</el-button>
                            <el-button v-if="model.status === 1 && model.paymentStatus == 1 && model.tradeStatus == 0"
                                @click="common.linkUrl('/payment/confirm?no=' + model.tradeNo)"
                                type="success">付尾款</el-button>
                            <el-button v-if="model.status === 3 || model.status == 4" @click="confirmOrder"
                                type="danger">确认收货</el-button>
                            <el-button v-if="model.status === 5 && model.orderGoods.find(val => val.evaluateStatus === 0)"
                                @click="common.linkUrl('/account/evaluate/edit/' + model.id)" type="success">去评价</el-button>
                            <el-button v-if="model.status > 2 && model.status < 6"
                                @click="common.linkUrl('/account/refund/list?no=' + model.orderNo)"
                                type="danger">售后详情</el-button>
                            <el-button @click="common.back(-1)">返回上一页</el-button>
                        </div>

                    </div>

                </div>
            </div>
        </div>
    </div>
</template>

<script>
import AxiosAjax from "@/utils/axios.config";
import AccountNav from "@/components/AccountNav.vue";
export default {
    name: 'AATShopOrderShow',
    components: {
        AccountNav
    },
    data() {
        return {
            id: this.$route.params.id,
            stateTip: '已下单',
            model: {
                orderGoods: [],
            },
            account: null, //会员信息
        };
    },
    methods: {
        //初始化数据
        async initData(id) {
            let _this = this;
            if (id < 1) {
                this.$alert('订单参数有误，请检查重试', '错误提示', {
                    confirmButtonText: '确定',
                    callback: () => {
                        _this.common.back(-1);
                    }
                });
                return;
            }
            //加载订单信息
            await AxiosAjax({
                url: `/account/order/${id}`,
                success(res) {
                    _this.model = res.data;
                    //判断是否预售
                    if (_this.model.orderType == 2) {
                        //检查预付款是否已付
                        let collectionType = 2; //默认查找尾款
                        if (_this.model.paymentStatus == 0) {
                            collectionType = 1;
                        }
                        let modelt = _this.model.collectionRelations.find(val => val.collection.collectionType == collectionType)
                        if (modelt) {
                            let collection = modelt.collection;
                            let tradeStatus = _this.checkDate(collection.startTime, collection.endTime);
                            _this.$set(_this.model, 'tradeNo', collection.tradeNo);
                            _this.$set(_this.model, 'tradeStatus', tradeStatus);
                            _this.$set(_this.model, 'paymentTitle', collection.paymentTitle);
                        }
                    } else {
                        let collection = _this.model.collectionRelations[0].collection;
                        let tradeStatus = _this.checkDate(collection.startTime, collection.endTime);
                        _this.$set(_this.model, 'tradeNo', collection.tradeNo);
                        _this.$set(_this.model, 'tradeStatus', tradeStatus);
                        _this.$set(_this.model, 'paymentTitle', collection.paymentTitle);
                    }
                    if (_this.model.status === 1) {
                        _this.stateTip = '待付款';
                        //如果是预售订单
                        if (_this.model.orderType === 2) {
                            if (_this.model.paymentStatus === 1) {
                                _this.stateTip = '待付尾款';
                            }
                            if (_this.model.paymentStatus == 1 && _this.model.tradeStatus === 2) {
                                _this.stateTip = '活动已失效';
                            }
                        }
                    } else if (_this.model.status === 2) {
                        _this.stateTip = '待发货';
                    } else if (_this.model.status === 3) {
                        _this.stateTip = '待收货';
                    } else if (_this.model.status === 4) {
                        _this.stateTip = '已签收';
                    } else if (_this.model.status === 5) {
                        _this.stateTip = '交易成功';
                        if (_this.model.orderGoods.find(val => val.evaluateStatus === 0)) {
                            _this.stateTip = '待评价';
                        }
                    } else if (_this.model.status === 6) {
                        _this.stateTip = '交易取消';
                    } else if (_this.model.status === 7) {
                        _this.stateTip = '交易作废';
                    }
                }
            });
            //加载会员信息
            await AxiosAjax({
                url: `/account/member`,
                success(res) {
                    _this.account = res.data;
                }
            });
        },
        //取消订单
        async cancelOrder() {
            let _this = this;
            _this.$confirm('确认要取消该订单吗？', '操作提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(function () {
                AxiosAjax({
                    method: 'put',
                    url: `/account/order/collection/cancel/${_this.model.tradeNo}`,
                    loading: true,
                    successMsg: '订单已取消',
                    success() {
                        _this.model.status = 6; //更改订单状态
                    }
                })
            }).catch(function () { });
        },
        //确认收货
        async confirmOrder() {
            let _this = this;
            _this.$confirm('确认已收到货了吗？', '操作提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(function () {
                AxiosAjax({
                    method: 'put',
                    url: `/account/order/confirm/${_this.model.id}`,
                    loading: true,
                    successMsg: '交易已成功',
                    success() {
                        _this.model.status = 5; //更改订单状态
                    }
                })
            }).catch(function () { });
        },
        //返回时间对比状态0正常1未开始2已结束
        checkDate(beginDateStr, endDateStr) {
            var curDate = new Date(),
                beginDate = new Date(beginDateStr),
                endDate = new Date(endDateStr);
            if (curDate < beginDate) {
                return 1;
            }
            if (curDate > endDate) {
                return 2;
            }
            return 0;
        },
    },
    created() {
        this.initData(this.id);
    }
};
</script>

<style lang="scss" scoped></style>