<template>
  <div class="wrapper">
    <div class="account-box">
      <AccountNav :account="account" model="setting" />

      <div class="right-box">
        <div class="tab-box">
          <div class="menu-box">
            <router-link to="/account/setting/info" class="item-box active">
              <span>个人资料</span>
            </router-link>
            <router-link to="/account/setting/password" class="item-box">
              <span>修改密码</span>
            </router-link>
          </div>
        </div>
        <div class="content">
          <el-form
            ref="editForm"
            :model="account"
            :rules="rules"
            label-position="right"
            label-width="120px"
          >
            <el-form-item label-width="0">
              <dt-upload-avatar
                v-model="account.avatar"
                :width="300"
                :height="300"
              ></dt-upload-avatar>
            </el-form-item>
            <el-form-item label="真实姓名">
              <el-input
                v-model="account.realName"
                placeholder="非必填，可空"
              ></el-input>
            </el-form-item>
            <el-form-item label="电商平台">
              <el-radio-group v-model="account.shopType">
                <el-radio-button label="淘宝" >淘宝</el-radio-button>
                <el-radio-button label="拼多多" >拼多多</el-radio-button>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="店铺名称">
              <el-input v-model="account.shopName" placeholder="非必填，可空"></el-input>
            </el-form-item>
            <el-form-item prop="sex" label="性别">
              <el-radio-group v-model="account.sex">
                <el-radio-button label="保密">保密</el-radio-button>
                <el-radio-button label="男">男</el-radio-button>
                <el-radio-button label="女">女</el-radio-button>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="生日">
              <el-date-picker
                v-model="account.birthday"
                type="date"
                value-format="yyyy-MM-dd"
                placeholder="选择日期"
              ></el-date-picker>
            </el-form-item>
            <el-form-item label="注册时间">
              {{ account.regTime }}
            </el-form-item>
            <el-form-item label="最后登录时间">
              {{ account.lastTime }}
            </el-form-item>
            <el-form-item label="注册IP">
              {{ account.regIp }}
            </el-form-item>
            <el-form-item label="最后登录IP">
              {{ account.lastIp }}
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="submitForm">确认修改</el-button>
              <el-button @click="common.back(-1)">取消</el-button>
            </el-form-item>
          </el-form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AxiosAjax from "@/utils/axios.config";
import AccountNav from "@/components/AccountNav.vue";
export default {
  name: "AATShopSettingInfo",
  components: {
    AccountNav,
  },
  data() {
    return {
      showDialog: false, //对话框显示
      account: {
        avatar: null,
        realName: null,
        sex: null,
        birthday: null,
        shopType:null,
        shopName:null,
      },
      rules: {
        sex: [{ required: true, message: "请选择性别", trigger: "change" }],
      },
    };
  },
  methods: {
    //初始化数据
    async initData() {
      let _this = this;
      //获取会员信息
      await AxiosAjax({
        url: `/account/member`,
        success(res) {
          _this.account = res.data;
        },
      });
    },
    //提交表单
    submitForm: function () {
      let _this = this; //当前页面
      //多个表单验证
      const f1 = new Promise((resolve) => {
        _this.$refs["editForm"].validate((valid) => {
          if (valid) resolve();
        });
      });
      Promise.all([f1]).then(() => {
        AxiosAjax({
          method: "put",
          url: "/account/member/info",
          data: _this.account,
          loading: true,
          successMsg: "修改资料已成功",
          success() {
            //可跳转加列表页
          },
        });
          
      });
    },
  },
  created() {
    this.initData();
  },
};
</script>

<style lang="scss" scoped></style>
