<template>
    <div class="wrapper">
        <div class="account-box">
            <AccountNav :account="account" model="coupon" />
            <div class="right-box">
                <div class="tab-box">
                    <div class="menu-box">
                        <div class="item-box active">
                            <span>我的优惠券</span>
                        </div>
                    </div>
                </div>
                <div class="content">
                    <div v-if="listData.length === 0" class="nodata">
                        <span class="icon iconfont icon-msg"></span>
                        <span class="text">暂无记录</span>
                    </div>

                    <div class="ticket-list">
                        <div v-for="(item, index) in listData" :key="index" class="list-wrap">
                            <div class="list-box">
                                <div class="price-box">
                                    <span class="price">{{ item.shopCoupon.amount }}</span>
                                </div>
                                <div class="txt-box">
                                    <span class="text">{{ item.shopCoupon.title }}</span>
                                    <span class="time">可用时间{{ item.shopCoupon.enableTime }}</span>
                                    <span class="time">有效期至{{ item.shopCoupon.endTime }}</span>
                                </div>
                                <div class="btn-box">
                                    <span v-if="item.isUse == 1" class="active">已使用</span>
                                    <span v-else>未使用</span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="pager-box">
                        <el-pagination background :hide-on-single-page="true" @current-change="handleCurrentChange"
                            :page-size="pageSize" layout="prev, pager, next" :total="totalCount">
                        </el-pagination>
                    </div>

                </div>
            </div>
        </div>
    </div>
</template>

<script>
import AccountNav from "@/components/AccountNav.vue";
import AxiosAjax from "@/utils/axios.config";
export default {
    name: 'AATShopCouponList',
    components: {
        AccountNav
    },
    data() {
        return {
            account: null, //会员信息
            totalCount: 0, //总数量
            pageSize: 10, //每页显示条数
            pageIndex: 1, //当前页码
            listData: [],
        };
    },

    methods: {
                //加载列表
                async loadData() {
                    let _this = this;
                    AxiosAjax({
                        url: `/account/coupon/history?pageSize=${_this.pageSize}&pageIndex=${_this.pageIndex}`,
                        loading: true,
                        success: function (res) {
                            _this.listData = res.data;
                            let pageInfo = JSON.parse(res.headers["x-pagination"]);
                            _this.totalCount = pageInfo.totalCount;
                            _this.pageSize = pageInfo.pageSize;
                            _this.pageIndex = pageInfo.pageIndex;
                        },
                        error: function () {
                            _this.listData = []
                        }
                    });
                },
                //初始化数据
                async initData() {
                    let _this = this;
                    //加载列表
                    await _this.loadData();
                    //加载会员信息
                    await AxiosAjax({
                        url: `/account/member`,
                        success(res) {
                            _this.account = res.data;
                        }
                    });
                },
                //跳转到第几页
                handleCurrentChange(val) {
                    if (this.pageIndex != val) {
                        this.pageIndex = val;
                        this.loadData();
                    }
                },
            },
            created() {
                this.initData()
            }
};
</script>

<style lang="scss" scoped></style>