<template>
    <div class="left-box">
        <div class="head-box">
            <div class="img-box">
                <img v-if="account && account.avatar" :src="common.loadFile(account.avatar)">
                <span v-else class="icon iconfont icon-user-full"></span>
            </div>
            <div class="info">
                <router-link to="/account/setting/password" class="text">修改密码</router-link>
                <span class="text" @click="common.logout()">退出登录</span>
            </div>
        </div>
        <div class="menu-box">
            <router-link to="/account/cargo/list" :class="['item-box', { active: model == 'cargo' }]">
                <span class="icon iconfont icon-order"></span>
                <span>配货清单</span>
            </router-link>
            <router-link to="/account/order/list" :class="['item-box', { active: model == 'order' }]">
                <span class="icon iconfont icon-order"></span>
                <span>我的订单</span>
            </router-link>
            <router-link to="/account/address/list" :class="['item-box', { active: model == 'address' }]">
                <span class="icon iconfont icon-address"></span>
                <span>收货地址</span>
            </router-link>
            <router-link to="/account/favorite/list" :class="['item-box', { active: model == 'favorite' }]">
                <span class="icon iconfont icon-like"></span>
                <span>商品收藏</span>
            </router-link>
            <router-link to="/account/store_favorite/list" :class="['item-box', { active: model == 'store_favorite' }]">
                <span class="icon iconfont icon-like"></span>
                <span>店铺收藏</span>
            </router-link>
            <router-link to="/account/message/list" :class="['item-box', { active: model == 'message' }]">
                <span class="el-icon-bell"></span>
                <span>站内消息</span>
            </router-link>
            <router-link to="/account/coupon/list" :class="['item-box', { active: model == 'coupon' }]">
                <span class="icon iconfont icon-ticket"></span>
                <span>优 惠 券</span>
            </router-link>
            <router-link to="/account/store/settled" :class="['item-box', { active: model == 'settled' }]">
                <span class="icon iconfont icon-edit"></span>
                <span>申请开店</span>
            </router-link>
            <router-link to="/account/balance/list" :class="['item-box', { active: model == 'balance' }]">
                <span class="icon iconfont icon-amount"></span>
                <span>我的余额 <span class="try">(试用)</span></span>
            </router-link>
            <router-link to="/account/setting/info" :class="['item-box', { active: model == 'setting' }]">
                <span class="icon iconfont icon-info"></span>
                <span>账户管理</span>
            </router-link>
            <router-link to="/account/info/list" :class="['item-box', { active: model == 'infoList' }]">
                <span class="icon iconfont icon-message"></span>
                <span>信息列表</span>
            </router-link>
        </div>
    </div>
</template>

<script>
export default {
    name: 'AccountNav',
    props: {
        model: {
            type: String,
            default: ''
        },
        account:Object
    },
};
</script>

<style scoped>
.try{
    color: #ff7900;
}
</style>
