<template>
    <div class="wrapper">
        <div class="location">
            <el-breadcrumb separator-class="el-icon-arrow-right">
                <el-breadcrumb-item>首页</el-breadcrumb-item>
                <el-breadcrumb-item>购物商城</el-breadcrumb-item>
                <el-breadcrumb-item>商品预售</el-breadcrumb-item>
            </el-breadcrumb>
        </div>

        <!--商品列表.开始-->
        <div class="goods-index">
            <div class="goods-list">
                <div v-if="listData.length === 0" class="nodata">暂无预售商品</div>
                <router-link v-for="(item, index) in listData" :key="index"
                    :to="'/shop/goods/show/' + item.goodsProduct.shopGoods.id + '?pid=' + item.goodsProduct.id"
                    class="list-box">
                    <div class="img-box">
                        <el-image
                            :src="common.loadFile(item.goodsProduct.imgUrl ? item.goodsProduct.imgUrl : item.goodsProduct.shopGoods.imgUrl)"></el-image>
                    </div>
                    <div class="title">
                        <span>{{ item.title }}</span>
                    </div>
                    <div class="price">
                        <span>¥</span>{{ item.totalPrice }}
                        <span class="ot">¥{{ item.goodsProduct.sellPrice }}</span>
                    </div>
                    <div class="timer">
                        <span class="text">距离结束</span>
                        <dt-timer :end="item.actEndTime"></dt-timer>
                    </div>
                </router-link>
            </div>

            <div class="pager-box">
                <el-pagination background :hide-on-single-page="false" @current-change="handleCurrentChange"
                    :page-size="pageSize" layout="prev, pager, next" :total="totalCount">
                </el-pagination>
            </div>
        </div>

        <!--商品列表.结束-->
    </div>
</template>

<script>
import AxiosAjax from '@/utils/axios.config';

export default {
    name: 'AATShopPresellList',

    data() {
        return {
            totalCount: 0, //总数量
            pageSize: 20, //每页显示条数
            pageIndex: 1, //当前页码
            listData: [] //数据列表
        };
    },
    methods: {
        //加载商品列表
        async loadData() {
            let _this = this;
            AxiosAjax({
                url: `/client/shop/presell?pageSize=${_this.pageSize}&pageIndex=${_this.pageIndex}`,
                loading: true,
                success: function (res) {
                    _this.listData = res.data;
                    let pageInfo = JSON.parse(res.headers["x-pagination"]);
                    _this.totalCount = pageInfo.totalCount;
                    _this.pageSize = pageInfo.pageSize;
                    _this.pageIndex = pageInfo.pageIndex;
                },
                error: function () {
                    _this.listData = []
                }
            });
        },
        //初始化数据
        async initData() {
            let _this = this;
            _this.loadData();
        },
        //跳转到第几页
        handleCurrentChange: function (val) {
            if (this.pageIndex != val) {
                this.pageIndex = val;
                this.loadData();
            }
        },
    },
    created() {
        this.initData();
    }
};
</script>

<style lang="scss" scoped></style>