<template>
    <div class="wrapper">
        <div class="account-box">

            <AccountNav :account="account" model="setting" />

            <div class="right-box">
                <div class="tab-box">
                    <div class="menu-box">
                        <router-link to="/account/setting/info" class="item-box">
                            <span>个人资料</span>
                        </router-link>
                        <router-link to="/account/setting/password" class="item-box active">
                            <span>修改密码</span>
                        </router-link>
                    </div>
                </div>
                <div class="content">
                    <el-form ref="editForm" :model="model" :rules="rules" label-position="right" label-width="120px">
                        <el-form-item prop="password" label="当前密码">
                            <el-input show-password v-model="model.password" placeholder="必填，最少6位英文字母"></el-input>
                        </el-form-item>
                        <el-form-item prop="newPassword" label="新 密 码">
                            <el-input show-password v-model="model.newPassword" placeholder="必填，最少6位英文字母"></el-input>
                        </el-form-item>
                        <el-form-item prop="confirmPassword" label="确认密码">
                            <el-input show-password v-model="model.confirmPassword" placeholder="必填，最少6位英文字母"></el-input>
                        </el-form-item>
                        <el-form-item>
                            <el-button type="primary" @click="submitForm">确认修改</el-button>
                            <el-button @click="common.back(-1)">取消</el-button>
                        </el-form-item>
                    </el-form>

                </div>
            </div>
        </div>
    </div>
</template>

<script>
import AxiosAjax from "@/utils/axios.config";
import AccountNav from "@/components/AccountNav.vue";
export default {
    name: 'AATShopSettingPassword',
    components: {
        AccountNav
    },
    data() {
        return {
            //账户信息
            account: {},
            //表单实体
            model: {
                password: null,
                newPassword: null,
                confirmPassword: null
            },
            rules: {
                password: [
                    { required: true, message: '请输入当前登录密码', trigger: 'blur' },
                    { min: 6, max: 128, message: '密码长度在至少6位', trigger: 'blur' },
                    { pattern: /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,20}$/, message: '必须英文字母数字组合', trigger: 'blur' }
                ],
                newPassword: [
                    { required: true, message: '请输入新密码', trigger: 'blur' },
                    { min: 6, max: 128, message: '密码长度在至少6位', trigger: 'blur' },
                    { pattern: /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,20}$/, message: '必须英文字母数字组合', trigger: 'blur' }
                ],
                confirmPassword: [
                    { required: true, validator: this.validatePass, trigger: 'blur' }
                ]
            }
        };
    },

    methods: {
        //初始化数据
        async initData() {
            let _this = this;
            //获取会员信息
            await AxiosAjax({
                url: `/account/member`,
                success: function (res) {
                    _this.account = res.data;
                }
            });
        },
        //提交表单
        submitForm: function () {
            let _this = this; //当前页面
            //多个表单验证
            const f1 = new Promise((resolve) => {
                _this.$refs['editForm'].validate((valid) => {
                    if (valid) resolve()
                });
            });
            Promise.all([f1]).then(() => {
                AxiosAjax({
                    method: 'put',
                    url: '/account/member/password',
                    data: _this.model,
                    loading: true,
                    successMsg: '密码修改成功',
                    success: function () {
                        _this.$refs['editForm'].resetFields();//重置表单
                    }
                });
            })
        },
        validatePass: function (rule, value, callback) {
            if (value === '') {
                callback(new Error('请输入确认密码'));
            } else if (value !== this.model.newPassword) {
                callback(new Error('两次输入密码不一致'));
            } else {
                callback();
            }
        }
    },
    created() {
        this.initData()
    }
};
</script>

<style lang="scss" scoped></style>