<template>
  <div class="side-nav">
    <div class="side-item">
      <i class="iconfont1 icon-kefu"></i>
      <div class="text">客服</div>
      <div class="content kefu">
        <div class="inner">
          <div class="title">在线客服</div>
          <el-tooltip class="item" placement="top-start">
            <div slot="content">
              <img
                src="@/assets/images/wxKfQR.png"
                alt="客服二维码"
                width="200"
                height="200"
              />
            </div>
            <div class="weixin">
              <img src="@/assets/images/weixinKf.png" />
              <span class="txt">微信客服</span>
            </div>
          </el-tooltip>
        </div>
      </div>
    </div>

    <router-link to="/shop/cart" class="side-item">
      <i class="iconfont1 icon-gouwuche"></i>
      <div class="text">采购车</div>
      <span class="count">{{ count <= 999 ? count : "99+" }}</span>
    </router-link>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "AATShopSideNav",
  computed: {
    ...mapState({
      count: (state) => state.cartCount,
    }),
  },
  data() {
    return {};
  },

  mounted() {},

  methods: {},
};
</script>

<style lang="scss" scoped>
.side-nav {
  position: fixed;
  width: 40px;
  border: 1px solid rgba(255, 121, 0, 1);
  right: 0;
  top: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 99;
  .side-item {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 10px 0;
    width: 100%;
    cursor: pointer;
    gap: 7px;
    position: relative;
    &:first-child,
    &:hover {
      color: #fff;
      background-color: #ff7900;
      .count {
        border-color: #fff;
      }
    }
    .text {
      writing-mode: vertical-lr;
    }
    .count {
      border-radius: 20px;
      border: 1px solid rgba(255, 121, 0, 1);
      font-size: 12px;
      text-align: center;
      padding: 5px;
    }

    .content {
      position: absolute;
      top: 0;
      left: 0;
      z-index: -10;
      border: 1px solid #ff7900;
      background-color: #fff;
      transition: all 300ms;
      display: none;
    }

    &:hover .content {
      display: block;
      transform: translateX(-100%);
    }

    .kefu {
      width: 176px;
      padding: 7px;
      height: 100%;
      .inner {
        display: flex;
        flex-direction: column;
        .title {
          color: #000;
        }
        .weixin {
          margin-top: 10px;
          .txt {
            color: #666;
            margin-left: 5px;
            &:hover {
              color: #ff7900;
            }
          }
        }
      }
    }
  }
}
</style>
