<template>
    <div class="wrapper">
        <div class="account-box">

            <AccountNav :account="account" model="balance" />

            <div class="right-box">
                <div class="tab-box">
                    <div class="menu-box">
                        <router-link to="/account/balance/list" class="item-box">
                            <span>账户余额</span>
                        </router-link>
                        <router-link to="/account/recharge/list" class="item-box active">
                            <span>充值记录</span>
                        </router-link>
                    </div>
                </div>
                <div class="content">
                    <el-form ref="editForm" :model="model" :rules="rules" label-position="right" label-width="120px">
                        <el-form-item label="用 户 名">
                            <span>{{ account.userName }}</span>
                        </el-form-item>
                        <el-form-item label="账户余额">
                            <span>{{ account.amount }} 元</span>
                        </el-form-item>
                        <el-form-item prop="amount" label="充值金额">
                            <el-input v-model="model.amount" placeholder="请输入充值金额(元)">
                                <template slot="append">
                                    元
                                </template>
                            </el-input>
                        </el-form-item>
                        <el-form-item prop="paymentId" label="支付方式">
                            <el-select v-model="model.paymentId" placeholder="请选择支付方式">
                                <el-option v-for="item in paymentList" :key="item.id" :label="item.title" :value="item.id">
                                </el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item>
                            <el-button type="primary" @click="submitForm">确认提交</el-button>
                            <el-button @click="common.back(-1)">取消</el-button>
                        </el-form-item>
                    </el-form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import AxiosAjax from "@/utils/axios.config";
import AccountNav from "@/components/AccountNav.vue";

export default {
    name: 'AATShopRechargeAdd',
    components: {
        AccountNav
    },
    data() {
        return {
            //账户信息
            account: {},
            //表单信息
            model: {
                paymentId: null,
                amount: null
            },
            //支付列表
            paymentList: [],
            //验证规则
            rules: {
                amount: [
                    { required: true, message: '请输入操作金额', trigger: 'blur' },
                    { pattern: /(^[1-9]([0-9]+)?(\.[0-9]{1,2})?$)|(^(0){1}$)|(^[0-9]\.[0-9]([0-9])?$)/, message: '请输入正确金额，可保留两位小数', trigger: 'blur' }
                ],
                paymentId: [
                    { required: true, message: '请选择支付方式', trigger: 'change' }
                ],
            }
        };
    },

    methods: {
        //初始化数据
        async initData() {
            let _this = this;
            //获取会员信息
            await AxiosAjax({
                url: `/account/member`,
                success: function (res) {
                    _this.account = res.data;
                }
            });
            //加载支付方式
            await AxiosAjax({
                url: `/client/payment/view/10?types=pc,native`,
                success: function (res) {
                    _this.paymentList = res.data;
                }
            });
        },
        //提交表单
        submitForm() {
            let _this = this; //当前页面
            //多个表单验证
            const f1 = new Promise((resolve) => {
                _this.$refs['editForm'].validate((valid) => {
                    if (valid) resolve()
                });
            });
            Promise.all([f1]).then(() => {
                AxiosAjax({
                    method: 'post',
                    url: `/account/member/recharge`,
                    data: _this.model,
                    loading: true,
                    success: function (res) {
                        //跳转到支付页
                        window.location.href = `/payment/confirm?no=${res.data.tradeNo}`
                    }
                });

            })
        },
    },
    created() {
        this.initData()
    }
};
</script>

<style lang="scss" scoped></style>