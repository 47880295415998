/*
 *VUE通用方法
 *使用说明:$common.fn()
 */
import Vue from 'vue';
import { baseApi } from '@/utils/axios.config';
import router from '@/router';
const common = (function () {
  return {
    //全局参数
    param: {
      channelType: 0, //搜索类型
      keyword: '', //搜索关健字
      showGoodsNav: false, //显示商品类别
    },
    //文件地址拼接
    loadFile(url) {
      if (!url) return;
      if (!url.startsWith('/')) return url;
      return baseApi + url;
    },
    //跳转链接
    linkUrl(url) {
      router.push(url);
    },
    //返回上一页
    back(num) {
      // window.history.go(num);
      router.go(num)
    },
    //获取参数值
    queryValue(queryName) {
      var query = decodeURI(window.location.search.substring(1));
      var vars = query.split('&');
      for (var i = 0; i < vars.length; i++) {
        var pair = vars[i].split('=');
        if (pair[0].toLowerCase() == queryName.toLowerCase()) {
          return pair[1];
        }
      }
      return null;
    },
    //过滤HTML代码
    filterHTML: (_msg) => {
      if (!_msg) return '';
      var msg = msg.replace(/<\/?[^>]*>/g, ''); //去除HTML
      msg = msg.replace(/[|]*\n/, ''); //去除行尾空格
      msg = msg.replace(/&npsp;/gi, ''); //去掉npsp
      return msg;
    },
    //截取字符串长度
    subString: (msg, n) => {
      let str = common.filterHTML(msg);
      return str.substring(0, n);
    },
    //小写转大写
    toUppercase: (o, parName) => {
      if (o && o[parName]) {
        o[parName] = o[parName].toUpperCase();
      }
    },
    //退出登录
    logout() {
      // window.localStorage.removeItem('dt_client_access_token');
      // window.localStorage.removeItem('dt_client_refresh_token');
      window.localStorage.removeItem('dtmall_client_refresh_token');
      window.localStorage.removeItem('dtmall_client_access_token');
      //删除Cookies
      let expires = new Date();
      expires.setTime(expires.getTime() + -1 * 24 * 60 * 60 * 1000);
      document.cookie =
        "dt_client_access_token='';expires=" + expires + ';path=/';
      //跳转到登录页面
      router.push('/account/login');
    },
    //搜索查询
    doSearch() {
      let type = common.param.channelType; //获取频道类型
      let keyword = common.param.keyword; //获取关健字
      let list = JSON.parse(window.localStorage.getItem('dt_client_keywords')); //获取本地缓存

      if (!keyword || keyword == '') {
        return;
      }
      //搜索商品才存储
      if (type == 0) {
        if (list) {
          list = list.slice(0, 10); //取前10条记录
          let item = list.find((val) => val.title == keyword);
          //如果关健字不存在则存入
          if (!item) {
            list.unshift({
              type: type,
              title: keyword,
            });
            window.localStorage.setItem(
              'dt_client_keywords',
              JSON.stringify(list)
            );
          }
        } else {
          list = [
            {
              type: type,
              title: keyword,
            },
          ];
          window.localStorage.setItem(
            'dt_client_keywords',
            JSON.stringify(list)
          );
        }
      }
      //跳转到对应的列表页面
      let url = null;
      switch (type) {
        case 1:
          url = '/news/list';
          break;
        case 2:
          url = '/video/list';
          break;
        case 3:
          url = '/photo/list';
          break;
        case 4:
          url = '/down/list';
          break;
        case 5:
          url = '/shop/store/list';
          break;
        default:
          url = '/shop/goods/list';
          break;
      }
      router.push(`${url}?keyword=${encodeURI(keyword)}`);
    },
    //获取关健字
    getKeyword() {
      return JSON.parse(window.localStorage.getItem('dt_client_keywords')); //获取本地缓存
    },
  };
})();

//加入VUE属性
if (typeof Vue == 'function') Vue.prototype.common = common;

export default common;
