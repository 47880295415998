<template>
    <div class="smallImg">
        <div id="imageMenu">
            <ul :style="{
                marginLeft: offsetLeft
            }">
                <li v-for="(item, i) in goodsAlbums" :key="i" @click="clickThumb(i)"
                    :class="active == i ? 'onlickImg' : ''">
                    <div class="img-wrapper">
                        <img :src="common.loadFile(item.thumbPath)"/>
                    </div>
                </li>
            </ul>
        </div>
        <div class="scrollbutton smallImgUp" :style="{
            dispaly: this.active > this.goodsAlbums.length - 5 ? 'none' : 'block'
        }" @click="prevImg"></div>
        <div class="scrollbutton smallImgDown" :style="{
            display: this.goodsAlbums.length <= 5 ? 'none' : 'block'
        }" @click="nextImg"></div>
    </div>
</template>

<script>
export default {
    name: 'AATShopGoodsShowThumb',
    props: {
        goodsAlbums: Array,
        active: Number
    },
    data() {
        return {
            imgWidth: 70
        };
    },
    computed: {
        offsetLeft() {
            if (this.goodsAlbums.length <= 5) return 0;
            if (this.active > this.goodsAlbums.length - 5) return (5 - this.goodsAlbums.length) * this.imgWidth;
            return -this.active * this.imgWidth
        }
    },
    methods: {
        clickThumb(on) {
            this.$emit('click', on);
        },
        prevImg() {
            if (this.active == 0) return
            this.$emit('click', this.active - 1);
        },
        nextImg() {
            if (this.active == this.goodsAlbums.length - 1) return;
            this.$emit('click', this.active + 1);
        }
    },
};
</script>

<style lang="scss" scoped>
.smallImg {
    position: relative;
    height: 55px;
    margin-top: 1px;
    padding: 17px 0 10px 0;
    width: 400px;
    margin-left: 0;
    overflow: hidden;
    box-sizing: content-box;
}

.img-wrapper {
    cursor: pointer;
    display: inline-block;
}

#imageMenu {
    height: 57px;
    width: 400px;
    overflow: hidden;
    margin-left: 33px;
    float: left;

    li {
        height: 55px;
        width: 55px;
        overflow: hidden;
        float: left;
        margin-right: 15px;
        text-align: center;
        border: 2px solid #fff;

        img {
            max-width: 55px;
            height: 55px;
            cursor: pointer;
        }

        &.onlickImg {
            border: 2px solid #da3a4c !important;
        }
    }
}

.scrollbutton {
    width: 20px;
    height: 55px;
    cursor: pointer;
    position: absolute;
    top: 17px;
    opacity: 0.8;
}

.smallImgUp {
    background: url("@/assets/images/d_1102.png");
    left: 0;
}

.smallImgDown {
    background: url("@/assets/images/d_1103.png");
    right: 0;
}

ul {
    transition: all 1s;
}
</style>

