<template>
    <div>
        <!--页面内容.开始-->
        <div class="wrapper">
            <div class="location">
                <el-breadcrumb separator-class="el-icon-arrow-right">
                    <el-breadcrumb-item>首页</el-breadcrumb-item>
                    <el-breadcrumb-item>购物商城</el-breadcrumb-item>
                    <el-breadcrumb-item>积分换购</el-breadcrumb-item>
                    <el-breadcrumb-item>换购详情</el-breadcrumb-item>
                </el-breadcrumb>
            </div>
        </div>

        <div class="goods-info-box wrapper">
            <div class="goods-box">
                <div class="ginfo-box">
                    <!--幻灯片.开始-->
                    <div class="left-box">
                        <div class="swiper-gallery">
                            <div id="gallery" class="swiper-container">
                                <div class="swiper-wrapper">
                                    <div class="swiper-slide">
                                        <img :src="convert && tcommon.loadFile(convert.goodsProduct.imgUrl != null ? convert.goodsProduct.imgUrl : convert.goodsProduct.shopGoods.imgUrl)">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!--幻灯片.结束-->
                    <!--商品信息.开始-->
                    <div class="right-box" v-if="convert">
                        <div class="gtitle">{{ convert.goodsProduct.shopGoods.title }}</div>
                        <div class="stitle">{{ convert.goodsProduct.shopGoods.subTitle }}</div>
                        <div class="info-box">
                            <div class="point-box">
                                <span class="del">原价：￥{{ convert.goodsProduct.shopGoods.marketPrice }}</span>
                                <span class="text">兑换积分<b>{{ convert.point }}</b>分</span>
                            </div>
                            <div class="sale-box">
                                <span class="num">{{ convert.goodsProduct.shopGoods.saleCount }}</span>
                                <span class="text">累计销量</span>
                            </div>
                        </div>
                        <div class="promote-box">
                            <div class="list-box">
                                <span class="title">商品规格</span>
                                <div class="remark">
                                    {{ convert.goodsProduct.skuText }}
                                </div>
                            </div>
                            <div class="list-box">
                                <span class="title">库存数量</span>
                                <div class="remark">
                                    {{ convert.goodsProduct.stockQuantity }} 件
                                </div>
                            </div>
                        </div>
                        <div class="btn-box" style="margin-left:0">
                            <el-button type="danger" @click="addOrder">立即兑换</el-button>
                        </div>
                    </div>
                    <!--商品信息.结束-->
                </div>
                <!--商品介绍.开始-->
                <div class="gdetail-box">
                    <div class="head-box">
                        <div class="item-box active">
                            <span>商品介绍</span>
                        </div>
                    </div>
                    <div class="content">
                        <div class="detail" v-if="convert"
                            v-html="ReplaceImagesPath(convert.goodsProduct.shopGoods.content)"> </div>
                    </div>
                </div>
                <!--商品介绍.结束-->
            </div>
            <div class="store-box">
                <div v-if="merchantInfo" class="info-box">
                    <div class="info-header">
                        <div class="title">
                            <span v-if="merchantInfo.isSelf == 1" class="label">自营</span>
                            <span class="text">{{ merchantInfo.title }}</span>
                        </div>
                    </div>
                    <div class="row-list">
                        <div class="list-box">
                            <span class="label">关注人数</span>
                            <span class="text">{{ merchantInfo.favoriteCount }} 人</span>
                        </div>
                        <div class="list-box">
                            <span class="label">累积评价</span>
                            <span class="text">{{ merchantInfo.evaluateCount }} 次</span>
                        </div>
                        <div class="list-box">
                            <span class="label">联系电话</span>
                            <span class="text">{{ merchantInfo.mobile }}</span>
                        </div>
                    </div>
                    <div class="btn-list">
                        <el-button @click="common.linkUrl(`/shop/store/index/${convert.merchantId}`)"
                            size="mini">进店逛逛</el-button>
                        <el-button v-if="!isMerchantFocus" size="mini" @click="addMerchantFocus">关注店铺</el-button>
                        <el-button v-else size="mini" @click="addMerchantFocus">取消关注</el-button>
                    </div>
                </div>
                <div class="head-box">
                    <span>店铺推荐</span>
                </div>
                <div class="goods-list">
                    <router-link v-for="(item) in redList" :to="'/shop/goods/show/' + item.id" class="list-box"
                        :key="item.id">
                        <div class="img-box">
                            <el-image :src="common.loadFile(item.imgUrl)"></el-image>
                        </div>
                        <div class="title">
                            <span class="label">{{ item.brandTitle }}</span>
                            <span>{{ item.title }}</span>
                        </div>
                        <div class="price">
                            <span>¥</span>{{ item.sellPrice }}
                        </div>
                    </router-link>
                </div>
            </div>
        </div>
        <!--页面内容.结束-->
    </div>
</template>

<script>
import AxiosAjax from "@/utils/axios.config";
import Swiper from 'swiper';
import { ReplaceImagesPath } from "@/utils/utilsHelper";
export default {
    name: 'AATShopPointShow',

    data() {
        return {
            isMerchantFocus: false, //店铺收藏
            id: this.$route.params.id, //换购ID
            goodsId: '', //商品ID
            redList: [], //推荐商品
            merchantInfo: null, //店铺信息
            convert: null
        };
    },
    methods: {
        //初始化数据
        async initData() {
            let _this = this;
            //加载人气前5条商品列表
            let convert = null;
            await AxiosAjax({
                url: `/client/shop/convert/${_this.id}`,
                success: function (res) {
                    _this.convert = convert = res.data
                }
            })
            AxiosAjax({
                url: `/client/shop/goods/view/5?orderBy=-Click`,
                success: function (res) {
                    _this.redList = res.data;
                }
            });
            //加载店铺信息
            AxiosAjax({
                url: `/client/merchant/${convert.merchantId}`,
                success(res) {
                    _this.merchantInfo = res.data;
                }
            });
            //加载店铺收藏信息
            AxiosAjax({
                url: `/account/merchant/favorite/${convert.merchantId}`,
                success() {
                    _this.isMerchantFocus = true;
                },
            });
        },
        //立即购买
        addOrder() {
            this.common.linkUrl(`/shop/order/confirm?type=3&id=${this.id}`);
        },
        //添加/取消店铺收藏
        async addMerchantFocus() {
            let _this = this;
            if (!_this.isMerchantFocus) {
                await AxiosAjax({
                    url: `/account/merchant/favorite/${_this.convert.merchantId}`,
                    method: 'post',
                    successMsg: '店铺关注成功',
                    success: function () {
                        _this.isMerchantFocus = true;
                    },
                });
            } else {
                await AxiosAjax({
                    url: `/account/merchant/favorite/${_this.convert.merchantId}`,
                    method: 'delete',
                    successMsg: '取消关注成功',
                    success: function () {
                        _this.isMerchantFocus = false;
                    },
                });
            }
        },
        ReplaceImagesPath
    },
    mounted() {
        //商品轮换
        new Swiper('#gallery', {
            spaceBetween: 10
        });
    },
    created() {
        this.initData();
    }
};
</script>

<style lang="scss" scoped></style>