<template>
  <div class="wrapper">
    <div class="location">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item>首页</el-breadcrumb-item>
        <el-breadcrumb-item>精品推荐</el-breadcrumb-item>
        <el-breadcrumb-item>商品列表</el-breadcrumb-item>
      </el-breadcrumb>
      <div>
        总共找到<span class="find-count">{{ totalCount }}</span
        >个{{ type }}{{ category }}商品
      </div>
    </div>

    <!--商品列表.开始-->
    <div class="goods-index">
      <div class="goods-list">
        <div v-if="listData.length === 0" class="nodata">
          暂无查询到相关商品
        </div>
        <router-link
          v-for="(item, index) in listData"
          :key="index"
          :to="'/shop/goods/show/' + item.id"
          class="list-box"
        >
          <Goods
            :imgUrl="item.imgUrl"
            :title="item.merchantTitle + ' ' + item.title"
            :goodsName="item.title"
            :sellPrice="item.sellPrice"
            :publicTime="item.addTime.split(' ')[0]"
            :icons="[]"
            :storeName="item.merchantTitle"
          />
        </router-link>
      </div>

      <div class="pager-box">
        <el-pagination
          background
          :hide-on-single-page="false"
          @current-change="handleCurrentChange"
          :page-size="pageSize"
          layout="prev, pager, next"
          :total="totalCount"
        >
        </el-pagination>
      </div>
    </div>

    <!--商品列表.结束-->
  </div>
</template>

<script>
import AxiosAjax from "@/utils/axios.config";
import Goods from "@/components/Goods.vue";

export default {
  name: "AATShopSelected",
  components: {
    Goods,
  },
  data() {
    return {
      categoryId: this.$route.params.categoryId || 0,
      brandId: this.$route.query.brandId || 0, //品牌(自主品牌)
      labelId: 7, //精品的id是7
      pageSize: 20,
      pageIndex: 1,
      listData: [], //数据列表
      totalCount: 0, //总数量
      categoryList: [],
    };
  },
  computed: {
    categorychildren() {
      return this.categoryList.reduce((accumulator, currentValue) => {
        if (currentValue.children && currentValue.children.length > 0) {
          accumulator.concat(currentValue.children);
        }
      }, []);
    },
    type() {
      if (this.categoryId == 0) return "";
      const curCategoryChild = this.categorychildren.find(
        (category) => category.id == this.categoryId
      );
      if (curCategoryChild) {
        const curCategory = this.categoryList.find(
          (category) => category.id == curCategoryChild.parentId
        );
        return curCategory ? curCategory.title : "";
      }
      return "";
    },
    category() {
      if (this.categoryId == 0) return "";
      const curCategory = this.categorychildren.find(
        (category) => category.id == this.categoryId
      );
      return curCategory ? curCategory.title : "";
    },
  },
  methods: {
    //初始化数据
    async initData() {
      let _this = this;
      //加载分类
      AxiosAjax({
        url: `/client/shop/category`,
        success: function (res) {
          _this.categoryList = res.data;
        },
      });
      //加载标签
      AxiosAjax({
        url: `/client/shop/label/view/0`,
        success: function (res) {
          _this.labelList = res.data;
        },
      });
      //加载商品列表
      _this.loadData();
    },
    //加载商品列表
    async loadData() {
      let _this = this;
      let sendUrl = `/client/shop/goods?categoryId=${_this.categoryId}`;
      sendUrl += "&brandId=" + _this.brandId;
      sendUrl += "&labelId=" + _this.labelId;
      sendUrl += "&pageSize=" + _this.pageSize;
      sendUrl += "&pageIndex=" + _this.pageIndex;
      //关健字
      if (_this.common.param.keyword) {
        sendUrl += "&keyword=" + encodeURI(_this.common.param.keyword);
      }

      AxiosAjax({
        url: sendUrl,
        loading: true,
        success: function (res) {
          _this.listData = res.data;
          let pageInfo = JSON.parse(res.headers["x-pagination"]);
          _this.totalCount = pageInfo.totalCount;
          _this.pageSize = pageInfo.pageSize;
          _this.pageIndex = pageInfo.pageIndex;
        },
        error: function () {
          _this.listData = [];
        },
      });
    },
    //跳转到第几页
    handleCurrentChange: function (val) {
      if (this.pageIndex != val) {
        this.pageIndex = val;
        this.loadData();
      }
    },
  },
  created() {
    this.common.param.keyword = this.common.queryValue("keyword");
    this.initData();
  },
};
</script>

<style lang="scss" scoped>
.location {
  display: flex;
  align-items: center;
  justify-content: space-between;
  .find-count {
    color: #fb4d4f;
    font-weight: bold;
  }
}
</style>
