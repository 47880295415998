<template>
    <div class="wrapper">
        <div class="account-box">
        
            <AccountNav :account="account" model="order"/>

            <div class="right-box">
                <div class="tab-box">
                    <div class="menu-box">
                        <div class="item-box">
                            <span>申请售后</span>
                        </div>
                    </div>
                </div>
                <div class="content">
                    <div class="order-form">
                        <div class="list-box">
                            <!--货品详情.开始-->
                            <div class="goods-box">
                                <div class="img-box">
                                    <el-image fit="cover" :src="common.loadFile(goodsModel.imgUrl)"></el-image>
                                </div>
                                <div class="txt-box">
                                    <span class="title">{{ goodsModel.goodsTitle }}</span>
                                    <span class="info">{{ goodsModel.skuText }} x {{ goodsModel.quantity }}</span>
                                    <span class="price">{{ goodsModel.realPrice }}</span>
                                </div>
                            </div>
                            <!--货品详情.结束-->
                            <!--退款表单.开始-->
                            <div class="form-box">
                                <div class="item-box">
                                    <div class="left">申请类型</div>
                                    <div class="right">
                                        <el-radio-group v-model="model.type">
                                            <el-radio :label="1">退款(退款退货)</el-radio>
                                            <el-radio :label="2">换货(已收货，需要更换)</el-radio>
                                        </el-radio-group>
                                    </div>
                                </div>
                                <div class="item-box">
                                    <div class="left">申请理由</div>
                                    <div class="right">
                                        <el-input v-model="model.applyReason" type="textarea" :rows="3"
                                            placeholder="请具体描述申请原因"></el-input>
                                    </div>
                                </div>
                                <div class="item-box">
                                    <div class="left">上传凭证</div>
                                    <div class="right">
                                        <dt-upload-album v-model="model.refundAlbums" :limit="8"></dt-upload-album>
                                    </div>
                                </div>
                            </div>
                            <!--退款表单.结束-->
                        </div>

                        <div class="btn-box">
                            <el-button @click="common.back(-1)">返回上一页</el-button>
                            <el-button type="danger" @click="submitFrom">确认提交</el-button>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</template>

<script>
import AxiosAjax from "@/utils/axios.config";
import AccountNav from "@/components/AccountNav.vue";
export default {
    name: 'AATShopRefundAdd',
    components: {
        AccountNav
    },
    data() {
        return {
            productId: this.$route.params.id,
            goodsModel: {},
            model: {
                orderId: 0,
                type: 0,
                applyReason: null,
                refundGoods: [],
                refundAlbums: []
            },
            account: {}, //账户信息
        };
    },
    methods: {
        //初始化数据
        async initData(productId) {
            let _this = this;
            //加载订单信息
            await AxiosAjax({
                url: `/account/order/goods/${productId}`,
                loading: true,
                success: function (res) {
                    _this.goodsModel = res.data;
                    _this.model.orderId = _this.goodsModel.orderId;
                    _this.model.refundGoods.push({
                        orderGoodsId: _this.goodsModel.id
                    })
                }
            });
            //获取会员信息
            await AxiosAjax({
                url: `/account/member`,
                success(res) {
                    _this.account = res.data;
                }
            });
        },
        //提交申请
        async submitFrom() {
            let _this = this;
            let model = _this.model;
            if (!model.type) {
                _this.$message({
                    message: '提示，请选择售后类型',
                    type: 'warning'
                });
                return;
            }
            if (!model.applyReason) {
                _this.$message({
                    message: '提示，请填写申请理由',
                    type: 'warning'
                });
                return;
            }
            //提交表单
            await AxiosAjax({
                method: "post",
                url: `/account/order/refund`,
                data: model,
                loading: true,
                successMsg: '提交申请成功',
                success() {
                    setTimeout(() => {
                        _this.common.linkUrl('/account/refund/list');
                    }, 1500);
                }
            });
        }
    },
    created() {
        this.initData(this.productId);
    }
};
</script>

<style lang="scss" scoped></style>