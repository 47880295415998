<!-- eslint-disable vue/multi-word-component-names -->
<!-- eslint-disable vue/require-v-for-key -->
<template>
  <div class="login-box">
    <div class="wrap-box">
      <div class="left-box">
        <div class="tab-box">
          <span @click="toggleMethod(0, 'loginForm')" class="text" :class="{ active: loginMethod === 0 }">密码登录</span>
          <span @click="toggleMethod(1, 'phoneForm')" class="text" :class="{ active: loginMethod === 1 }">验证码登录</span>
        </div>
        <!--用户名密码登录.开始-->
        <el-form v-if="loginMethod === 0" :model="loginForm" :rules="rules" ref="loginForm" @submit.native.prevent>
          <el-form-item prop="userName">
            <el-input type="text" v-model="loginForm.userName" placeholder="请输入账户名" prefix-icon="el-icon-user"></el-input>
          </el-form-item>
          <el-form-item prop="password">
            <el-input type="password" v-model="loginForm.password" placeholder="请输入密码"
              prefix-icon="el-icon-lock"></el-input>
          </el-form-item>
          <el-form-item prop="codeValue">
            <el-input type="text" v-model="loginForm.codeValue" placeholder="请输入验证码" prefix-icon="el-icon-key">
              <template slot="append">
                <img @click="toggleCode" class="code" :src="imgSrc" title="点击切换验证码" />
              </template>
            </el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" class="btn" native-type="submit" :loading="btnLoading" :disabled="btnLoading"
              @click="submitForm('loginForm')">登 录</el-button>
          </el-form-item>
        </el-form>
        <!--用户名密码登录.结束-->
        <!--手机验证码登录.开始-->
        <el-form v-if="loginMethod === 1" :model="phoneForm" :rules="rules" ref="phoneForm" @submit.native.prevent>
          <el-form-item prop="phone">
            <el-input type="text" v-model="phoneForm.phone" placeholder="请输入手机号码" prefix-icon="el-icon-user"></el-input>
          </el-form-item>
          <el-form-item prop="codeValue">
            <el-input type="text" v-model="phoneForm.codeValue" placeholder="请输入手机验证码" prefix-icon="el-icon-key">
              <template slot="append">
                <el-button v-if="timer > 0">重新获取({{ timer }}s)</el-button>
                <el-button v-else @click="sendCode">获取验证码</el-button>
              </template>
            </el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" class="btn" native-type="submit" :loading="btnLoading" :disabled="btnLoading"
              @click="submitPhoneForm('phoneForm')">登 录</el-button>
          </el-form-item>
        </el-form>
        <!--手机验证码登录.结束-->
        <div class="foot-box">
          <span class="text">还没有账号？<router-link to="/account/register">马上注册</router-link></span>
          <span class="text"><router-link to="/account/reset/password">忘记密码</router-link></span>
        </div>
      </div>

      <div class="right-box">
        <div class="head-box">
          <span class="title">第三方授权登录</span>
          <span class="text">登录后即可享受更多的会员权益</span>
        </div>
        <div v-if="oauthList.length > 0" class="oauth-box">
          <div v-for="(item, index) in oauthList" :key="index" @click="redirectUrl(item.provider)" class="list-box">
            <div v-if="item.imgUrl" class="icon">
              <img :src="common.loadFile(item.imgUrl)" />
            </div>
            <span class="text">{{ item.title }}</span>
          </div>
        </div>
        <div v-else class="img-box">
          <img src="@/assets/images/img-1.png" />
        </div>
        <div class="foot-box">
          <router-link to="/home" class="icon iconfont icon-home">返回首页</router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import router from '@/router';
import AxiosAjax, { saveToken } from '@/utils/axios.config';
import common from '@/utils/vue.common.js';

export default {
  data: function () {
    return {
      timer: 0, //计时器
      btnLoading: false, //提交按钮状态
      loginMethod: 0, //登录方式0用户名密码1手机验证码
      imgSrc: null, //验证码路径
      oauthList: [], //授权平台列表
      loginForm: {
        userName: '',
        password: '',
        codeKey: null,
        codeValue: null,
      },
      phoneForm: {
        phone: null,
        codeKey: null,
        codeValue: null,
      },
      rules: {
        userName: [
          { required: true, message: '请输入登录用户名', trigger: 'blur' },
          {
            min: 3,
            max: 20,
            message: '长度在 3 到 20 个字符',
            trigger: 'blur',
          },
        ],
        password: [
          { required: true, message: '请输入登录密码', trigger: 'blur' },
          {
            min: 6,
            max: 20,
            message: '长度在 6 到 20 个字符',
            trigger: 'blur',
          },
        ],
        phone: [
          { required: true, message: '请输入手机号码', trigger: 'blur' },
          {
            pattern: /^1[0-9]{10}$/,
            message: '手机号码格式不正确',
            trigger: 'blur',
          },
        ],
        codeValue: [
          { required: true, message: '请输入验证码', trigger: 'blur' },
          {
            lmin: 4,
            max: 8,
            message: '请输入 4 到 8 位的验证码',
            trigger: 'blur',
          },
        ],
      },
    };
  },
  methods: {
    //初始化
    async initData() {
      let _this = this;
      //验证码
      await AxiosAjax({
        url: `/verifycode`,
        success: function (res) {
          _this.loginForm.codeKey = res.data.key;
          _this.imgSrc = res.data.data;
        },
      });
      //授权平台列表
      await AxiosAjax({
        url: `/client/oauth/view/0?types=web`,
        success: function (res) {
          _this.oauthList = res.data;
        },
      });
    },
    //切换注册方式
    toggleMethod(type, formName) {
      this.loginMethod = type;
      this.$nextTick(() => {
        this.$refs[formName].clearValidate();
      });
    },
    //提交密码登录表单
    submitForm(formName) {
      let _this = this;
      //表单验证
      _this.$refs[formName].validate((valid) => {
        if (valid) {
          AxiosAjax({
            url: `/auth/login`,
            method: 'post',
            data: _this.loginForm,
            beforeSend() {
              _this.btnLoading = true;
            },
            complete() {
              _this.btnLoading = false;
            },
            success(res) {
              //存储ACCESSTOKEN
              saveToken(res.data.accessToken, res.data.refreshToken);
              //跳转到管理页
              _this.$router.push('/account');
            },
          });
        } else {
          return false;
        }
      });
    },
    //切换图形验证码
    toggleCode() {
      this.initData();
    },
    //提交手机登录表单
    submitPhoneForm(formName) {
      let _this = this;
      //表单验证
      _this.$refs[formName].validate((valid) => {
        if (valid) {
          AxiosAjax({
            url: `/auth/login/phone`,
            method: 'post',
            data: _this.phoneForm,
            beforeSend() {
              _this.btnLoading = true;
            },
            complete() {
              _this.btnLoading = false;
            },
            success(res) {
              //存储ACCESSTOKEN
              saveToken(res.data.accessToken, res.data.refreshToken);
              //跳转到管理页
              _this.$router.push('/account');
            },
          });
        } else {
          return false;
        }
      });
    },
    //获取手机验证码
    sendCode() {
      let _this = this;
      if (!/(^1[3|4|5|7|8][0-9]{9}$)/.test(_this.phoneForm.phone)) {
        _this.$message({
          message: '提示，请输入正确的手机号码',
          type: 'warning',
        });
        return;
      }
      //发送短信
      AxiosAjax({
        url: `/verifycode/mobile/${_this.phoneForm.phone}`,
        success(res) {
          _this.timer = 120; //设置120秒
          _this.countDown(); //开始倒计时
          _this.phoneForm.codeKey = res.data.codeKey;
        },
      });
    },
    //获取验证码计时器
    countDown() {
      let _this = this;
      if (_this.timer > 0) {
        _this.timer--;
        setTimeout(function () {
          _this.countDown();
        }, 1000);
      }
    },
    //获取授权跳转URL
    redirectUrl(provider) {
      let href = `${window.location.protocol}//${window.location.host}/account/login?provider=${provider}`;
      let uri = encodeURIComponent(href);
      AxiosAjax({
        url: `/oauth/redirect?provider=${provider}&redirectUri=${uri}`,
        success: function (res) {
          //console.log(res.data)
          common.linkUrl(res.data);
        },
      });
    },
    //授权登录
    submitOAuth(provider, code) {
      const _this = this;
      let href = `${window.location.protocol}//${window.location.host}/account/login?provider=${provider}`;
      let uri = encodeURIComponent(href);
      //发送请求
      AxiosAjax({
        url: `/oauth/login`,
        method: 'post',
        loading: true,
        data: {
          provider: provider,
          code: code,
          redirectUri: uri,
        },
        success(res) {
          //存储ACCESSTOKEN
          saveToken(res.data.accessToken, res.data.refreshToken);
          //跳转到管理页
          _this.$router.push('/account');
        },
      });
    },
  },
  created() {
    let provider = common.queryValue('provider');
    let code = common.queryValue('code');
    if (provider && code) {
      this.submitOAuth(provider, code);
    }
    this.initData();
  },
  beforeRouteEnter(_, from, next) {
    const token = window.localStorage.getItem('dtmall_client_access_token');
    if (token) {
      if(from.fullPath == "/"){
        router.replace("/");
        next();
      }
    
    } else {
      next()
    }
  },
};
</script>
