/*
 **作者：一些事情
 **警告：版权归深圳市动力启航软件有限公司所有
 **警告：未经同意任何人或单位不得直接用于商业用途
 **注意：此文件不能独立加载，基于axios和element-ui使用
 **作用：封装axios的全局设置
 */
import Vue from 'vue';
import axios from 'axios';
import router from '@/router';
// 是否正在刷新的标记
var isRefreshing = false;
// 重试队列，每一项将是一个待执行的函数形式
var requests = [];
//WebApi接口地址
// const baseApi = 'http://47.103.98.50:8090';
// const baseApi = 'http://111.231.171.116:8090/';
const baseApi = 'https://open.aiaitu.net';
//axios实例
const instance = axios.create({
  baseURL: baseApi,
  timeout: 20000, //设置请求超时时间
  headers: {
    'content-type': 'application/json',
  },
});

//存储Token到本地
function saveToken(accessToken, refreshToken) {
  window.localStorage.setItem('dtmall_client_access_token', accessToken);
  window.localStorage.setItem('dtmall_client_refresh_token', refreshToken);
  //同时将AcccessToken存储到Cookies
  let expires = new Date();
  expires.setTime(expires.getTime() + 1 * 24 * 60 * 60 * 1000);
  document.cookie = 'dtmall_client_access_token=' + escape(accessToken) + ';expires=' + expires + ';path=/';
}
//刷新Token请求方法
function refreshToken(axiosObj, error) {
  let refreshToken = window.localStorage.getItem('dtmall_client_refresh_token');
  if (!refreshToken) {
    // window.location.href = '/account/login';
    router.push('/account/login');
  }
  //console.log('开始刷新Token...');
  const config = error.response.config;
  if (!isRefreshing) {
    isRefreshing = true;
    let isRefreshed = false;
    return instance
      .post('auth/retoken', { refreshToken: refreshToken })
      .then((res) => {
        console.log('刷新成功');
        //设置请求头
        config.headers.Authorization = 'Bearer ' + res.data.accessToken;
        //存储Token
        saveToken(res.data.accessToken, res.data.refreshToken);
        //已经刷新了token，将所有队列中的请求进行重试
        requests.forEach((cb) => cb(res.data.accessToken));
        //重试完了清空这个队列
        requests = [];
        isRefreshed = true;
        return axiosObj(config);
      })
      .catch((err) => {
        //console.log('出错了：' + isRefreshing);
        //无法刷新Token时重新登录
        if (!isRefreshed) {
          Vue.prototype.$alert('认证失效，请重新登录!', '登录过期', {
            confirmButtonText: '确认',
            callback: () => {
              window.localStorage.removeItem('dtmall_client_access_token');
              window.localStorage.removeItem('dtmall_client_refresh_token');
              // window.location.href = '/account/login';
              router.push('/account/login');
            },
          });
        }
        return Promise.reject(err);
      })
      .finally(() => {
        isRefreshing = false;
      });
  } else {
    //正在刷新token，返回一个未执行resolve的promise
    return new Promise((resolve) => {
      console.log('加入队列...');
      //将resolve放进队列，用一个函数形式来保存，等token刷新后直接执行
      requests.push((token) => {
        config.headers.Authorization = 'Bearer ' + token;
        resolve(axiosObj(config));
      });
    });
  }
}
//添加请求拦截器
instance.interceptors.request.use(
  (config) => {
    //获取token
    const token = window.localStorage.getItem('dtmall_client_access_token');
    if (token) {
      //判断是否存在token，如果存在的话，则每个http header都加上token
      config.headers.Authorization = 'Bearer ' + token;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
//添加响应拦截器
instance.interceptors.response.use(
  (response) => {
    if (response.status) {
      return Promise.resolve(response);
    } else {
      return Promise.reject(response);
    }
  },
  (error) => {
    if (error.response && error.response.status === 401 && error.response.headers['token-expired']) {
      if (parent.Vue) {
        return window.parent.refreshToken(instance, error);
      } else {
        return refreshToken(instance, error);
      }
    }
    return Promise.reject(error);
  }
);

//封装axios方法
var AxiosAjax = (params) => {
  /* 参数示例
   * AxiosAjax({method:'get',url:'auth/login',data:...});
   * @param {method,next,url,data,loading,beforeSend,progress,success,successMsg,error,complete,completeMsg}
   */
  let _this = Vue; //当前VUE实例
  //加载Loading
  let loadingInstance = params.loading ? _this.prototype.$loading({ body: true, lock: true }) : null;
  //发送请求前调用
  if (params.beforeSend) params.beforeSend();

  //定义参数配置
  let method = params.method,
    url = params.url,
    callback = (res) => {
      if (params.loading) loadingInstance.close();
      if (!res.status) {
        _this.prototype.$message({
          message: '请求失败，请重试...',
          type: 'error',
        });
      }
      //成功
      else if (res.status === 200 || res.status === 204) {
        if (params.successMsg) {
          _this.prototype.$message({
            message: params.successMsg,
            type: 'success',
          });
        }
        if (params.success) params.success(res);
      }
      //其它
      else {
        _this.prototype.$message({
          message: res.data.message,
          type: 'error',
        });
      }
      if (params.complete) params.complete();
    },
    error = (err) => {
      if (params.loading) loadingInstance.close();
      //网络错误
      if (!err.response) {
        _this.prototype.$message({
          type: 'error',
          message: '请求失败，请检查网络...',
        });
      }
      //401:没有权限
      else if (err.response.status === 401 && err.response.data.code === 10001) {
        _this.prototype.$message({
          message: '请求失败，没有操作权限',
          type: 'warning',
        });
      }
      //401:未登录
      else if (err.response.status === 401 && !err.response.data) {
        // window.location.href = '/account/login';
        router.push('/account/login');
      }
      //422:数据验证未通过
      else if (err.response.status === 422) {
        _this.prototype.$message({
          message: err.response.data.message[0][0],
          type: 'warning',
        });
      } else if (err.response.status === 403) {
        //403暂不处理
      } else if (err.response.status === 404) {
        //404暂不处理
        //console.log(err.response);
        _this.prototype.$message({
          message: err.response.data.message,
          type: 'error',
        });
      }
      //其它错误
      else {
        if (err.response.request.responseType === 'blob') {
          let fileReader = new FileReader();
          fileReader.onloadend = () => {
            let jsonData = JSON.parse(fileReader.result);
            _this.prototype.$message({
              message: jsonData.message,
              type: 'error',
            });
          };
          fileReader.readAsText(err.response.data);
        } else {
          _this.prototype.$message({
            message: typeof err.response.data != 'undefined' ? err.response.data.message : err,
            type: 'error',
          });
        }
      }
      //执行完成之后的回调函数
      if (params.error) params.error(err);
      if (params.complete) params.complete(err);
    };
  //封装Promise
  return new Promise((resolve) => {
    if (!method || method === 'get') {
      if (params.data) {
        instance
          .get(url, params.data)
          .then((res) => {
            resolve(callback(res));
          })
          .catch((err) => {
            error(err);
            if (params.next) resolve();
          });
      } else {
        instance
          .get(url)
          .then((res) => {
            resolve(callback(res));
          })
          .catch((err) => {
            error(err);
            if (params.next) resolve();
          });
      }
    } else {
      if (method === 'file') {
        // instance.headers = {
        //   'Content-Type': 'multipart/form-data',
        // };
        if (params.progress) {
          instance
            .post(url, params.data, {
              headers: { 'Content-Type': 'multipart/form-data' },
              onUploadProgress: params.progress
            })
            .then((res) => {
              resolve(callback(res));
            })
            .catch((err) => {
              error(err);
              if (params.next) resolve();
            });
        } else {
          instance
            .post(url, params.data, { headers: { 'Content-Type': 'multipart/form-data' }, })
            .then((res) => {
              resolve(callback(res));
            })
            .catch((err) => {
              error(err);
              if (params.next) resolve();
            });
        }
      } else if (method === 'down') {
        instance
          .get(url, {
            responseType: 'blob',
          })
          .then((res) => {
            resolve(callback(res));
          })
          .catch((err) => {
            error(err);
            if (params.next) resolve();
          });
      } else if (method === 'post') {
        instance
          .post(url, params.data)
          .then((res) => {
            resolve(callback(res));
          })
          .catch((err) => {
            error(err);
            if (params.next) resolve();
          });
      } else if (method === 'delete') {
        instance
          .delete(url, params.data)
          .then((res) => {
            resolve(callback(res));
          })
          .catch((err) => {
            error(err);
            if (params.next) resolve();
          });
      } else if (method === 'put') {
        instance
          .put(url, params.data)
          .then((res) => {
            resolve(callback(res));
          })
          .catch((err) => {
            error(err);
            if (params.next) resolve();
          });
      } else if (method === 'patch') {
        instance
          .patch(url, params.data)
          .then((res) => {
            resolve(callback(res));
          })
          .catch((err) => {
            error(err);
            if (params.next) resolve();
          });
      }
    }
  });
};

export { baseApi, saveToken };
export default AxiosAjax;
