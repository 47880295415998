<template>
  <div class="wrapper">
    <div class="account-box">
      <AccountNav :account="account" model="address" />
      <div class="right-box">
        <div class="tab-box">
          <div class="menu-box">
            <div class="item-box active">
              <span>收货地址</span>
            </div>
          </div>
        </div>
        <div class="content">
          <div class="address-list">
            <div
              v-for="(item, index) in listData"
              :key="index"
              class="list-box"
            >
              <span class="name">{{ item.acceptName }}</span>
              <span class="mobile">{{ item.mobile }}</span>
              <span class="address">{{ item.address }}</span>
              <div v-if="item.isDefault" class="label">默认</div>
              <div class="edit-box">
                <router-link
                  :to="'/account/address/edit/' + item.id"
                  class="text"
                  >修改</router-link
                >
                <span @click="deleteItem(item.id)" class="text">删除</span>
              </div>
            </div>
            <router-link to="/account/address/edit" class="add-box">
              <span class="icon iconfont icon-add"></span>
              <span class="text">添加</span>
            </router-link>
          </div>

          <div class="pager-box">
            <el-pagination
              background
              :hide-on-single-page="true"
              @current-change="handleCurrentChange"
              :page-size="pageSize"
              layout="prev, pager, next"
              :total="totalCount"
            >
            </el-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AccountNav from "@/components/AccountNav.vue";
import AxiosAjax from "@/utils/axios.config";
export default {
  name: "AATShopAddressList",
  components: {
    AccountNav,
  },
  data() {
    return {
      account: null, //会员信息
      totalCount: 0, //总数量
      pageSize: 10, //每页显示条数
      pageIndex: 1, //当前页码
      listData: [], //数据列表
    };
  },
  methods: {
    //加载列表
    async loadData() {
      let _this = this;
      AxiosAjax({
        url: `/account/member/address/list?pageSize=${_this.pageSize}&pageIndex=${_this.pageIndex}`,
        loading: true,
        success: function (res) {
          _this.listData = res.data;
          let pageInfo = JSON.parse(res.headers["x-pagination"]);
          _this.totalCount = pageInfo.totalCount;
          _this.pageSize = pageInfo.pageSize;
          _this.pageIndex = pageInfo.pageIndex;
        },
        error: function () {
          _this.listData = [];
        },
      });
    },
    //初始化数据
    async initData() {
      let _this = this;
      //加载列表
      await _this.loadData();
      //加载会员信息
      await AxiosAjax({
        url: `/account/member`,
        success: function (res) {
          _this.account = res.data;
        },
      });
    },
    //跳转到第几页
    handleCurrentChange(val) {
      if (this.pageIndex != val) {
        this.pageIndex = val;
        this.loadData();
      }
    },
    //删除地址
    deleteItem(id) {
      let _this = this;
      _this
        .$confirm("确认要删除该记录吗？", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
        .then(() => {
          AxiosAjax({
            method: "delete",
            url: `/account/member/address/${id}`,
            loading: true,
            successMsg: "已删除成功",
            success: function () {
              _this.pageIndex = 1;
              _this.totalCount = 0;
              _this.loadData();
            },
          });
        })
        .catch(() => {});
    },
  },
  created() {
    this.initData();
  },
};
</script>

<style lang="scss" scoped></style>
