<template>
  <div>
    <div class="wrapper">
      <div class="location">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item>首页</el-breadcrumb-item>
          <el-breadcrumb-item>订单结算</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
    </div>
    <!--循环列表.开始-->
    <div class="wrapper">
      <div class="order-confirm">
        <div class="screen-box">
          <div class="nav-bar">收货地址</div>
          <div class="address-list">
            <div
              v-for="(item, index) in addressList"
              :key="index"
              :class="{ active: useAddress.id && useAddress.id === item.id }"
              @click="addressSelected(item)"
              class="list-box"
            >
              <span class="name">{{ item.acceptName }}</span>
              <span class="mobile">{{ item.mobile }}</span>
              <span class="address">{{ item.address }}</span>
              <div v-if="item.isDefault" class="label">默认</div>
            </div>
            <router-link to="/account/address/edit" class="add-box">
              <span class="icon iconfont icon-add"></span>
              <span class="text">添加</span>
            </router-link>
          </div>
        </div>

        <div class="screen-box">
          <div class="nav-bar">订单信息</div>
          <div class="tips">
            <p>提示：1.本次采购属于商业贸易行为，不适用7天无理由退换政策</p>
            <p>2.应商户无货导致发货超时问题，本网站不承担责任</p>
          </div>
          <div class="goods-box">
            <div
              v-for="(item, index) in cartTotal.list"
              :key="index"
              class="list-wrap"
            >
              <div class="list-head">
                <span v-if="item.isSelf == 1" class="label">自营</span>
                <span class="text">{{ item.merchantTitle }}</span>
              </div>
              <div
                v-for="(gitem, gindex) in item.goods"
                :key="gindex"
                class="list-box"
              >
                <div class="img-box">
                  <img :src="common.loadFile(gitem.imgUrl)" />
                </div>
                <div class="txt-box">
                  <span class="title">{{ gitem.title }}</span>
                  <span class="info">{{ gitem.skuText }}</span>
                  <span class="price">{{ gitem.groupPrice }}</span>
                </div>
                <div class="num-box">x {{ gitem.quantity }}</div>
              </div>
              <div class="list-foot">
                <div
                  v-if="orderType == 0 && item.shopCoupons.length > 0"
                  class="item-box"
                >
                  <div class="left-box">
                    <span>优 惠 券</span>
                  </div>
                  <div class="center-box">
                    <el-select
                      clearable
                      v-model="item.couponId"
                      placeholder="请选择优惠券"
                      @change="selectCoupon"
                    >
                      <el-option
                        v-for="(citem, cindex) in item.shopCoupons"
                        :key="cindex"
                        :label="citem.title + '(￥' + citem.amount + '元)'"
                        :value="citem.id"
                      >
                      </el-option>
                    </el-select>
                  </div>
                  <div v-if="item.couponAmount > 0" class="right-box">
                    -￥{{ item.couponAmount }}
                  </div>
                </div>
                <div class="item-box">
                  <div class="left-box">
                    <span>配送方式</span>
                  </div>
                  <div class="center-box">
                    <el-select
                      clearable
                      v-model="item.deliveryId"
                      placeholder="请选择配送方式"
                      @change="selectDelivery"
                    >
                      <el-option
                        v-for="(citem, cindex) in item.shopDeliverys"
                        :key="cindex"
                        :label="citem.title"
                        :value="citem.id"
                      >
                      </el-option>
                    </el-select>
                  </div>
                  <div v-if="item.freightAmount > 0" class="right-box">
                    快递费：+￥{{ item.freightAmount }}
                  </div>
                </div>
                <div class="item-box">
                  <div class="width-full">
                    <div class="flex justify-between">
                      <div class="color-gray font1">商品服务：</div>
                      <div>
                        服务费合计：￥<span>{{ item.realServices }}</span>
                      </div>
                    </div>
                    <el-table :data="item.services" style="width: 100%">
                      <el-table-column label="商品服务" width="100">
                        <template slot-scope="scope">
                          <div>
                            <span>
                              {{ scope.row.typeName }}
                            </span>
                            <el-tooltip
                              class="item"
                              effect="dark"
                              :content="scope.row.instruction"
                              placement="right-start"
                            >
                              <i class="el-icon-question"></i>
                            </el-tooltip>
                          </div>
                        </template>
                      </el-table-column>
                      <el-table-column>
                        <template slot-scope="scope">
                          <div class="flex gap1">
                            <div
                              v-for="(service, i) in scope.row.shopServices"
                              :key="service.id"
                              :class="[
                                'service-item',
                                service.checked ? 'active' : '',
                              ]"
                              @click="checkService(scope.row, i, item)"
                            >
                              {{ service.title }}
                              <i class="icon-checked"></i>
                            </div>
                          </div>
                        </template>
                      </el-table-column>
                      <el-table-column label="单价">
                        <template slot-scope="scope">
                          <div class="color-gray">￥ {{ scope.row.price }}</div>
                        </template>
                      </el-table-column>
                      <el-table-column label="数量">
                        <template slot-scope="scope">
                          <el-input-number
                            v-model="scope.row.num"
                            :min="1"
                            :step="1"
                            @change="
                              (currentValue) =>
                                changeServiceNum(currentValue, scope.row)
                            "
                          ></el-input-number>
                        </template>
                      </el-table-column>
                      <el-table-column label="小计" width="150">
                        <template slot-scope="scope">
                          <div class="color-red">￥ {{ scope.row.total }}</div>
                        </template>
                      </el-table-column>
                    </el-table>
                  </div>
                </div>
                <div class="item-box">
                  <div class="left-box">
                    <span>留言备注</span>
                  </div>
                  <div class="center-box">
                    <el-input
                      v-model="item.postscript"
                      type="textarea"
                      rows="2"
                      placeholder="请输入内容"
                      maxlength="250"
                      show-word-limit
                    ></el-input>
                  </div>
                </div>
                <div v-if="item.promotion" class="item-box">
                  <div class="left-box">
                    <span>促销活动</span>
                  </div>
                  <div class="center-box">
                    <span>{{ item.promotion.title }}</span>
                  </div>
                  <div v-if="item.promotionAmount > 0" class="right-box">
                    -￥{{ item.promotionAmount }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="screen-box">
          <div class="item-wrap">
            <div class="item-box">
              <div class="left-box">
                <span>支付方式</span>
              </div>
              <div class="center-box">
                <el-radio-group v-model="paymentId" @change="selectPayment">
                  <el-radio
                    v-for="(item, index) in paymentList"
                    :key="index"
                    :label="item.id"
                    >{{ item.title }}</el-radio
                  >
                </el-radio-group>
              </div>
            </div>
          </div>

          <div class="total-box">
            <span v-if="orderType === 3" class="item"
              >兑换积分<b class="point">-{{ cartTotal.totalPoint }}</b> 分</span
            >
            <span v-if="orderType < 3" class="item"
              >商品金额<b class="price">{{ cartTotal.totalRealAmount }}</b>
              元</span
            >
            <span v-if="cartTotal.totalFreightAmount > 0" class="item"
              >总运费<b class="price">{{ cartTotal.totalFreightAmount }}</b>
              元</span
            >
            <span v-if="cartTotal.totalPromotionAmount > 0" class="item"
              >活动促销<b class="price"
                >-{{ cartTotal.totalPromotionAmount }}</b
              >
              元</span
            >
            <span v-if="cartTotal.totalCouponAmount > 0" class="item"
              >优惠券抵<b class="price">-{{ cartTotal.totalCouponAmount }}</b>
              元</span
            >
            <span v-if="cartTotal.totalServices > 0" class="item"
              >总服务费<b class="price">-{{ cartTotal.totalServices }}</b>
              元</span
            >
          </div>

          <div class="amount-box">
            <span v-if="orderType == 2">预售定金：</span>
            <span v-else>实付金额：</span>
            <span class="price">{{ cartTotal.totalOrderAmount }}</span>
          </div>

          <div class="btn-box">
            <el-button @click="common.back(-1)">返回上一页</el-button>
            <el-button type="danger" @click="saveOrder()">提交订单</el-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AxiosAjax from "@/utils/axios.config";
import Vue from "vue";

export default {
  name: "AATShopOrderConfirm",

  data() {
    return {
      orderType: 0, //订单类型
      activeId: 0, //当订单类型为1时抢购ID，为2时换购ID
      paymentId: 0, //当前支付方式ID
      currTotalList: {}, //当前编辑行
      goodsData: null, //普通订单的货品传参
      useAddress: null, //收货地址
      usePayment: {}, //已选支付方式
      cartTotal: {}, //统计列表
      paymentList: [], //支付列表
      addressList: [], //收货地址列表
    };
  },

  methods: {
    //初始化数据
    async initData() {
      let _this = this;
      //如果是普通订单
      if (_this.orderType === 0) {
        //加载商品列表
        await AxiosAjax({
          url: `/account/shop/cart/buy?jsonData=${_this.goodsData}`,
          success(res) {
            _this.cartTotal = res.data;
          },
        });
      }
      //抢购商品
      else if (_this.orderType === 1) {
        //加载商品列表
        await AxiosAjax({
          url: `/account/shop/speed/buy/${_this.activeId}`,
          success(res) {
            _this.cartTotal = res.data;
          },
        });
      }
      //预售商品
      else if (_this.orderType === 2) {
        //加载商品列表
        await AxiosAjax({
          url: `/account/shop/presell/buy/${_this.activeId}`,
          success(res) {
            _this.cartTotal = res.data;
          },
        });
      }
      //积分换购
      else if (_this.orderType === 3) {
        //加载商品列表
        await AxiosAjax({
          url: `/account/shop/convert/buy/${_this.activeId}`,
          success(res) {
            _this.cartTotal = res.data;
          },
        });
      }
      //重新赋值优惠券和配送为空
      _this.cartTotal.list?.forEach((item) => {
        item.couponId = null;
        item.deliveryId = null;
      });

      //加载支付方式列表
      AxiosAjax({
        url: `/client/payment/view/10?types=balance,pc,native`,
        success(res) {
          _this.paymentList = res.data;
        },
      });
      //加载收货地址
      AxiosAjax({
        url: `/account/member/address/view/20`,
        success(res) {
          _this.addressList = res.data;
          let obj = _this.addressList.find((val) => val.isDefault === 1);
          if (obj) {
            _this.useAddress = obj;
          }
        },
      });

      //加载商品服务
      AxiosAjax({
        url: `/client/shop/services/view/0`,
        async success(res) {
          for (let i = 0; i < _this.cartTotal.list.length; i++) {
            const tableData = res.data.map(async (item) => {
              item.price = 0;
              item.num = 1;
              item.total = 0;
              for (let i = 0; i < item.shopServices.length; i++) {
                const element = item.shopServices[i];
                if (i === 0) {
                  element.checked = true;
                  await AxiosAjax({
                    url: `/client/shop/services/price?weight=${_this.cartTotal.list[i].totalWeight}&servicesId=${element.id}`,
                    method: "get",
                    loading: true,
                    success(res) {
                      item.price = res.data;
                      item.total = res.data * item.num;
                    },
                  });
                } else {
                  element.checked = false;
                }
              }
              return item;
            });

            const services = await Promise.all(tableData);
            Vue.set(_this.cartTotal.list[i], "services", services);
            Vue.set(_this.cartTotal.list[i], "realServices", 0);
            _this.totalAmount();
          }
        },
      });
    },
    //统计订单金额
    totalAmount() {
      let _this = this;
      let totalFreightAmount = 0;
      let totalCouponAmount = 0;
      let totalService = 0;
      _this.cartTotal.list.forEach((item) => {
        totalFreightAmount += item.freightAmount;
        totalCouponAmount += item.couponAmount;
      });
      _this.cartTotal.totalFreightAmount = totalFreightAmount;
      _this.cartTotal.totalCouponAmount = totalCouponAmount;
      _this.cartTotal.list.forEach((item) => {
        item.realServices = 0;
        for (let i = 0; i < item.services.length; i++) {
          item.realServices += item.services[i].total;
          totalService += item.services[i].total;
        }
      });
      //如果不是预售订单则需要计算总费用
      if (_this.orderType != 2) {
        //订单总金额=商品实付金额-促销金额-优惠券金额+运费
        _this.cartTotal.totalOrderAmount = (
          parseFloat(_this.cartTotal.totalRealAmount) -
          parseFloat(_this.cartTotal.totalPromotionAmount) -
          parseFloat(totalCouponAmount) +
          parseFloat(totalFreightAmount) +
          parseFloat(totalService)
        ).toFixed(2);
      }
    },
    //选择优惠券
    selectCoupon() {
      let _this = this;
      _this.cartTotal.list.forEach((item) => {
        item.couponAmount = 0;
        item.couponTitle = null;
        var obj = item.shopCoupons.find((val) => val.id === item.couponId);
        if (obj) {
          item.couponAmount = obj.amount;
          item.couponTitle = obj.title;
        }
      });
      //重新统计
      _this.totalAmount();
    },
    //选择地址
    async addressSelected(item) {
      let _this = this;
      _this.useAddress = item;
      //重新计算配送费用
      await _this.selectDelivery();
      //   if (_this.deliveryId > 0) {
      //     await _this.selectDelivery();
      //   }
    },
    //选择配送方式
    async selectDelivery() {
      let _this = this;
      //检查是否选择了地址
      if (!_this.useAddress || !_this.useAddress.province) {
        _this.$message({
          message: "提示，请先选择收货地址",
          type: "warning",
        });
        return;
      }
      //遍历所有商家
      _this.cartTotalList();
      // _this.cartTotal.list.forEach((item) => {
      //   item.freightAmount = 0;
      //   item.deliveryTitle = null;
      //   var deliveryObj = item.shopDeliverys.find(
      //     (val) => val.id === item.deliveryId
      //   );

      //   if (deliveryObj) {
      //     item.freightAmount = deliveryObj.amount;
      //     item.couponTitle = deliveryObj.title;

      //     //计算配送费用,如果商品全免运费则配送费为0
      //     if (
      //       (item.promotion && item.promotion.awardType == 6) ||
      //       item.freeFreight == 1
      //     ) {
      //       item.freightAmount = 0;
      //     } else {
      //       //计算配送费用
      //       let totalWeight =
      //         item.totalWeight > 1000 ? Math.ceil(item.totalWeight / 1000) : 1;
      //       let firstPrice = deliveryObj.firstPrice; //首重费用
      //       let secondPrice = deliveryObj.secondPrice; //续重费用
      //       let totalSecondPrice = 0; //续重总费用
      //       //查询是否是设置了个别地区
      //       let areaObj = deliveryObj.deliveryAreas.find(
      //         (val) => val.province == _this.useAddress.province
      //       );
      //       if (areaObj) {
      //         firstPrice = areaObj.firstPrice;
      //         secondPrice = areaObj.secondPrice;
      //       }
      //       //如果总重量大于首重才计算续重费用
      //       if (totalWeight > deliveryObj.firstWeight) {
      //         //续重重量=总重量-首重量
      //         let secondWeight = totalWeight - deliveryObj.firstWeight;
      //         //向上取整，只要有小数都加1
      //         //续重费用=(续重重量/续重量)*续重价格
      //         totalSecondPrice =
      //           Math.ceil(secondWeight / deliveryObj.secondWeight) *
      //           secondPrice;
      //       }
      //       //总运费=首重费用+续重费用+保价费用
      //       item.freightAmount = firstPrice + totalSecondPrice;
      //     }
      //   }
      // });
      //重新统计
      _this.totalAmount();
    },
    cartTotalList() {
      //遍历所有商家
      const _this = this;
      this.cartTotal.list.forEach((item) => {
        item.freightAmount = 0;
        item.deliveryTitle = null;
        var deliveryObj = item.shopDeliverys.find(
          (val) => val.id === item.deliveryId
        );

        if (deliveryObj) {
          item.freightAmount = deliveryObj.amount;
          item.couponTitle = deliveryObj.title;

          //计算配送费用,如果商品全免运费则配送费为0
          if (
            (item.promotion && item.promotion.awardType == 6) ||
            item.freeFreight == 1
          ) {
            item.freightAmount = 0;
          } else {
            //计算配送费用
            let totalWeight =
              item.totalWeight > 1000 ? Math.ceil(item.totalWeight / 1000) : 1;
            let firstPrice = deliveryObj.firstPrice; //首重费用
            let secondPrice = deliveryObj.secondPrice; //续重费用
            let totalSecondPrice = 0; //续重总费用
            let firstWeight = deliveryObj.firstWeight;
            //查询是否是设置了个别地区
            let areaObj = deliveryObj.deliveryAreas.find(
              (val) => val.province == _this.useAddress.province
            );

            if (areaObj) {
              firstPrice = areaObj.firstPrice;
              secondPrice = areaObj.secondPrice;
            }

            //如果设置了，遍历是否在固定价格区间内
            if (areaObj && areaObj.deliveryAreasPrice.length > 0) {
              for (let i = 0; i < areaObj.deliveryAreasPrice.length; i++) {
                const areaPrice = areaObj.deliveryAreasPrice[i];
                const minWeight = parseFloat(areaPrice.minWeight);
                const maxWeight = parseFloat(areaPrice.maxWeight);
                if (maxWeight > firstWeight) {
                  firstWeight = maxWeight;
                  firstPrice = parseFloat(areaPrice.price);
                }
                if (totalWeight >= minWeight && totalWeight <= maxWeight) {
                  item.freightAmount = parseFloat(areaPrice.price);
                  return;
                }
              }
            }
            //如果总重量大于首重才计算续重费用
            if (totalWeight > firstWeight) {
              //续重重量=总重量-首重量
              let secondWeight = totalWeight - firstWeight;
              //向上取整，只要有小数都加1
              //续重费用=(续重重量/续重量)*续重价格
              totalSecondPrice =
                Math.ceil(secondWeight / deliveryObj.secondWeight) *
                secondPrice;
            }
            //总运费=首重费用+续重费用+保价费用
            item.freightAmount = firstPrice + totalSecondPrice;
          }
        }
      });
    },
    //选择支付方式
    selectPayment() {
      let _this = this;
      let item = _this.paymentList.find((val) => val.id === _this.paymentId);
      _this.usePayment = item;
    },
    //保存订单
    async saveOrder() {
      let _this = this;
      //检查收货地址
      if (!_this.useAddress) {
        _this.$message({
          message: "提示，请选择收货地址",
          type: "warning",
        });
        return false;
      }
      //检查配送方式
      if (_this.cartTotal.list.find((val) => !val.deliveryId)) {
        _this.$message({
          message: "提示，请选择配送方式",
          type: "warning",
        });
        return false;
      }
      //检查支付方式
      if (!_this.paymentId) {
        _this.$message({
          message: "提示，请选择支付方式",
          type: "warning",
        });
        return false;
      }
      //检查商品列表
      if (!_this.cartTotal.list) {
        _this.$message({
          message: "提示，结算商品不能为空",
          type: "warning",
        });
        return false;
      }

      //开始保存订单
      let model = {};
      model.orderType = _this.orderType; //0普通订单1抢购订单2换购订单
      model.activeId = _this.activeId; //抢购ID或换购ID
      model.paymentId = _this.paymentId;
      model.acceptName = _this.useAddress.acceptName;
      model.mobile = _this.useAddress.mobile;
      model.province = _this.useAddress.province;
      model.city = _this.useAddress.city;
      model.area = _this.useAddress.area;
      model.address = _this.useAddress.address;
      model.orderList = [];
      //循环列表
      _this.cartTotal.list.forEach((item) => {
        let modelt = {};
        let orderGoods = [];
        modelt.merchantId = item.merchantId;
        modelt.activeId = _this.activeId;
        modelt.deliveryId = item.deliveryId;
        modelt.useCouponId = item.couponId;
        modelt.isInsure = 0;
        modelt.insurePrice = 0;
        modelt.postscript = item.postscript;
        modelt.realServices = item.realServices;
        //modelt.acceptTime = item.acceptTime;
        item.goods.forEach((gitem) => {
          orderGoods.push({
            productId: gitem.productId,
            quantity: gitem.quantity,
          });
        });
        modelt.orderGoods = orderGoods;
        //添加进订单列表
        model.orderList.push(modelt);
      });

      //提交订单
      AxiosAjax({
        url: `/account/order`,
        method: "post",
        data: model,
        loading: true,
        success(res) {
          //判断支付方式是否货到付款
          if (_this.usePayment.providerType == 0) {
            _this
              .$confirm("订单提交成功，是否查看订单详情？", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
              })
              .then(() => {
                window.location.href = `/shop/order/show/${res.data.id}`;
              })
              .catch(() => {
                window.location.href = `/home`;
              });
          } else {
            window.location.href = `/payment/confirm?no=${res.data.tradeNo}`;
          }
        },
      });
    },
    checkService(item, i, order) {
      const _this = this;
      item.shopServices.forEach((it) => (it.checked = false));
      item.shopServices[i].checked = true;

      AxiosAjax({
        url: `/client/shop/services/price?weight=${order.totalWeight}&servicesId=${item.shopServices[i].id}`,
        method: "get",
        loading: true,
        success(res) {
          item.price = res.data;
          item.total = res.data * item.num;
          _this.totalAmount();
        },
      });
    },
    changeServiceNum(currentValue, item) {
      item.total = currentValue * item.price;
      this.totalAmount();
    },
  },
  created() {
    const _this = this;
    this.orderType = parseInt(_this.common.queryValue("type"));
    if (this.orderType > 0) {
      //抢购预售换购订单
      this.activeId = parseInt(this.common.queryValue("id"));
    } else {
      //普通订单
      this.goodsData = _this.common.queryValue("data");
    }
    this.initData();
  },
};
</script>

<style lang="scss" scoped>
.tips {
  padding: 0 30px 0;
}
.width-full {
  width: 100%;
}
.color-gray {
  color: #999;
}
.font1 {
  font-size: 16px;
}
.service-item {
  border: 1px solid red;
  border-radius: 10px;
  position: relative;
  padding: 5px 20px;
  color: red;
  overflow: hidden;
  cursor: pointer;
  .icon-checked {
    display: none;
    position: absolute;
    top: 0;
    right: 0;
    width: 30px;
    height: 30px;
    background: url("../../assets/images/checked.svg") 0 0 / contain no-repeat;
  }
  &.active {
    .icon-checked {
      display: block;
    }
  }
}
.gap1 {
  gap: 10px;
}
.color-red {
  color: red;
}
</style>
