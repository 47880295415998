<template>
  <div>
    <div class="header-top">
      <div class="wrapper">
        <div class="left-box">
          <router-link to="/home"
            ><span class="icon iconfont icon-home"></span>首页</router-link
          >
        </div>
        <div class="right-box">
          <router-link to="/account"
            ><span class="icon iconfont icon-user"></span>会员中心</router-link
          >
          <router-link to="/account/store/settled"
            ><span class="icon iconfont icon-address-full"></span
            >申请入驻</router-link
          >
          <el-dropdown>
            <a href="#" class="text-3">
              更多服务<i class="el-icon-arrow-down el-icon--right"></i>
            </a>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item>
                <router-link to="/account/article?article=1"
                  >代理商必读</router-link
                >
              </el-dropdown-item>
              <el-dropdown-item>
                <router-link to="/account/article?article=2"
                  >快递价格</router-link
                >
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </div>
    </div>
    <div class="goods-header">
      <div class="wrapper">
        <div class="left-box">
          <router-link to="/home">
            <img src="../assets/images/logo.png" />
          </router-link>
        </div>
        <div class="center-box">
          <ul class="search-type">
            <li
              :class="{ 'search-type-item': true, active: searchType == 0 }"
              @click="changeSearchTypeHandle(0)"
            >
              商品
            </li>
            <li
              :class="{ 'search-type-item': true, active: searchType == 5 }"
              @click="changeSearchTypeHandle(5)"
            >
              店铺
            </li>
          </ul>
          <div class="search-box">
            <span class="icon iconfont icon-search"></span>
            <input
              v-model="common.param.keyword"
              type="text"
              class="input"
              :placeholder="searchPlaceholder"
              @keyup.enter="common.doSearch()"
            />
            <div class="btn" @click="common.doSearch()">搜索</div>
          </div>
          <div class="hot-box">
            <router-link
              v-for="(item, index) in common.getKeyword()"
              :key="index"
              :to="'/shop/goods/list?keyword=' + encodeURI(item.title)"
              class="text"
              >{{ item.title }}</router-link
            >
          </div>
        </div>
        <div class="right-box">
          <router-link to="/shop/cart">
            <i class="cart"></i>
            <page-cart-count class="text"></page-cart-count>
          </router-link>
        </div>
      </div>
    </div>
    <div class="goods-nav">
      <div class="wrapper">
        <page-category-nav
          title="商品分类"
          link="/shop/goods/list/"
          :active="showGoodsNav"
        ></page-category-nav>
        <router-link to="/home" class="item">首页</router-link>
        <router-link to="/shop/goods/list" class="item">每日新款</router-link>
        <!-- <router-link to="/shop/selected/list" class="item"
          >精品推荐</router-link
        > -->
        <!-- <router-link to="/shop/new_years_goods/list" class="item"
          >年货专区</router-link
        > -->
        <router-link to="/shop/copyright/list" class="item"
          >版权专区</router-link
        >
        <router-link to="/shop/wedding/list" class="item">婚庆专区</router-link>
        <router-link to="/shop/ai/list" class="item">AI专区</router-link>
        <router-link to="/shop/rent_out/list" class="item">信息发布</router-link>
        <router-link to="/shop/live_room/list" class="item">共享直播间</router-link>
        <!-- <router-link to="/shop/contribution/list" class="item"
          >投稿专区</router-link
        > -->
        <!-- <router-link to="/shop/sale/list" class="item">限时抢购</router-link>
        <router-link to="/shop/presell/list" class="item">商品预售</router-link>
        <router-link to="/shop/point/list" class="item">积分换购</router-link> -->
      </div>
    </div>
    <SideNav />
  </div>
</template>

<script>
import SideNav from "@/components/SideNav.vue";
export default {
  components: {
    SideNav,
  },
  data() {
    return {
      showGoodsNav: false,
      searchType: 0,
    };
  },
  methods: {
    changeSearchTypeHandle(searchType) {
      this.searchType = searchType;
    },
  },
  computed: {
    searchPlaceholder() {
      if (this.searchType == 0) return "请输入关键字（如：毛绒玩具、抱枕靠枕）";
      else if (this.searchType == 5) return "请输入关键字（如：店铺名称）";
      return "搜索商品";
    },
  },
  watch: {
    $route: {
      handler(to) {
        if (to.path == "/home") {
          this.showGoodsNav = true;
        } else {
          this.showGoodsNav = false;
        }
      },
      immediate: true,
    },
    searchType(value) {
      this.common.param.channelType = value;
    },
  },
};
</script>

<style scoped>
.text-3 {
  font-size: 12px;
}
</style>
