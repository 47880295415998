<template>
  <span> 购物车 ({{ count }}) </span>
</template>

<script>
import { mapActions, mapState } from 'vuex'
export default {
  computed: {
    ...mapState({
      count: state => state.cartCount
    })
  },
  methods: {
    //初始化数据
    ...mapActions({
      initData: "getCartCount"
    })
  },
  created() {
    this.initData();
  },
};
</script>
