import Vue from 'vue';
import VueRouter from 'vue-router';
import AppHome from './views/AppHome.vue';
import Login from './views/Account/Login.vue';
import Account from './views/Account/Index.vue';
import AddressEdit from './views/Account/AddressEdit.vue';
import AddressList from './views/Account/AddressList.vue';
import BalanceList from './views/Account/BalanceList.vue';
import CouponList from './views/Account/CouponList.vue';
import EvaluateEdit from './views/Account/EvaluateEdit.vue';
import FavoriteList from './views/Account/FavoriteList.vue';
import OrderDelivery from './views/Account/OrderDelivery.vue';
import OrderList from './views/Account/OrderList.vue';
import OrderShow from './views/Account/OrderShow.vue';
import RechargeAdd from './views/Account/RechargeAdd.vue';
import RechargeList from './views/Account/RechargeList.vue';
import RefundAdd from './views/Account/RefundAdd.vue';
import RefundList from './views/Account/RefundList.vue';
import RefundShow from './views/Account/RefundShow.vue';
import Register from './views/Account/Register.vue';
import ResetPassword from './views/Account/ResetPassword.vue';
import SettingInfo from './views/Account/SettingInfo.vue';
import SettingPassword from './views/Account/SettingPassword.vue';
import StoreSettled from './views/Account/StoreSettled.vue';
import InfoList from './views/Account/InfoList.vue';
import Confirm from './views/Payment/Confirm.vue';
import Result from './views/Payment/Result.vue';
import Cart from './views/Shop/Cart.vue';
import List from './views/Shop/List.vue';
import OrderConfirm from './views/Shop/OrderConfirm.vue';
import PointList from './views/Shop/PointList.vue';
import PointShow from './views/Shop/PointShow.vue';
import PresellList from './views/Shop/PresellList.vue';
import SaleList from './views/Shop/SaleList.vue';
import Show from './views/Shop/Show.vue';
import StoreIndex from './views/Shop/StoreIndex.vue';
import StoreList from './views/Shop/StoreList.vue';
import Selected from "./views/Shop/Selected.vue";
import Wedding from "./views/Shop/Wedding.vue";
import Copyright from "./views/Shop/Copyright.vue";
import NewYearsGoods from "./views/Shop/NewYearsGoods.vue";
import MessageList from "./views/Account/MessageList.vue";
import StoreFavorite from "./views/Account/StoreFavorite.vue";
import Article from "./views/Account/Article.vue";
import Contribution from "./views/Shop/Contribution.vue";
import AI from "./views/Shop/AI.vue";
import RentOut from "./views/Shop/RentOut.vue";
import LiveRoom from "./views/Shop/LiveRoom.vue";
// import Privacy from "./views/Account/Privacy.vue";
import CargoList from "./views/Account/CargoList.vue";
const routes = [
  {
    path: '/',
    redirect: { path: 'home' },
  },
  {
    path: '/home',
    name: 'home',
    components: {
      shop: AppHome,
    },
  },
  {
    path: '/account',
    name: 'Account',
    components: {
      shop: Account,
    },
  },
  {
    path: '/account/login',
    name: 'login',
    components: {
      auth: Login,
    },
    meta: {
      hideHeader: true,
      hideFooter: true,
    },
  },
  {
    path: '/account/register',
    name: 'Register',
    components: {
      auth: Register,
    },
    meta: {
      hideHeader: true,
      hideFooter: true,
    },
  },
  {
    path: '/account/reset/password',
    name: 'ResetPassword',
    components: {
      auth: ResetPassword,
    },
    meta: {
      hideHeader: true,
      hideFooter: true,
    },
  },
  {
    path: '/account/address/edit/:id?',
    name: 'addressEdit',
    components: {
      shop: AddressEdit,
    },
  },
  {
    path: '/account/address/list',
    name: 'AddressList',
    components: {
      shop: AddressList,
    },
  },
  {
    path: '/account/balance/list',
    name: 'BalanceList',
    components: {
      shop: BalanceList,
    },
  },
  {
    path: '/account/coupon/list',
    name: 'CouponList',
    components: {
      shop: CouponList,
    },
  },
  {
    path: '/account/evaluate/edit/:id',
    name: 'EvaluateEdit',
    components: {
      shop: EvaluateEdit,
    },
  },
  {
    path: '/account/favorite/list',
    name: 'FavoriteList',
    components: {
      shop: FavoriteList,
    },
  },
  {
    path: '/account/article',
    name: 'Article',
    components: {
      shop: Article,
    },
  },
  // {
  //   path: '/account/article/privacy',
  //   name: 'Privacy',
  //   components: {
  //     shop: Privacy,
  //   },
  // },
  {
    path: '/account/store_favorite/list',
    name: 'StoreFavorite',
    components: {
      shop: StoreFavorite,
    },
  },
  {
    path: '/account/message/list',
    name: 'MessageList',
    components: {
      shop: MessageList,
    },
  },
  {
    path: '/account/order/delivery',
    name: 'OrderDelivery',
    components: {
      shop: OrderDelivery,
    },
  },
  {
    path: '/account/cargo/list/:id?',
    name: 'CargoList',
    components: {
      shop: CargoList,
    },
  },
  {
    path: '/account/order/list/:id?',
    name: 'OrderList',
    components: {
      shop: OrderList,
    },
  },
  {
    path: '/account/order/show/:id',
    name: 'OrderShow',
    components: {
      shop: OrderShow,
    },
  },
  {
    path: '/account/recharge/add',
    name: 'RechargeAdd',
    components: {
      shop: RechargeAdd,
    },
  },
  {
    path: '/account/recharge/list',
    name: 'RechargeList',
    components: {
      shop: RechargeList,
    },
  },
  {
    path: '/account/refund/add/:id',
    name: 'RefundAdd',
    components: {
      shop: RefundAdd,
    },
  },
  {
    path: '/account/refund/list',
    name: 'RefundList',
    components: {
      shop: RefundList,
    },
  },
  {
    path: '/account/refund/show/:id',
    name: 'RefundShow',
    components: {
      shop: RefundShow,
    },
  },
  {
    path: '/account/setting/info',
    name: 'SettingInfo',
    components: {
      shop: SettingInfo,
    },
  },
  {
    path: '/account/setting/password',
    name: 'SettingPassword',
    components: {
      shop: SettingPassword,
    },
  },
  {
    path: '/account/store/settled',
    name: 'StoreSettled',
    components: {
      shop: StoreSettled,
    },
  },
  {
    path: '/account/info/list',
    name: 'InfoList',
    components: {
      shop: InfoList,
    },
  },
  {
    path: '/payment/confirm',
    name: 'Confirm',
    components: {
      shop: Confirm,
    },
  },
  {
    path: '/payment/result',
    name: 'Result',
    components: {
      shop: Result,
    },
  },
  {
    path: '/shop/cart',
    name: 'Cart',
    components: {
      shop: Cart,
    },
  },
  {
    path: '/shop/goods/list/:categoryId?',
    name: 'List',
    components: {
      shop: List,
    },
  },
  {
    path: '/shop/order/confirm',
    name: 'OrderConfirm',
    components: {
      shop: OrderConfirm,
    },
  },
  {
    path: '/shop/point/list',
    name: 'PointList',
    components: {
      shop: PointList,
    },
  },
  {
    path: '/shop/point/show/:id',
    name: 'PointShow',
    components: {
      shop: PointShow,
    },
  },
  {
    path: '/shop/presell/list',
    name: 'PresellList',
    components: {
      shop: PresellList,
    },
  },
  {
    path: '/shop/sale/list',
    name: 'SaleList',
    components: {
      shop: SaleList,
    },
  },
  {
    path: '/shop/goods/show/:id',
    name: 'Show',
    components: {
      shop: Show,
    },
  },
  {
    path: '/shop/store/index/:id',
    name: 'StoreIndex',
    components: {
      shop: StoreIndex,
    },
  },
  {
    path: '/shop/store/list',
    name: 'StoreList',
    components: {
      shop: StoreList,
    },
  },
  {
    path: '/shop/selected/list',
    name: 'Selected',
    components: {
      shop: Selected,
    },
  },
  {
    path: '/shop/wedding/list',
    name: 'Wedding',
    components: {
      shop: Wedding,
    },
  },
  {
    path: '/shop/copyright/list',
    name: 'Copyright',
    components: {
      shop: Copyright,
    },
  },
  {
    path: '/shop/new_years_goods/list',
    name: 'NewYearsGoods',
    components: {
      shop: NewYearsGoods,
    },
  },
  {
    path: '/shop/contribution/list',
    name: 'Contribution',
    components: {
      shop: Contribution,
    },
  },
  {
    path: '/shop/ai/list',
    name: 'AI',
    components: {
      shop: AI,
    },
  },
  {
    path: '/shop/rent_out/list',
    name: 'RentOut',
    components: {
      shop: RentOut,
    },
  },
  {
    path: '/shop/live_room/list',
    name: 'LiveRoom',
    components: {
      shop: LiveRoom,
    },
  },
  {
    path: '/shop/new_years_goods/list',
    name: 'NewYearsGoods',
    components: {
      shop: NewYearsGoods,
    },
  },
];

let originPush = VueRouter.prototype.push;
VueRouter.prototype.push = function (location, resolve, reject) {
  if (resolve && reject) {
    //如果传了回调函数，直接使用
    originPush.call(this, location, resolve, reject);
  } else {
    //如果没有传回调函数，手动添加
    originPush.call(
      this,
      location,
      () => { },
      () => { }
    );
  }
};

Vue.use(VueRouter);

const router = new VueRouter({
  mode: 'history',
  routes,
});

export default router;
