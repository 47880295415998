import Vue from 'vue'
import Vuex from 'vuex'
import AxiosAjax from '@/utils/axios.config';
Vue.use(Vuex)

const store = new Vuex.Store({
    state: {
        cartCount: 0
    },
    mutations: {
        setCartCount(state, count) {
            state.cartCount = count
        }
    },
    actions: {
        getCartCount({ commit }) {
            AxiosAjax({
                url: `/client/shop/cart/count?rand=${Math.random()}`,
                success(res) {
                    commit("setCartCount", res.data)
                },
            });
        }
    }
})

export default store
